import JSZip from "jszip";
import { saveAs } from "file-saver";

/**
 * Compresse une liste de fichiers en un fichier ZIP et le télécharge.
 *
 * @param {Array} files - Liste des fichiers à compresser (objets contenant `path` et `name`).
 * @param {string} zipName - Nom du fichier ZIP généré.
 */
export const createAndDownloadZip = async (files, zipName = "download.zip") => {
  const zip = new JSZip();

  try {
    for (const file of files) {
      const response = await fetch(file.path);
      if (!response.ok) throw new Error(`Erreur en récupérant ${file.name}`);
      const blob = await response.blob();
      zip.file(file.name, blob); 
    }

    const zipContent = await zip.generateAsync({ type: "blob" });

    saveAs(zipContent, zipName);
  } catch (error) {
    console.error("Erreur lors de la compression des fichiers : ", error);
    throw error; 
  }
};
