import * as React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  makeStyles,
} from "@material-ui/core";
import Spacer from "../../components/Spacer";
import DocumentService from "../../services/DocumentService";
import RubriquesService from "../../services/RubriquesService";
import { useTheme } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useNavigate } from "react-router";
import Result from "../message/Result";
import useToast from "../../hooks/useToast";
import { TYPE_NOTIFICATION } from "../../components/reusable/NotificationBull";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    //overflowY: "auto",
    flexDirection: "column",
    // maxWidth: 2000,
    elevation: 2,
    marginLeft: "10px",
  },
  titleInput: {
    width: "400px",
    textAlign: "center",
  },
  labelPosition: {
    float: "left",
    marginLeft: "50px",
  },
}));

const bullRed = (
  <Box
    component="span"
    sx={{
      display: "inline-block",
      mx: "5px",
      transform: "scale(0.8)",
      color: "red",
      fontSize: "20px",
    }}
  >
    *
  </Box>
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 400,
    },
  },
};

export default function AddLocationDialog(props) {
  const openAddLocationDialog = props.openAddLocationDialog;
  const closeAddLocationDialog = props.closeAddLocationDialog;
  const documentId = props.documentId;

  const { Toast, showToast } = useToast();

  const [personName, setPersonName] = useState("");

  const classes = useStyles();

  const [form, setForm] = useState({
    id: "",
    title: "",
    direction: "",
    service: "",
    employe: "",
    etat: "",
    emplacement: "",
  });

  const [etat, setEtat] = useState([]);

  const [direction, setDirection] = useState([]);

  const [service, setService] = useState([]);

  const [employe, setEmploye] = useState([]);

  const [emplacement, setEmplacement] = useState([]);

  const [etats, setEtats] = useState({
    open: false,
    message: "",
    color: "",
  });

  function handleChangeEmploye(event) {
    // const {
    //   target: { value },
    // } = event;
    const { name, value } = event.target;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setForm({ ...form, [name]: value });
  }

  function handleChangeDirection(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
    console.log("direction id: ", event.target.value);

    RubriquesService.getService(value).then((result) => {
      if (result && result.data) {
        setService(result.data);
        console.log("Service data: ", result.data);
      }
    });

    RubriquesService.getEmployeByDirection(value).then((result) => {
      if (result && result.status) {
        setEmploye(result.data);
        console.log("Données Employés:", result.data);
      }
    });
  }

  function handleChangeService(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });

    RubriquesService.getEmployeByService(value).then((res) => {
      if (res && res.status) {
        setEmploye(res.data);

        console.log("Employés data by service: ", res.data);
      }
    });
  }

  function handleChange(event) {
    const { name, value } = event.target; //destructuration

    if (name == "etat") {
      setForm({ ...form, [name]: value });
    } else if (name == "emplacement") {
      setForm({ ...form, [name]: value });
    }
  }

  function handleClickSaveDocumentLocation(e) {
    DocumentService.AddDocumentLocation(
      form.etat,
      form.employe,
      form.emplacement,
      form.id
    ).then((response) => {
      if (response.status) {
        showToast(TYPE_NOTIFICATION.SUCCESS, response.message);
        closeAddLocationDialog();
      } else {
        showToast(TYPE_NOTIFICATION.ERROR, "Erreur lors de l'ajout");
      }
    });
  }

  function handleCloseResult() {
    setEtats({ ...etats, open: false });
  }
  useEffect(() => {
    if (openAddLocationDialog) {
      DocumentService.documentById(documentId).then((library) => {
        if (library.status) {
          setForm({
            id: library.data.id,
            title: library.data.titre,
          });
        }
      });

      RubriquesService.getDirection().then((result) => {
        if (result.status) {
          setDirection(result.data);
        } else {
          console.log("Data direction non obtenue");
        }
      });

      DocumentService.getEtat().then((response) => {
        if (response.status) {
          setEtat(response.data);
          console.log("Etat: ", response.data);
        }
      });

      DocumentService.getLocationForRollingList().then((response) => {
        if (response.status) {
          console.log(response.data);
          setEmplacement(response.data);
        }
      });

      setService([]);
      setEmploye([]);
      setPersonName([]);
    }
  }, [openAddLocationDialog]);

  return (
    <>
      <Toast />
      <Dialog
        open={openAddLocationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
          {"Formulaire d'ajout d'emplacement de document"}
        </DialogTitle>

        <Spacer y={3} />

        <DialogContent id="alert-dialog-description">
          <Grid>
            <Paper className={classes.paper}>
              <Grid
                container
                spacing={2}
                justifyContent="center" // Pour centrer horizontalement
              >
                <TextField
                  className={classes.titleInput}
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  name="title"
                  value={form.title}
                />
              </Grid>

              <Spacer y={5} />

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <label className={classes.labelPosition}>
                    Etat physique du document
                  </label>
                </Grid>
                <Grid item xs={8}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">
                      Choisir
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={form.etat}
                      name="etat"
                      label="Etat physique du document"
                      onChange={(e) => handleChange(e)}
                      // sx={{width: "500px"}}
                    >
                      {etat.map((row) => (
                        <MenuItem key={row.state_id} value={row.state_id}>
                          {row.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Spacer y={3} />

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <label className={classes.labelPosition}>
                    Détenteur(s) actuel
                  </label>
                </Grid>
                <Grid item xs={8}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">
                      Direction{bullRed}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={form.direction}
                      name="direction"
                      label="Direction"
                      onChange={(e) => handleChangeDirection(e)}
                    >
                      {direction.map((row) => (
                        <MenuItem
                          key={row.direction_id}
                          value={row.direction_id}
                        >
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Spacer y={3} />

              <Grid container spacing={2}>
                <Grid item xs={4}></Grid>
                <Grid item xs={8}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">
                      Service
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={form.service}
                      defaultValue=""
                      name="service"
                      label="Service"
                      onChange={(e) => handleChangeService(e)}
                    >
                      {service.map((row) => (
                        <MenuItem key={row.service_id} value={row.service_id}>
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Spacer y={3} />

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  {/* <label className={classes.labelPosition}>
                    Employé(és)
                  </label> */}
                </Grid>
                <Grid item xs={8}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-multiple-checkbox-label">
                      {personName.length === 0 ? "Employé(s)" : ""}
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      name="employe"
                      value={personName}
                      onChange={(e) => handleChangeEmploye(e)}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                      inputProps={{
                        placeholder: "Sélectionnez un employé", // Placeholder text here
                      }}
                      // label={"Employé(s)"}
                    >
                      {employe.map((item) => (
                        <MenuItem key={item.name} value={item.name}>
                          <Checkbox
                            checked={personName.indexOf(item.name) > -1}
                          />
                          <ListItemText primary={item.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Spacer y={3} />

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <label className={classes.labelPosition}>Emplacement</label>
                </Grid>
                <Grid item xs={8}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">
                      Choisir
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={form.emplacement}
                      name="emplacement"
                      label="Emplacement"
                      onChange={(e) => handleChange(e)}
                    >
                      {emplacement.map((row) => (
                        <MenuItem key={row.location_id} value={row.location_id}>
                          {row.Emplacement}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Spacer y={3} />
            </Paper>
          </Grid>

          <Result
            open={etats.open}
            color={etats.color}
            message={etats.message}
            handleClose={handleCloseResult}
          />
        </DialogContent>

        <Spacer y={2} />

        <DialogActions>
          <Grid container spacing={2} style={{ marginLeft: "600px" }}>
            <Grid item xs={5}>
              <Button
                variant="contained"
                color="secondary"
                onClick={(e) => handleClickSaveDocumentLocation(e)}
              >
                Enregistrer
              </Button>
            </Grid>
            <Grid item xs={5}>
              <Button
                variant="contained"
                color="primary"
                onClick={closeAddLocationDialog}
              >
                Fermer
              </Button>
            </Grid>
          </Grid>
        </DialogActions>

        <Spacer y={2} />
      </Dialog>
    </>
  );
}
