import React, { useState } from 'react'
import MainContainer from '../../../components/MainContainer'
import { headerPersonnalEvalutaion } from '../../../data/modules/personnal-evaluation-header'
import AppPageForTableList from '../shared/AppPageForTableList'
import CategoryCritereFiltre from './CategoryCritereFiltre'

const CategoryCritereList = () => {
  const [openForm, setOpenForm] = useState(false);
  const handleOpenDialog = () => {
    setOpenForm(!openForm);
  }
  const handleCloseDialog = () => {
    setOpenForm(false);
  }

  return (
    <MainContainer {...headerPersonnalEvalutaion}>
      <AppPageForTableList
        handleClickBtnCreer={handleOpenDialog}
        title={"Liste des categories de criteres"}
        filter_component={<CategoryCritereFiltre handleCloseFormDialog={handleCloseDialog} handleOpenFormDialog={handleOpenDialog} openForm={openForm} />}
      //table_component={<CategoryCritereTable openForm={openForm} onCloseForm={handleCloseDialog} />}
      />
    </MainContainer>
  )
}

export default CategoryCritereList