import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  AddOutlined,
  CloseOutlined,
  RefreshOutlined,
} from "@material-ui/icons";
import useToast from "../../../hooks/useToast";
import useConfirm from "../../../hooks/useConfirm";
import { circuit_validation_service } from "../../../services/CircuitValidationService";
import { TYPE_NOTIFICATION } from "../../../components/reusable/NotificationBull";
import AutocompleteCombobox, { adaptDataValueToAutocompleteData } from "../../personal_evaluation/shared/AutocompleteCombobox";
import ReorderableValidatorsList from "./ReorderableValidatorsList";

const ValidatorsForm = ({ isOpen, handleClose }) => {
  const childReorderItemRef = useRef(null);
  const { showToast, Toast } = useToast();
  const { DialogComponent, openConfirmDialog } = useConfirm();
  const [addMode, setAddMode] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [selectedValidator, setSelectedValidator] = useState({
    ID_PERSONNALIZED_VALIDATOR: 0,
    ID_FUNCTION_VALIDATOR: 0,
    ID_REQUESTOR: 0,
    LEVEL: 0,
  });
  const [functions_list, setFunctionsList] = useState([]);

  useEffect(() => {
    handleFetchAllValidators();
  }, [isOpen]);

  const handleChangeSelectedValidator = (new_val) => {
    if (new_val) {
      setSelectedValidator({
        ID_FUNCTION_VALIDATOR: new_val.function_id,
        ID_REQUESTOR: 0,
        LEVEL: childReorderItemRef.current.getMaxNiveau() + 1,
      });
    } else {
      setSelectedValidator({
        ID_FUNCTION_VALIDATOR: 0,
        ID_REQUESTOR: 0,
        LEVEL: 0,
      });
    }
  };

  const transformChildItemsIntoValidators = () => {
    try {
      return childReorderItemRef.current.items.map((item) => {
        return {
          ID_PERSONNALIZED_VALIDATOR: item.ID_PERSONNALIZED_VALIDATOR,
          ID_FUNCTION_VALIDATOR: item.function_id,
          ID_REQUESTOR: item.ID_REQUESTOR,
          LEVEL: item.niveau,
        };
      });
    } catch (error) {
      showToast(TYPE_NOTIFICATION.ERROR, error.toString());
      return [];
    }
  };

  const handleSaveValidators = () => {
    try {
      circuit_validation_service
        .update_ultiple_validators(transformChildItemsIntoValidators())
        .then((res) => {
          if (res && res.success) {
            showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
            handleClose();
          } else {
            showToast(TYPE_NOTIFICATION.ERROR, res.message);
          }
        });
    } catch (error) {
      showToast(TYPE_NOTIFICATION.ERROR, error.toString());
    }
  };

  const handleDialogClose = () => {
    handleClose();
  };

  const handleToggleAddMode = () => {
    setAddMode(!addMode);
  };

  const handleRefreshData = () => {
    setRefresh(refresh + 1);
    handleFetchAllValidators();
  };

  const handleFetchAllValidators = () => {
    circuit_validation_service.get_function_validators_list().then((res) => {
      if (res && res.data) {
        setFunctionsList(res.data);
      }
    });
  };

  const isAllFieldOk = () => {
    return selectedValidator !== null;
  };

  const handleClickAddNewValidator = () => {
    if (isAllFieldOk()) {
      circuit_validation_service
        .insert_validator(selectedValidator)
        .then((res) => {
          if (res && res.success) {
            showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
            handleRefreshData();
          } else {
            showToast(TYPE_NOTIFICATION.ERROR, res.message);
          }
        });
    } else {
      showToast(
        TYPE_NOTIFICATION.ERROR,
        "Vueillez selectionner un validateur d'habord"
      );
    }
  };

  return (
    <>
      <Toast />
      <Dialog
        open={isOpen}
        /* onClose={ handleDialogClose} */
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <Paper>
          <Grid
            container
            height={"100%"}
            display={"flex"}
            flexDirection={"column"}
            overflow={"auto"}
          >
            <DialogTitle>Validateur form</DialogTitle>
            <DialogComponent />
            <DialogTitle
              id="dialog-title"
              className="entete-dialog"
            ></DialogTitle>

            <DialogContent
              id="dialog-description"
              className="app-scroll-bar-view"
              sx={{ maxHeight: "75vh", overflow: "auto" }}
            >
              <Grid maxHeight={"65vh"}>
                <Grid p={0.5}>
                  <Grid
                    m={1}
                    display={addMode ? "flex" : "none"}
                    flexDirection={"row"}
                    width={"95%"}
                  >
                    <Button
                      sx={{ ml: 0.5 }}
                      size="small"
                      variant="outlined"
                      className="form_control"
                      onClick={() => handleRefreshData()}
                    >
                      <RefreshOutlined />
                    </Button>
                    <Grid ml={0.5} flexGrow={1}>
                      <AutocompleteCombobox
                        label={"Choisir nouveau validateur"}
                        data={adaptDataValueToAutocompleteData(
                          functions_list,
                          "name",
                          "function_id"
                        )}
                        size="small"
                        onChange={handleChangeSelectedValidator}
                      />
                    </Grid>
                    <Button
                      sx={{ ml: 0.5 }}
                      size="small"
                      variant="contained"
                      disabled={selectedValidator.ID_FUNCTION_VALIDATOR === 0}
                      className="form_control"
                      onClick={handleClickAddNewValidator}
                    >
                      <AddOutlined />
                    </Button>
                    <Button
                      sx={{ ml: 0.5 }}
                      size="small"
                      variant="outlined"
                      onClick={handleToggleAddMode}
                      className="form_control"
                    >
                      <CloseOutlined />
                    </Button>
                  </Grid>
                  <Grid mb={1} display={!addMode ? "flex" : "none"}>
                    <Button
                      size="small"
                      variant="contained"
                      startIcon={<AddOutlined />}
                      onClick={handleToggleAddMode}
                    >
                      Ajouter
                    </Button>
                  </Grid>
                  <Grid mt={3} maxHeight={"45vh"} overflow={"auto"}>
                    <ReorderableValidatorsList
                      refresh={refresh}
                      ref={childReorderItemRef}
                    />
                  </Grid>
                  <Grid>
                    {/*  <CardHeader
                      title={
                        "Veuillez deplacer à l'aide de votre souris pour ordonner les ordres des validateurs"
                      }
                      titleTypographyProps={{ variant: "caption" }}
                    /> */}
                    <Grid mt={1.5}>
                      <Alert
                        severity="info"
                        variant="outlined"
                        sx={{ fontSize: "0.8rem" }}
                      >
                        Veuillez deplacer à l'aide de votre souris pour ordonner
                        les ordres des validateurs
                      </Alert>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider variant="middle" />
            <DialogActions>
              <Button
                className="form_control"
                size="small"
                onClick={handleSaveValidators}
                variant="contained"
              >
                Enregistrer
              </Button>
              <Button
                className="form_control"
                size="small"
                onClick={handleDialogClose}
                variant="outlined"
              >
                Fermer
              </Button>
            </DialogActions>
          </Grid>
        </Paper>
      </Dialog>
    </>
  );
};
export default ValidatorsForm;
