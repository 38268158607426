import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const apiMis = process.env.REACT_APP_API_MIS;
const PlanningMissionService = {
  async getPlanning(state) {
    try {
      const result = await axios.get(`${apiUrl}/planningMission?state=`+state);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getActivity() {
    try {
      const result = await axios.get(`${apiUrl}/planningMission/activity`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getCategory(category_mission_id) {
    try {
      const result = category_mission_id
        ? await axios.get(
            `${apiUrl}/planningMission/category/${category_mission_id}`
          )
        : await axios.get(`${apiUrl}/planningMission/category`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getDirectionInMis() {
    try {
      const result = await axios.get(
        `http://192.168.1.18:8080/misrepo_interne/webservice/apisite_ws/directions`,
        {
          headers: {
            "Sec-Fetch-Mode": "cors",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            Accept: "application/json,text/plain,*/*",

            "Content-Type": "application/json",
            Authorization:
              "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjQzMiIsImVtYWlsIjoic2FtdWVsYS5yYW1hcm92YXZ5QGZpZC5tZyIsInRpbWVzdGFtcCI6MTY1ODkyMDUwOH0.GeKezyIzKTIT3npQcaK4cXEOWPfbMI6fSZI7aK4eiec",
          },
        }
      );
      console.log(result);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getDirection() {
    try {
      const result = await axios.get(`${apiUrl}/planningMission/direction`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getServiceappui(id) {
    try {
      const result = await axios.post(`${apiUrl}/planningMission/serviceappui`,{id:id});
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getService(id) {
    try {
      const result = await axios.post(`${apiUrl}/planningMission/service`,{id:id});
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getdetails(id) {
    try {
      const result = await axios.get(`${apiUrl}/planningMission/detailsID/`+id);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getIntervenant(direction_id, service_id) {
    try {
      const result = await axios.post(
        `${apiUrl}/planningMission/intervenant`
      ,{
        direction:direction_id,
        service:service_id
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },


  async saveMissionPlanning(formData) {
    try {
      const result = await axios.post(`${apiUrl}/planningMission`, formData);
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async updateMissionPlanning(formData) {
    try {
      const result = await axios.put(`${apiUrl}/planningMission`, formData);
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  
  async saveMissionPlanningAgenda(formData, mission_planning_id, modif) {
    try {
      const result = modif
        ? await axios.put(
            `${apiUrl}/planningMission/agenda/${mission_planning_id}`,
            formData
          )
        : await axios.post(
            `${apiUrl}/planningMission/agenda/${mission_planning_id}`,
            formData
          );
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getMissionPlanningDays(mission_planning_id) {
    try {
      const result = await axios.get(
        `${apiUrl}/planningMission/days/${mission_planning_id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getMissionary(mission_planning_id) {
    try {
      const result = await axios.get(
        `${apiUrl}/planningMission/missionary/${mission_planning_id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async deleteMissionPlanning(mission_planning_id) {
    try {
      const result = await axios.delete(
        `${apiUrl}/planningMission/id/${mission_planning_id}`
      );
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getPlanningMissionById(mission_planning_id) {
    try {
      const result = await axios.get(
        `${apiUrl}/planningMission/id/${mission_planning_id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async updatePlanningMissionById(mission_planning_id) {
    try {
      const result = await axios.get(
        `${apiUrl}/planningMission/update/${mission_planning_id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getMissionPlanningId(mission_planning_id) {
    try {
      const result = await axios.get(
        `${apiUrl}/planningMission/post/${mission_planning_id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },


  async getUserMission() {
    try {
      const result = await axios.get(
        `${apiUrl}/planningMission/mission`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getObservation(id) {
    try {
      const result = await axios.get(
        `${apiUrl}/planningMission/observation/`+id
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getObservationtdr(id) {
    try {
      const result = await axios.get(
        `${apiUrl}/planningMission/observationtdr/`+id
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getAgendaMissionPlanning(mission_planning_id) {
    try {
      const result = await axios.get(
        `${apiUrl}/planningMission/agenda/${mission_planning_id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async newtrd(id) {
    try {
      const result = await axios.get(
        `${apiUrl}/TDR/newtdraftervalidation/`+id
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getEtat(form) {
    try {
      const result = await axios.post(
        `${apiUrl}/MissionEtat`,{
         ligne:form.ligne,
         valeur:form.valeur, 
         datedebut:form.date_debut,
         datefin:form.date_fin,
         codemission:form.code_mission,
         status:form.status,
         direction:form.direction,
         destination:form.destination,
         directionmis:form.directionmiss,
         servicemis:form.servicemiss
         }
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getEtatplanning(form) {
    
    try {
      const result = await axios.post(
        `${apiUrl}/MissionEtat/getEtatPlanning`,{
         datedebut:form.date_debut,
         datefin:form.date_fin,
         status:form.status,
         direction:form.directiondest,
         destination:form.destination,
         directionmis:form.direction,
         service:form.service
         }
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  
  
};

export default PlanningMissionService;
