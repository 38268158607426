import React, { useState, useEffect } from "react";

import { DataGrid } from "@material-ui/data-grid";

import DialogContent from "@material-ui/core/DialogContent";

import DialogTitle from "@material-ui/core/DialogTitle";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import RubriquesService from "../../services/RubriquesService";
import { makeStyles, MenuItem } from "@material-ui/core";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import Spacer from "../../components/Spacer";

import Result from "../message/Result";

import { CreateTwoTone, DeleteOutlineRounded } from "@material-ui/icons";
import Message from "../personnel/Message";
import DocumentService from "../../services/DocumentService";
import LoadingSimpleBackdrop from "./LoadingSimpleBackdrop";

const bullRed = (
  <Box
    component="span"
    sx={{
      display: "inline-block",
      mx: "5px",
      transform: "scale(0.8)",
      color: "red",
      fontSize: "20px",
      verticalAlign: "middle",
    }}
  >
    *
  </Box>
);

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflowY: "auto",
    flexDirection: "column",
    maxWidth: 2000,
    elevation: 2,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  text: {
    width: 370,
  },
  labelPosition: {
    float: "left",
  },
  text: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  label: {
    marginLeft: "10px",
  },
  titleInput: {
    width: "400px",
    textAlign: "center",
  },
}));

export default function PrivilegeDialog(props) {
  const openDialog = props.isActive;

  const messageDialog = props.message;

  const handleClose = props.handleClose;

  const typePrivilege = props.typePrivilege;

  const selectedId = props.selectedId;

  const [direction, setDirection] = useState([]);

  const [service, setService] = useState([]);

  const [fonction, setFonction] = useState([]);

  const [employe, setEmploye] = useState([]);

  const classes = useStyles();

  const [condition, setCondition] = useState([
    {
      value: 1,
      label: "Tout supérieur hierarchique",
    },
    {
      value: 2,
      label: "Tous les subordonnées",
    },
    {
      value: 3,
      label: "Spécifique",
    },
  ]);

  const [selectedFunctionId, setSelectedFunctionId] = useState(0);

  const [selectedEmployeId, setSelectedEmployeId] = useState(0); //pour l'employé de réference

  const [selectedEmployeIdList, setSelectedEmployeIdList] = useState([]);

  const [conditionValue, setConditionValue] = useState(null);

  const [etats, setEtats] = useState({
    open: false,
    message: "",
    color: "",
  });

  const [privilegeId, setPrivilegeId] = useState([]);

  const [superiorsIdList, setSuperiorsIdList] = useState(0);

  const [listPrivilegeId, setListPrivilegeId] = useState(0);

  const [rows, setRows] = useState([]);

  const [rubriqueId, setRubriqueId] = useState(0);

  const [canCreate, setCanCreate] = useState(0);

  const [canSee, setCanSee] = useState(0);

  const [canEdit, setCanEdit] = useState(0);

  const [canDelete, setCanDelete] = useState(0);

  const [canSetprivilege, setcanSetprivilege] = useState(0);

  const [etat, setEtat] = useState({
    open: false,
    message: "message",
  });

  const [activeRowId, setActiveRowId] = useState(0);

  const [employeByDirectionIdList, setEmployeByDirectionIdList] = useState([]);

  const [employeByServiceIdList, setEmployeByServiceIdList] = useState([]);

  const [selectedDirectionId, setSelectedDirectionId] = useState(0);

  const [selectedServiceId, setSelectedServiceId] = useState(0);

  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =
    useState(false);

  const [form, setForm] = useState({
    id: "",
    title: "",
    direction: "",
    service: "",
    fonction_id: [],
    condition: "",
    employe: "",
    create: false,
    modify: false,
    see: false,
  });

  const columns = [
    {
      field: "name",
      headerName: "Nom et prénom(s)",
      width: 350,
      filterable: true,
    },
    { field: "droit", headerName: "Droit", width: 350 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <>
            <div>
              <Button
                onClick={(e) => handleEdit(e, params.id)}
                title="Modifier les droits de cet utilisateur"
              >
                <CreateTwoTone style={{ color: "black" }} />
              </Button>

              <Button title="Supprimer">
                <DeleteOutlineRounded
                  style={{ color: "red" }}
                  onClick={(e) => handleDeletePrivilege(e, params.id)}
                />
              </Button>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (openDialog) {
      setActiveRowId(0);
      setCanCreate(0);
      setCanSee(0);
      setCanEdit(0);
      setCanDelete(0);
      setcanSetprivilege(0);
      setSelectedEmployeId(0); //employé de réference
      setConditionValue(null);
      setSelectedEmployeIdList([]);

      switch (typePrivilege) {
        case "rubrique":
          RubriquesService.RubriqueById(selectedId).then((library) => {
            setForm({
              id: library.data.id,
              title: library.data.nom,
            });
            setRubriqueId(library.data.id); //id rubrique
          });

          RubriquesService.getUsersRight(selectedId, typePrivilege).then(
            (response) => {
              if (response.status) {
                setRows(response.data.map((row) => ({ ...row, id: row.id })));
              } else {
                setRows([]);
              }
            }
          );
          break;

        case "document":
          console.log("documentIdSelected: ", selectedId);
          DocumentService.documentById(selectedId).then((library) => {
            if (library.status) {
              setForm({
                id: library.data.id, //id document
                title: library.data.titre,
              });
              console.log("type document, id document ", library.data.id);
            }
          });

          RubriquesService.getUsersRight(selectedId, typePrivilege).then(
            (response) => {
              if (response.status) {
                setRows(response.data.map((row) => ({ ...row, id: row.id })));
              } else {
                setRows([]);
              }
            }
          );

          break;
      }

      RubriquesService.getDirection().then((res) => {
        if (res && res.status) {
          setDirection(res.data);
        }
      });

      RubriquesService.getFunction().then((res) => {
        if (res && res.status) {
          setFonction(res.data);
        }
      });
    } else {
      setService([]);
      setEmploye([]);
      setRows([]);
    }
  }, [openDialog]);

  function handleChangeDirection(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
    console.log("direction id: ", event.target.value);
    // setSelectedDirectionId(event.target.value);

    RubriquesService.getService(value).then((res) => {
      if (res && res.status) {
        setService(res.data);
        console.log("Service data: ", res.data);
      }
    });

    RubriquesService.getEmployeByDirection(value).then((res) => {
      if (res && res.status) {
        setEmploye(res.data);
        console.log("Données Employés:", res.data);
        setEmployeByDirectionIdList(res.employeByDirectionIdList);
        //setSelectedEmployeIdList(res.employeByDirectionIdList);

        // setSelectedEmployeIdList(res.employeByDirectionIdList);
      }
    });
  }

  function handleChangeService(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
    setSelectedServiceId(event.target.value);

    RubriquesService.getEmployeByService(value).then((res) => {
      if (res && res.status) {
        setEmploye(res.data);

        console.log("Employés data by service: ", res.data);

        setEmployeByServiceIdList(res.employeByServiceIdList);

        //setSelectedEmployeIdList(res.employeByServiceIdList);

        // setSelectedEmployeIdList((oldArray) => [
        //   ...oldArray,
        //   res.employeByServiceIdList,
        // ]);

        // setSelectedEmployeIdList(res.employeByServiceIdList);
      }
    });
  }

  function handleChangeFunction(event, value) {
    if (value) {
      setForm({ ...form, fonction_id: value });

      console.log("une fonction est selectioné", value.function_id);

      setSelectedFunctionId(value.function_id);

      RubriquesService.getEmployeByFonction(value.function_id).then((res) => {
        if (res && res.status) {
          setEmploye(res.data);
          console.log("employé par fonction: ", res.data);
        }
      });
    } else {
      setEmploye([]);
    }
  }

  function handleChangeEmploye(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });

    setSelectedEmployeId(event.target.value); //employé de réference

    console.log(event.target.value);

    console.log("typeeeeeeee", typeof event.target.value);

    const id = event.target.value;

    const numberId = parseInt(id);

    console.log("typeeeeeeee", typeof numberId);

    // setSelectedEmployeIdList(numberId);

    setSelectedEmployeIdList((oldArray) => [...oldArray, numberId]);
    //setSelectedEmployeIdList(event.target.value);

    console.log("selected employe : ", event.target.value);
  }

  function handleChangeCondition(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
    setConditionValue(event.target.value);
    console.log("value condition selectionéé: ", event.target.value);
  }

  function handleClick(e) {
    if (conditionValue == null && selectedEmployeId == 0) {
      if (employeByServiceIdList.length === 0) {
        //filtre par direction
        RubriquesService.savePrivilege(
          typePrivilege,
          canCreate,
          canSee,
          canEdit,
          canDelete,
          canSetprivilege,
          form.id,
          employeByDirectionIdList,
          //selectedEmployeIdList,
          "filterByDirection",
          0,
          0,
          0
        ).then((response) => {
          if (response.status) {
            setOpenLoadingSimpleBackdrop(true);

            RubriquesService.getUsersRight(selectedId, typePrivilege).then(
              (right) => {
                if (right.status) {
                  setRows(right.data.map((row) => ({ ...row, id: row.id })));
                  setOpenLoadingSimpleBackdrop(false);
                } else {
                  setOpenLoadingSimpleBackdrop(false);
                }

                setActiveRowId(0);
              }
            );

            setPrivilegeId(response.id);
            setCanCreate(0);
            setCanSee(0);
            setCanEdit(0);
            setCanDelete(0);
            setcanSetprivilege(0);
            setSelectedDirectionId(0);
            setService([]);
            setEmploye([]);

            setEtats({
              ...etats,
              open: true,
              color: "green",
              message: response.message,
            });
          } else {
            console.log("Ajout de privilge par filtre de direction echouée");
          }
        });
      } else {
        RubriquesService.savePrivilege(
          typePrivilege,
          canCreate,
          canSee,
          canEdit,
          canDelete,
          canSetprivilege,
          form.id,
          employeByServiceIdList,
          //selectedEmployeIdList,
          "filterByService",
          0,
          0,
          0
        ).then((response) => {
          if (response.status) {
            console.log("Ajout de privilège par filtre de service reussie");
            setOpenLoadingSimpleBackdrop(true);
            RubriquesService.getUsersRight(selectedId, typePrivilege).then(
              (right) => {
                if (right.status) {
                  setRows(right.data.map((row) => ({ ...row, id: row.id })));
                  setOpenLoadingSimpleBackdrop(false);
                } else {
                  setOpenLoadingSimpleBackdrop(false);
                }

                setActiveRowId(0);
              }
            );

            setPrivilegeId(response.id);
            setCanCreate(0);
            setCanSee(0);
            setCanEdit(0);
            setCanDelete(0);
            setcanSetprivilege(0);
            setService([]);
            setEmploye([]);

            setEtats({
              ...etats,
              open: true,
              color: "green",
              message: response.message,
            });
          } else {
            console.log("Ajout de privilge par filtre de service echouée");
          }
        });
      }
    } else {
      switch (conditionValue) {
        case 1:
          console.log("Condition : Superieur hiérarchique");

          RubriquesService.getUserSuperior(selectedEmployeId).then(
            (superiorResult) => {
              if (superiorResult.status) {
                setSuperiorsIdList(superiorResult.superior_id_list);
                //setSelectedEmployeIdList(superiorResult.superior_id_list);

                console.log(
                  "superior_id_list",
                  superiorResult.superior_id_list
                );

                setSelectedEmployeIdList(superiorResult.superior_id_list);

                RubriquesService.savePrivilege(
                  typePrivilege,
                  canCreate,
                  canSee,
                  canEdit,
                  canDelete,
                  canSetprivilege,
                  form.id,
                  //superiorResult.superior_id_list,
                  //selectedEmployeIdList,
                  superiorResult.superior_id_list,
                  "superior",
                  selectedFunctionId,
                  selectedEmployeId,
                  activeRowId
                ).then((results) => {
                  if (results.status) {
                    setOpenLoadingSimpleBackdrop(true);
                    setPrivilegeId(results.id);
                    setCanCreate(0);
                    setCanSee(0);
                    setCanEdit(0);
                    setCanDelete(0);
                    setcanSetprivilege(0);
                    setSelectedEmployeId(0);

                    RubriquesService.getUsersRight(
                      selectedId,
                      typePrivilege
                    ).then((right) => {
                      if (right.status) {
                        setRows(
                          right.data.map((row) => ({ ...row, id: row.id }))
                        );
                        setOpenLoadingSimpleBackdrop(false);
                      } else {
                        setOpenLoadingSimpleBackdrop(false);
                      }

                      setActiveRowId(0);

                      setEtats({
                        ...etats,
                        open: true,
                        color: "green",
                        message: right.message,
                      });
                    });
                  } else {
                    setEtats({
                      ...etats,
                      open: true,
                      color: "red",
                      message: results.message, //privilège non ajouté
                    });
                  }
                });
              }
            }
          );
          break;

        case 2:
          console.log("Condition : Subordonnés");

          RubriquesService.getUserSubordonate(selectedEmployeId).then(
            (subordonateResult) => {
              if (subordonateResult.status) {
                let subordonateIdList = subordonateResult.subordonate_id_list;

                // setSelectedEmployeIdList(subordonateResult.subordonate_id_list);

                RubriquesService.savePrivilege(
                  typePrivilege,
                  canCreate,
                  canSee,
                  canEdit,
                  canDelete,
                  canSetprivilege,
                  form.id,
                  subordonateIdList,
                  //selectedEmployeIdList,
                  "subordonate",
                  selectedFunctionId,
                  selectedEmployeId,
                  activeRowId
                ).then((results) => {
                  if (results.status) {
                    console.log(results.status);
                    setPrivilegeId(results.id);

                    setOpenLoadingSimpleBackdrop(true);

                    RubriquesService.getUsersRight(
                      selectedId,
                      typePrivilege
                    ).then((response) => {
                      if (response && response.status) {
                        setRows(
                          response.data.map((row) => ({ ...row, id: row.id }))
                        );

                        setOpenLoadingSimpleBackdrop(false);

                        setEtats({
                          ...etats,
                          open: true,
                          color: "green",
                          message: response.message,
                        });
                      } else {
                        console.log("Droit des subrordonnées non obtenus");
                        setOpenLoadingSimpleBackdrop(false);
                      }
                    });

                    setActiveRowId(0);
                    setCanCreate(0);
                    setCanSee(0);
                    setCanEdit(0);
                    setCanDelete(0);
                    setcanSetprivilege(0);
                    setSelectedEmployeId(0);
                  }
                });
              } else {
                setEtats({
                  ...etats,
                  open: true,
                  color: "red",
                  message: subordonateResult.message,
                });
              }
            }
          );
          break;

        case 3:
          console.log("Condition : Spécifique");

          // const selectedEmploye = employe.find(
          //   (employee) => employee.identifier === selectedEmployeId
          // );

          RubriquesService.savePrivilege(
            typePrivilege,
            canCreate,
            canSee,
            canEdit,
            canDelete,
            canSetprivilege,
            form.id,
            //selectedEmployeId,
            selectedEmployeIdList,
            "specifique",
            selectedFunctionId,
            selectedEmployeId, //employe de reference
            activeRowId
          ).then((results) => {
            if (results.status) {
              console.log("booommm");
              console.log("privilege specifique ok");
              console.log(results.status);
              console.log(typeof results.status);

              setListPrivilegeId(results.inserted_privilege_id);

              setOpenLoadingSimpleBackdrop(true);

              setActiveRowId(0);
              setCanCreate(0);
              setCanSee(0);
              setCanEdit(0);
              setCanDelete(0);
              setcanSetprivilege(0);
              setSelectedEmployeId(0);

              setEmploye([]);
              //setConditionValue(null);
              setSelectedDirectionId(0);

              setEtats({
                ...etats,
                open: true,
                color: "green",
                message: results.message,
              });

              RubriquesService.getUsersRight(selectedId, typePrivilege).then(
                (response) => {
                  if (response && response.status) {
                    setRows(
                      response.data.map((row) => ({ ...row, id: row.id }))
                    );
                    setOpenLoadingSimpleBackdrop(false);
                  } else {
                    setOpenLoadingSimpleBackdrop(false);
                  }
                }
              );
            } else {
              console.log("privilege specifique non enregistrée");

              setEtats({
                ...etats,
                open: true,
                color: "red",
                message: results.message,
              });
            }
          });
          break;

        default:
          setEtats({
            ...etats,
            open: true,
            color: "red",
            message: "Vueillez ajouter une condition",
          });
      }
    }
  }

  function handleEdit(event, id) {
    console.log("une ligne est selectionnée", id);

    setActiveRowId(id);

    console.log("active row id", id);

    setConditionValue(3); //sinon indeterminé ny condition any am switch case

    RubriquesService.getActionToEditCheckbox(id).then((response) => {
      if (response.status) {
        console.log(
          "Action correspondant à cette ligne selectionnée: ",
          response.data.action
        );

        const action = response.data.action;

        console.log(action);

        console.log(typeof action); //string en tenant compte que ',' est reconnu comme une chaine de caractère

        if (action[0] === "1") {
          setCanCreate(1);
          console.log("create: ", 1);
        } else {
          setCanCreate(0);
        }

        if (action[2] === "1") {
          setCanSee(1);
          console.log("see: ", 1);
        } else {
          setCanSee(0);
        }

        if (action[4] === "1") {
          setCanEdit(1);
          console.log("edit: ", 1);
        } else {
          setCanEdit(0);
        }

        if (action[6] === "1") {
          setCanDelete(1);
          console.log("delete: ", 1);
        } else {
          setCanDelete(0);
        }

        if (action[8] === "1") {
          setcanSetprivilege(1);
          console.log("set privilege: ", 1);
        } else {
          setcanSetprivilege(0);
        }
      } else
        console.log(
          "Aucune action correspodante à l'ID du privilege selectioné "
        );
    });
  }

  const handleChangeCreate = (event) => {
    setCanCreate(event.target.checked === true ? 1 : 0);
    console.log("etat1: ", event.target.checked);
  };

  const handleChangeSee = (event) => {
    setCanSee(event.target.checked === true ? 1 : 0);
    console.log("etat2: ", event.target.checked);
  };

  const handleChangeEdit = (event) => {
    setCanEdit(event.target.checked === true ? 1 : 0);
    console.log("etat3: ", event.target.checked);
  };

  const handleChangeDelete = (event) => {
    setCanDelete(event.target.checked === true ? 1 : 0);
    console.log("etat4: ", event.target.checked);
  };

  const handleChangeSetPrivilege = (event) => {
    setcanSetprivilege(event.target.checked === true ? 1 : 0);

    console.log("etat5: ", event.target.checked);
  };

  const handleCloseMessage = () => {
    setEtats({ ...etats, open: false });
  };

  const handleCloseResult = () => {
    setEtats({ ...etats, open: false });
  };

  // const handleCloseResultForSubordonate = () => {
  //   setEtatSubordonate({ ...etats, open: false });
  // };

  const [IdRowTodelete, setIdRowToDelete] = useState(0);

  //Pour supprimer :
  //Etape 1:
  function handleDeletePrivilege(e, id) {
    setEtat({
      open: true,
      message: "Voulez vous vraiment supprimer  ?",
    });
    setIdRowToDelete(id);
    console.log("ligne à supprimer: ", id);
  }

  //Etape 2
  const handleCloseConfirmationMessage = () => {
    setEtat({ ...etat, open: false });
  };

  //Etape 3
  function handleAccept() {
    RubriquesService.deletePrivilege(IdRowTodelete, selectedId).then(
      (response) => {
        if (response.status) {
          RubriquesService.getUsersRight(selectedId, typePrivilege).then(
            (result) => {
              if (result.status === true) {
                console.log("nifafa");
                setRows(result.data.map((row) => ({ ...row, id: row.id })));
              } else {
                setRows([]);
              }
            }
          );

          setEtat({
            open: false,
            message: "",
          });

          setEtats({
            ...etats,
            open: true,
            color: "green",
            message: response.message,
          });
        } else if (response.status == false) {
          console.log("suppression echouée");
          setRows([]);
        }
      }
    );
  }

  return (
    <div>
      <Dialog
        open={openDialog}
        //onClose={handleClose} ==> efa nafindra any @ bouton fermer de tsy afaka mikitika ivelan'ny boite de dialogue ny utilisateur rehefa ifermé
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth={true}
      >
        <Grid>
          <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
            {"Configurer les privilèges"}
            <Button onClick={handleClose} title="Fermer">
              <CancelPresentationIcon
                style={{ color: "white", marginLeft: "650px" }}
              />
            </Button>
          </DialogTitle>
        </Grid>

        <DialogContent>
          <Grid>
            <Paper className={classes.paper}>
              <Grid
                container
                spacing={2}
                style={{ marginLeft: "250px", textAlign: "center" }}
              >
                <TextField
                  className={classes.titleInput}
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  name="title"
                  value={form.title}
                />
              </Grid>

              <Spacer y={3} />

              <Grid container spacing={3}>
                <Grid item xs={1} style={{ marginLeft: "110px" }}>
                  <label className={classes.label}>Direction</label>
                </Grid>
                <Grid item xs={8} style={{ marginLeft: "40px" }}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={form.direction}
                      defaultValue=""
                      name="direction"
                      label="direction"
                      onChange={handleChangeDirection}
                    >
                      {direction.length > 0 &&
                        direction.map((row) => (
                          <MenuItem
                            key={row.direction_id}
                            value={row.direction_id}
                          >
                            {row.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Spacer y={2} />

              <Grid container spacing={3}>
                <Grid item xs={1} style={{ marginLeft: "110px" }}>
                  <label className={classes.label}>Service</label>
                </Grid>
                <Grid item xs={8} style={{ marginLeft: "40px" }}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={form.service}
                      defaultValue=""
                      name="service"
                      label="Service"
                      onChange={handleChangeService}
                    >
                      {service.length > 0 &&
                        service.map((row) => (
                          <MenuItem key={row.service_id} value={row.service_id}>
                            {row.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Spacer y={2} />

              <Grid container spacing={3}>
                <Grid
                  item
                  xs={1}
                  style={{ marginLeft: "110px", display: "inline-flex" }}
                >
                  <label className={classes.label}>Fonction </label>
                  {/* {bullRed} */}
                </Grid>
                <Grid item xs={8} style={{ marginLeft: "40px" }}>
                  <Autocomplete
                    options={fonction}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option.name === value.name
                    }
                    filterSelectedOptions
                    onChange={handleChangeFunction}
                    value={form.fonction_id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        id="outlined-basic"
                        //size="small"
                        //label="fonction"
                        //variant="outlined"
                        //placeholder="fonction"
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Spacer y={2} />

              <Grid container spacing={3}>
                <Grid
                  item
                  xs={1}
                  style={{ marginLeft: "110px", display: "inline-flex" }}
                >
                  <label className={classes.label}>Employe</label>
                  {/* {bullRed} */}
                </Grid>
                <Grid item xs={8} style={{ marginLeft: "40px" }}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={form.employe}
                      name="employe"
                      label="employe"
                      onChange={(e) => handleChangeEmploye(e)}
                    >
                      {employe.length > 0 &&
                        employe.map((row) => (
                          <MenuItem key={row.user_id} value={row.user_id}>
                            {row.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Spacer y={2} />

              <Grid container spacing={3}>
                <Grid
                  item
                  xs={1}
                  style={{ marginLeft: "110px", display: "inline-flex" }}
                >
                  <label className={classes.label}>Condition</label>
                  {selectedEmployeId !== 0 ? bullRed : null}
                  {/* {bullRed} */}
                </Grid>
                <Grid item xs={8} style={{ marginLeft: "40px" }}>
                  <TextField
                    fullWidth
                    id="outlined-select-currency"
                    select
                    variant="standard"
                    onChange={handleChangeCondition}
                    //defaultValue="supérieur_hierarchique"
                  >
                    {condition.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Spacer y={2} />

              <Grid container spacing={2} style={{ marginLeft: "20px" }}>
                <Grid item xs={2}>
                  <Spacer y={2} />
                  {typePrivilege === "rubrique" ? (
                    <FormControlLabel
                      label="Créer"
                      control={
                        <Checkbox
                          color="primary"
                          checked={canCreate === 1 ? true : false}
                          onChange={handleChangeCreate}
                        />
                      }
                    />
                  ) : (
                    <FormControlLabel
                      label="Créer"
                      control={
                        <Checkbox
                          color="primary"
                          checked={canCreate === 1 ? true : false}
                          onChange={handleChangeCreate}
                          disabled
                        />
                      }
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Spacer y={2} />
                  <FormControlLabel
                    label="Voir"
                    control={
                      <Checkbox
                        color="primary"
                        checked={canSee === 1 ? true : false}
                        onChange={handleChangeSee}
                      />
                    }
                  />
                </Grid>

                <Grid item xs={2}>
                  <Spacer y={2} />
                  {/*<Checkbox color="primary" />
                  <label style={{ marginLeft: "8px" }}>Modification</label> */}
                  <FormControlLabel
                    label="Modifier"
                    control={
                      <Checkbox
                        color="primary"
                        checked={canEdit === 1 ? true : false}
                        onChange={handleChangeEdit}
                      />
                    }
                  />
                </Grid>

                <Grid item xs={2}>
                  <Spacer y={2} />
                  <FormControlLabel
                    label="Supprimer"
                    control={
                      <Checkbox
                        color="primary"
                        checked={canDelete === 1 ? true : false}
                        onChange={handleChangeDelete}
                      />
                    }
                  />
                </Grid>

                <Grid item xs={3}>
                  <Spacer y={2} />
                  <FormControlLabel
                    label="Configurer privilège"
                    control={
                      <Checkbox
                        color="primary"
                        checked={canSetprivilege === 1 ? true : false}
                        onChange={(e) => {
                          handleChangeSetPrivilege(e);
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>

              <Spacer y={3} />
              <Grid>
                <Button
                  variant="contained"
                  color="primary"
                  // onClick={handleSubmit}
                  style={({ fontWeight: 600 }, { marginLeft: "650px" })}
                  size="small"
                  onClick={(e) => handleClick(e)}
                >
                  ENREGISTRER
                </Button>
              </Grid>

              <Spacer y={3} />

              <div style={{ height: 400, width: "100%" }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={50}
                  rowsPerPageOptions={[50]}
                />
              </div>

              <Spacer y={4} />

              <Grid item xs={12} style={{ marginLeft: "50px" }}>
                <label className={classes.text}>
                  ({bullRed}):Champs obligatoires{" "}
                </label>
              </Grid>
            </Paper>
          </Grid>

          <Result
            open={etats.open}
            color={etats.color}
            message={etats.message}
            handleClose={handleCloseResult}
          />

          <Message
            isActive={etat.open}
            message={etat.message}
            handleClose={handleCloseConfirmationMessage}
            handleConfirm={handleAccept}
          />

          <LoadingSimpleBackdrop
            openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
