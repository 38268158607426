import StyledTreeItem from "./StyledTreeItem";
import TreeView from "@material-ui/lab/TreeView";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ShopIcon from "@mui/icons-material/Shop";
import MonitorIcon from "@mui/icons-material/Monitor";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import EditOffOutlinedIcon from "@mui/icons-material/EditOffOutlined";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import AirlineSeatIndividualSuiteOutlinedIcon from "@material-ui/icons/AirlineSeatIndividualSuiteOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import MeetingRoomOutlinedIcon from "@mui/icons-material/MeetingRoomOutlined";
import useStyles from "./styledtreeitem.styles";
import { userDetailContext } from "../App";
import { useContext } from "react";
import { hasPrivilege } from "../utils/privilege";
import MailIcon from "@mui/icons-material/Mail";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SettingsInputCompositeOutlinedIcon from "@mui/icons-material/SettingsInputCompositeOutlined";
import PsychologyAltOutlinedIcon from "@mui/icons-material/PsychologyAltOutlined";
import SettingsAccessibilityOutlinedIcon from "@mui/icons-material/SettingsAccessibilityOutlined";
import InsightsIcon from "@mui/icons-material/Insights";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import {
  ContactsTwoTone,
  FaceTwoTone,
  HomeTwoTone,
  ChevronRight,
  Settings,
  CardTravel,
  Beenhere,
  Dashboard,
  ListAltSharp,
  ListAlt,
} from "@material-ui/icons";
import AuthService from "../services/AuthService";
import PlanningService from "../services/PlanningService";
import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";

function SidebarTreeView() {
  const classes = useStyles();
  const [id, setId] = useState(0);
  const [idSupSpecial, setIdSupSpecial] = useState(0);
  const [showTitlePreProd, setShowTitlePreProd] = useState(false);
  const handleLogOut = (e) => {
    AuthService.logOut();
  };
  useEffect(() => {
    PlanningService.getUser().then((result) => {
      if (result) {
        setId(result.data);
        setIdSupSpecial(result.dataSpecial);
      }
    });
    if (window.location.hostname == "2fidmg.mg") {
      setShowTitlePreProd(true);
    }
  }, []);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const contextData = useContext(userDetailContext);

  //parametrage collapse sidebar
  const [expanded, setExpanded] = React.useState([
    localStorage.getItem("EXPANDED")
      ? localStorage.getItem("EXPANDED").substring(0, 1)
      : localStorage.setItem("EXPANDED", "0"),
    localStorage.getItem("EXPANDED").substring(0, 3),
  ]);
  const [currentId, setCurrentId] = React.useState(
    localStorage.getItem("EXPANDED").substring(0, 3)
  );
  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };
  const handleToggleSelected = (event, nodeIds) => {
    if (nodeIds?.trim()?.length !== 0) {
      localStorage.setItem("EXPANDED", nodeIds + "-1");
      setCurrentId(nodeIds);
    }
  };
  const handleExpandClick = () => {
    setExpanded((oldExpanded) =>
      oldExpanded.length === 0 ? ["1", "2", "3", "4", "5", "6"] : []
    );
    // console.log(oldExpanded.length)
  };
  const handleExpandClickGed = () => {
    setExpanded((oldExpanded) => (oldExpanded.length <= 1 ? [""] : ["b"]));
  };
  const handleExpandClickValidation = () => {
    setExpanded((oldExpanded) => (oldExpanded.length <= 1 ? [""] : ["2"]));
  };
  const handleExpandClickAdmin = () => {
    setExpanded((oldExpanded) => (oldExpanded.length <= 1 ? [""] : ["3"]));
  };
  const handleExpandClickSetting = () => {
    setExpanded((oldExpanded) => (oldExpanded.length <= 1 ? [""] : ["4"]));
  };
  const handleExpandClickRh = () => {
    setExpanded((oldExpanded) => (oldExpanded.length <= 1 ? [""] : ["5"]));
  };
  const handleExpandClickLogistic = () => {
    setExpanded((oldExpanded) => (oldExpanded.length <= 1 ? [""] : ["8"]));
  };
  const handleExpandClickBanqueCV = () => {
    setExpanded((oldExpanded) => (oldExpanded.length <= 1 ? [""] : ["6"]));
  };
  const handleExpandClickMission = () => {
    setExpanded((oldExpanded) => (oldExpanded.length <= 1 ? [""] : ["7"]));
  };
  const handleExpandClickState = () => {
    setExpanded((oldExpanded) => (oldExpanded.length <= 1 ? [""] : ["1"]));
  };
  const handleExpandClickAccueil = () => {
    setExpanded((oldExpanded) => (oldExpanded.length <= 1 ? ["0"] : ["0"]));
  };
  const handleExpandClickCommun = () => {
    setExpanded((oldExpanded) => (oldExpanded.length === 2 ? ["4-1"] : [""]));
  };
  const handleExpandClickMail = () => {
    setExpanded((oldExpanded) => (oldExpanded.length <= 1 ? [""] : ["9"]));
  };
  const handleExpandClickPersonnalEvaluation = () => {
    setExpanded((oldExpanded) => (oldExpanded.length <= 1 ? [""] : ["a"]));
  };
  const handleExpandClickCircuitValidation = () => {
    setExpanded((oldExpanded) => (oldExpanded.length <= 1 ? [""] : ["c"]));
  };
  const handleExpandClickPersonnalEvaluationParametre = () => {
    setExpanded((oldExpanded) => (oldExpanded.length <= 1 ? [""] : ["a-2"]));
  };
  //Fin pqrqmetrqge
  return (
    <>
      {showTitlePreProd && (
        <h2 style={{ color: "red", fontWeight: "bold" }}>SERVEUR TEST</h2>
      )}
      {!showTitlePreProd && (
        <strong style={{ color: "red", fontSize: "20px" }}>
          Version 1.0.1
        </strong>
      )}

      <TreeView
        className={classes.treeView}
        defaultExpanded={[
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8w",
          "9",
          "a",
          "b",
          "c",
          "3-1",
          "3-1-1",
          "3-1-2",
          "3-1-3",
          "3-2",
          "3-2-1",
          "3-2-2",
          "3-3",
          "3-3-1",
        ]}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        expanded={expanded?.trim?.length !== 0 ? expanded : [...currentId]}
        onNodeToggle={handleToggle}
        onNodeSelect={handleToggleSelected}
        defaultEndIcon={<div style={{ width: 24 }} />}
      >
        <StyledTreeItem
          nodeId="0"
          labelText="Accueil"
          labelIcon={HomeTwoTone}
          link="/home"
          onClick={handleExpandClickAccueil}
          isBold={true}
          isActiveMenu={splitLocation.includes("home") ? true : false}
        />
        <StyledTreeItem
          nodeId="1"
          labelText="Etat"
          labelIcon={Dashboard}
          link="/dashboard"
          onClick={handleExpandClickState}
          isBold={true}
        >
          <StyledTreeItem
            nodeId="1-1"
            labelText="Congé"
            labelIcon={AirlineSeatIndividualSuiteOutlinedIcon}
            isBold={true}
            link="/dashboard"
          >
            <StyledTreeItem
              nodeId="1-1-1"
              labelText=" Tableau de bord "
              link="/dashboard"
              labelIcon={ChevronRight}
              isActiveMenu={splitLocation.includes("dashboard") ? true : false}
            />
            <StyledTreeItem
              nodeId="1-1-2"
              labelText="Etats personnalisés"
              link="/custom_states"
              isActiveMenu={
                splitLocation.includes("custom_states") ? true : false
              }
              labelIcon={ChevronRight}
            />
          </StyledTreeItem>
          <StyledTreeItem
            nodeId="1-2"
            labelText="Mission"
            labelIcon={AssignmentIcon}
            isBold={true}
            link="/mission_dashboard"
          >
            <StyledTreeItem
              nodeId="1-2-1"
              labelText="Liste des planning"
              link="/mission_planning"
              labelIcon={ChevronRight}
              isActiveMenu={
                splitLocation.includes("mission_planning") ? true : false
              }
            />
            <StyledTreeItem
              nodeId="1-2-2"
              labelText="Liste des missions"
              link="/mission_dashboard"
              labelIcon={ChevronRight}
              isActiveMenu={
                splitLocation.includes("mission_dashboard") ? true : false
              }
            />
            <StyledTreeItem
              nodeId="1-2-3"
              labelText="Graphe"
              link="/mission_graphe"
              labelIcon={ChevronRight}
              isActiveMenu={
                splitLocation.includes("mission_graphe") ? true : false
              }
            />
          </StyledTreeItem>
        </StyledTreeItem>

        {/* {hasPrivilege(contextData.privileges, "Other-Me-Validations") && ( */}
        <StyledTreeItem
          nodeId="b"
          labelText="GED"
          labelIcon={AssignmentOutlinedIcon}
          isBold={true}
          isActiveMenu={false}
          onClick={handleExpandClickGed}
          link="/rubrique"
        >
          {/* <StyledTreeItem
            nodeId="8-1"
            labelText="Rubrique principale"
            labelIcon={ChevronRight}
            link="/principale"
            isActiveMenu={splitLocation.includes("principale") ? true : false}
          />

          <StyledTreeItem
            nodeId="8-2"
            labelText="Rubrique secondaire"
            labelIcon={ChevronRight}
            link="/secondaire"
            isActiveMenu={splitLocation.includes("secondaire") ? true : false}
          /> */}

          <StyledTreeItem
            nodeId="b-1"
            labelText="Rubrique"
            labelIcon={ChevronRight}
            link="/rubrique"
            isActiveMenu={splitLocation.includes("rubrique") ? true : false}
          />

          <StyledTreeItem
            nodeId="b-2"
            labelText="Document"
            labelIcon={ChevronRight}
            link="/document"
            isActiveMenu={splitLocation.includes("document") ? true : false}
          />

          <StyledTreeItem
            nodeId="b-3"
            labelText="Emplacement"
            labelIcon={ChevronRight}
            link="/emplacement"
            isActiveMenu={splitLocation.includes("emplacement") ? true : false}
          />
        </StyledTreeItem>

        {/* )} */}
        {hasPrivilege(contextData.privileges, "Other-Me-Validations") && (
          <StyledTreeItem
            style={{ display: "none" }}
            nodeId="c"
            labelText="Circuit validation"
            labelIcon={PublishedWithChangesIcon}
            isBold={true}
            isActiveMenu={false}
            onClick={handleExpandClickCircuitValidation}
            link="/demandes_en_cours"
          >
            <StyledTreeItem
              nodeId="c-1"
              labelText="En cours"
              labelIcon={ChevronRight}
              link="/demandes_en_cours"
              isActiveMenu={pathname === "/demandes_en_cours" ? true : false}
            />
            <StyledTreeItem
              nodeId="c-2"
              labelText="Validée(s)"
              labelIcon={ChevronRight}
              link="/demandes_validees"
              isActiveMenu={pathname === "/demandes_validees" ? true : false}
            />
            <StyledTreeItem
              nodeId="c-3"
              labelText="Refusée(s)"
              labelIcon={ChevronRight}
              link="/demandes_refusees"
              isActiveMenu={pathname === "/demandes_refusees" ? true : false}
            />
            <StyledTreeItem
              nodeId="c-4"
              labelText="Mes validateurs"
              labelIcon={ChevronRight}
              link="/myValidators"
              isActiveMenu={pathname === "/myValidators" ? true : false}
            />
          </StyledTreeItem>
        )}
        {hasPrivilege(contextData.privileges, "Other-Me-Validations") && (
          <StyledTreeItem
            nodeId="2"
            labelText="Mes validations"
            labelIcon={Beenhere}
            isBold={true}
            isActiveMenu={false}
            onClick={handleExpandClickValidation}
            link="/me/validations"
          >
            <StyledTreeItem
              nodeId="2-1"
              labelText="Validations réelles"
              labelIcon={ChevronRight}
              link="/me/validations"
              isActiveMenu={
                splitLocation.includes("validations") ? true : false
              }
            />
            {((id != undefined && id.length > 0) ||
              (idSupSpecial != undefined && idSupSpecial.length > 0)) && (
              <StyledTreeItem
                nodeId="2-3"
                labelText="Validations plannings congés"
                labelIcon={ChevronRight}
                link="/planning/table"
                isActiveMenu={splitLocation.includes("table") ? true : false}
              />
            )}
            {((id != undefined && id.length > 0) ||
              (idSupSpecial != undefined && idSupSpecial.length > 0)) && (
              <StyledTreeItem
                nodeId="2-4"
                labelText="Validations plannings missions"
                labelIcon={ChevronRight}
                link="/me/missions"
                isActiveMenu={splitLocation.includes("missions") ? true : false}
              />
            )}
            {((id != undefined && id.length > 0) ||
              (idSupSpecial != undefined && idSupSpecial.length > 0)) && (
              <StyledTreeItem
                nodeId="2-5"
                labelText="Validations pointage manuel"
                labelIcon={ChevronRight}
                link="/me/tracking"
                isActiveMenu={pathname === "/me/tracking" ? true : false}
              />
            )}
            <StyledTreeItem
              nodeId="2-1"
              labelText="Validations immobilisations"
              labelIcon={ChevronRight}
              link="/logistic/myValidations"
              isActiveMenu={
                pathname === "/logistic/myValidations" ? true : false
              }
            />
          </StyledTreeItem>
        )}

        {hasPrivilege(contextData.privileges, "Admin") && (
          <StyledTreeItem
            nodeId="3"
            labelText="Administration"
            labelIcon={ContactsTwoTone}
            isBold={true}
            disabled
            link="/groups"
            onClick={handleExpandClickAdmin}
          >
            {hasPrivilege(contextData.privileges, "Admin-Group-List") && (
              <StyledTreeItem
                nodeId="3-1"
                labelText="Groupes"
                labelIcon={ChevronRight}
                link="/groups"
                isActiveMenu={splitLocation.includes("groups") ? true : false}
              />
            )}
            {hasPrivilege(contextData.privileges, "Admin-User-List") && (
              <StyledTreeItem
                nodeId="3-2"
                labelText="Utilisateurs"
                labelIcon={ChevronRight}
                link="/users"
                isActiveMenu={splitLocation.includes("users") ? true : false}
              />
            )}
            {hasPrivilege(contextData.privileges, "Admin-Privilege-List") && (
              <StyledTreeItem
                nodeId="3-3"
                labelText="Privilèges"
                labelIcon={ChevronRight}
                link="/privileges"
                isActiveMenu={
                  splitLocation.includes("privileges") ? true : false
                }
              />
            )}
            {/* {hasPrivilege(contextData.privileges, "Admin-Privilege-List") && (
              <StyledTreeItem
                nodeId="3-4"
                labelText="Accès par direction"
                labelIcon={ChevronRight}
                link="/acces_direction"
                isActiveMenu={
                  splitLocation.includes("acces_direction") ? true : false
                }
              />
            )} */}
            {hasPrivilege(contextData.privileges, "Admin-Privilege-List") && (
              <StyledTreeItem
                nodeId="3-4"
                labelText="Fonctions"
                labelIcon={ChevronRight}
                link="/fonction"
                isActiveMenu={splitLocation.includes("fonction") ? true : false}
              />
            )}
            {hasPrivilege(contextData.privileges, "Admin-Privilege-List") && (
              <StyledTreeItem
                nodeId="3-5"
                labelText="Directions"
                labelIcon={ChevronRight}
                link="/direction"
                isActiveMenu={
                  splitLocation.includes("direction") ? true : false
                }
              />
            )}
            {hasPrivilege(contextData.privileges, "Admin-Privilege-List") && (
              <StyledTreeItem
                nodeId="3-6"
                labelText="Antennes"
                labelIcon={ChevronRight}
                link="/antenne"
                isActiveMenu={splitLocation.includes("antenne") ? true : false}
              />
            )}
            {hasPrivilege(contextData.privileges, "Admin") && (
              <StyledTreeItem
                nodeId="3-7"
                labelText="Services"
                labelIcon={ChevronRight}
                link="/services"
                isActiveMenu={splitLocation.includes("services") ? true : false}
              />
            )}
            {hasPrivilege(contextData.privileges, "Admin-Group-List") && (
              <StyledTreeItem
                nodeId="3-8"
                labelText="Bureau"
                labelIcon={ChevronRight}
                link="/seat"
                isActiveMenu={splitLocation.includes("bureau") ? true : false}
              />
            )}
            {hasPrivilege(contextData.privileges, "Admin-Group-List") && (
              <StyledTreeItem
                nodeId="3-9"
                labelText="Batiment"
                labelIcon={ChevronRight}
                link="/building"
                isActiveMenu={splitLocation.includes("batiment") ? true : false}
              />
            )}
          </StyledTreeItem>
        )}
        {hasPrivilege(contextData.privileges, "RH_parametrage") && (
          <StyledTreeItem
            nodeId="4"
            labelText="Paramétrage"
            labelIcon={Settings}
            isBold={true}
            link="/funding"
            onClick={handleExpandClickSetting}
          >
            {hasPrivilege(contextData.privileges, "Other-Me-Validations") && (
              <StyledTreeItem
                nodeId="4-1"
                labelText="Commun"
                labelIcon={AccountBalanceWalletOutlinedIcon}
                isBold={true}
                link="/funding"
              >
                <StyledTreeItem
                  nodeId="4-1-1"
                  labelText="  Financement"
                  link="/funding"
                  labelIcon={ChevronRight}
                  isActiveMenu={
                    splitLocation.includes("funding") ? true : false
                  }
                />
                <StyledTreeItem
                  nodeId="4-1-2"
                  labelText="Types et validations"
                  link="/number_validation"
                  labelIcon={ChevronRight}
                  isActiveMenu={
                    splitLocation.includes("number_validation") ? true : false
                  }
                />
              </StyledTreeItem>
            )}
            {hasPrivilege(contextData.privileges, "Other-Me-Validations") && (
              <StyledTreeItem
                nodeId="4-2"
                labelText="Congé"
                isBold={true}
                labelIcon={AirlineSeatIndividualSuiteOutlinedIcon}
                link="/leave_type"
              >
                <StyledTreeItem
                  nodeId="4-2-1"
                  labelText="Facteurs de comptage"
                  link="/leave_type"
                  labelIcon={ChevronRight}
                  isActiveMenu={
                    splitLocation.includes("leave_type") ? true : false
                  }
                />
                <StyledTreeItem
                  nodeId="4-2-2"
                  labelText="Jours non comptabilisés"
                  link="/annual_calendar"
                  labelIcon={ChevronRight}
                  isActiveMenu={
                    splitLocation.includes("annual_calendar") ? true : false
                  }
                />
                <StyledTreeItem
                  nodeId="4-2-3"
                  labelText="Période de validation"
                  link="/validation_planning"
                  labelIcon={ChevronRight}
                  isActiveMenu={
                    splitLocation.includes("validation_planning") ? true : false
                  }
                />

                {hasPrivilege(
                  contextData.privileges,
                  "balance_configuration"
                ) && (
                  <StyledTreeItem
                    nodeId="4-2-4"
                    labelText="Configuration solde"
                    link="/balance_configuration"
                    labelIcon={ChevronRight}
                    isActiveMenu={
                      splitLocation.includes("balance_configuration")
                        ? true
                        : false
                    }
                  />
                )}

                {hasPrivilege(contextData.privileges, "maternity_leave") && (
                  <StyledTreeItem
                    nodeId="4-2-5"
                    labelText="Congé de maternité"
                    link="/maternity_leave"
                    labelIcon={ChevronRight}
                    isActiveMenu={
                      splitLocation.includes("maternity_leave") ? true : false
                    }
                  />
                )}
                {hasPrivilege(contextData.privileges, "annual_leave") && (
                  <StyledTreeItem
                    nodeId="4-2-6"
                    labelText="Congé annuel"
                    link="/annual_leave"
                    labelIcon={ChevronRight}
                    isActiveMenu={
                      splitLocation.includes("annual_leave") ? true : false
                    }
                  />
                )}
              </StyledTreeItem>
            )}
            {hasPrivilege(contextData.privileges, "Other-Me-Validations") && (
              <StyledTreeItem
                nodeId="4-3"
                labelText="Personnel"
                isBold={true}
                labelIcon={AssignmentIndOutlinedIcon}
                link="/champ_dynamique"
              >
                <StyledTreeItem
                  nodeId="4-3-1"
                  labelText="Champ dynamique"
                  link="/champ_dynamique"
                  labelIcon={ChevronRight}
                  isActiveMenu={
                    splitLocation.includes("champ_dynamique") ? true : false
                  }
                />
              </StyledTreeItem>
            )}
            {hasPrivilege(contextData.privileges, "Other-Me-Validations") && (
              <StyledTreeItem
                nodeId="4-4"
                labelText="Candidature"
                isBold={true}
                labelIcon={AssignmentIndOutlinedIcon}
                link="/list_candidature"
              >
                <StyledTreeItem
                  nodeId="4-4-1"
                  labelText="Configuration poste"
                  link="/list_candidature"
                  labelIcon={ChevronRight}
                  isActiveMenu={
                    splitLocation.includes("list_candidature") ? true : false
                  }
                />
              </StyledTreeItem>
            )}
            {hasPrivilege(contextData.privileges, "Other-Me-Validations") && (
              <StyledTreeItem
                nodeId="4-5"
                labelText="Mission"
                isBold={true}
                labelIcon={AssignmentIcon}
                link="/mission_category"
              >
                <StyledTreeItem
                  nodeId="4-5-1"
                  labelText="Catégorie mission"
                  link="/mission_category"
                  labelIcon={ChevronRight}
                  isActiveMenu={
                    splitLocation.includes("mission_category") ? true : false
                  }
                />
                <StyledTreeItem
                  nodeId="4-5-2"
                  labelText="Suite de la demande d'appui"
                  link="/mission_reponse"
                  labelIcon={ChevronRight}
                  isActiveMenu={
                    splitLocation.includes("mission_reponse") ? true : false
                  }
                />
                <StyledTreeItem
                  nodeId="4-5-3"
                  labelText="Nombre de validation par direction"
                  link="/mission_dir"
                  labelIcon={ChevronRight}
                  isActiveMenu={
                    splitLocation.includes("mission_dir") ? true : false
                  }
                />
                <StyledTreeItem
                  nodeId="4-5-4"
                  labelText="Période de validation"
                  link="/mission_periode"
                  labelIcon={ChevronRight}
                  isActiveMenu={
                    splitLocation.includes("mission_periode") ? true : false
                  }
                />
                <StyledTreeItem
                  nodeId="4-5-5"
                  labelText="Configuration mail"
                  link="/mission_mail"
                  labelIcon={ChevronRight}
                  isActiveMenu={
                    splitLocation.includes("mission_mail") ? true : false
                  }
                />
              </StyledTreeItem>
            )}
            <StyledTreeItem
              nodeId="4-6"
              labelText="Evaluation du personnel"
              link="/evaluation_saison"
              labelIcon={PsychologyAltOutlinedIcon}
              isBold={true}
            >
              <StyledTreeItem
                nodeId="4-6-1"
                labelText="Session d'evaluation"
                labelIcon={ChevronRight}
                label="Session/Assignation"
                link="/evaluation_saison"
                isActiveMenu={
                  splitLocation.includes("evaluation_saison") ||
                  splitLocation.includes("evaluation_fonctions_list")
                    ? true
                    : false
                }
              />
              {false && (
                <StyledTreeItem
                  nodeId="4-6-2"
                  labelText="Groupe/Critère"
                  labelIcon={ChevronRight}
                  link="/critere_categorie"
                  //isActiveMenu={pathname === "/critere_categorie" ? true : false}
                  isActiveMenu={
                    splitLocation.includes("critere_categorie") ||
                    pathname === "/critere"
                      ? true
                      : false
                  }
                />
              )}

              {/* <StyledTreeItem
                nodeId="a-2-3"
                labelText="Critère d'evaluation"
                labelIcon={ChevronRight}
                label="Critere d'evalutaion"
                link="/critere"
                // isActiveMenu={pathname === "/critere" ? true : false}
                isActiveMenu={splitLocation.includes("critere") ? true : false}

              /> */}
              <StyledTreeItem
                nodeId="4-6-4"
                labelText="Fiche d'evaluation"
                labelIcon={ChevronRight}
                label="Fiche d'evaluation"
                link="/critere_groupee"
                // isActiveMenu={pathname === "/critere" ? true : false}
                isActiveMenu={
                  splitLocation.includes("critere_groupee") ||
                  splitLocation.includes("critere_groupee_detail")
                    ? true
                    : false
                }
              />
            </StyledTreeItem>
          </StyledTreeItem>
        )}
        {hasPrivilege(contextData.privileges, "HR") && (
          <StyledTreeItem
            nodeId="5"
            labelText="RH"
            labelIcon={FaceTwoTone}
            isBold={true}
            link="/me/leaves"
            onClick={handleExpandClickRh}
          >
            {hasPrivilege(contextData.privileges, "Planning_leaving") && (
              <StyledTreeItem
                nodeId="5-1"
                labelText="Planification congé"
                labelIcon={ChevronRight}
                link="/me/leaves"
                isActiveMenu={pathname === "/me/leaves" ? true : false}
              />
            )}
            {hasPrivilege(contextData.privileges, "Leave_request") && (
              <StyledTreeItem
                nodeId="5-2"
                labelText="Demande de congé"
                labelIcon={ChevronRight}
                link="/me/leave-request"
                isActiveMenu={pathname === "/me/leave-request" ? true : false}
              />
            )}
            {hasPrivilege(contextData.privileges, "Personnel_managing") && (
              <StyledTreeItem
                nodeId="5-3"
                labelText="Gestion du personnel"
                labelIcon={ChevronRight}
                link="/personnel"
                isActiveMenu={pathname === "/personnel" ? true : false}
              />
            )}
            {hasPrivilege(contextData.privileges, "Sub_menu_annuaire") && (
              <StyledTreeItem
                nodeId="5-4"
                labelText="Annuaire"
                labelIcon={ChevronRight}
                link="/annuaire"
                isActiveMenu={pathname === "/annuaire" ? true : false}
              />
            )}
            {hasPrivilege(contextData.privileges, "Information_of_family") && (
              <StyledTreeItem
                nodeId="5-5"
                labelText="Information sur la Famille"
                labelIcon={ChevronRight}
                link="/information_famille"
                isActiveMenu={
                  pathname === "/information_famille" ? true : false
                }
              />
            )}

            {hasPrivilege(contextData.privileges, "Leave_balance_summary") && (
              <StyledTreeItem
                nodeId="5-5"
                labelText="Historique Solde"
                labelIcon={ChevronRight}
                link="/leaveBalanceSummary"
                isActiveMenu={
                  pathname === "/leaveBalanceSummary" ? true : false
                }
              />
            )}

            {/* {hasPrivilege(contextData.privileges, "Time_Tracking") && ( */}
            <StyledTreeItem
              nodeId="5-5"
              labelText="Pointage"
              labelIcon={ChevronRight}
              link="/timeTracking"
              isActiveMenu={pathname === "/timeTracking" ? true : false}
            />
             <StyledTreeItem
              nodeId="5-6"
              labelText="Pointage Manuel"
              labelIcon={ChevronRight}
              link="/timeTrackingprovisional"
              isActiveMenu={pathname === "/timeTrackingprovisional" ? true : false}
            />
            <StyledTreeItem
              nodeId="5-7"
              labelText="Resultat évaluations"
              labelIcon={ChevronRight}
              label="Resultat"
              link="/resultats"
              isActiveMenu={splitLocation.includes("resultats") ? true : false}
            />
            {hasPrivilege(contextData.privileges, "Banque de CV") && false && (
              <StyledTreeItem
                nodeId="5-7"
                labelText="Banque de CV"
                labelIcon={ChevronRight}
                link="/cv_intern"
                isActiveMenu={
                  splitLocation.includes("cv_intern") ? true : false
                }
              />
            )}
            {/* )} */}
          </StyledTreeItem>
        )}
        {/* } {hasPrivilege(contextData.privileges, "Banque de CV") && ( */}
        <StyledTreeItem
          nodeId="6"
          labelText="Banque de CV"
          labelIcon={ListAlt}
          isBold={true}
          link="/cv_extern"
          onClick={handleExpandClickBanqueCV}
        >
          {/*  {hasPrivilege(contextData.privileges, "CV Externe") && ( */}
          <StyledTreeItem
            nodeId="6-1"
            labelText="CV externe"
            labelIcon={ChevronRight}
            link="/cv_extern"
            isActiveMenu={location.pathname === "/cv_extern" ? true : false}
          />
          {/*   )}  */}
          {hasPrivilege(contextData.privileges, "CV Interne") && (
            <StyledTreeItem
              nodeId="6-2"
              labelText="CV Interne"
              labelIcon={ChevronRight}
              link="/cv_intern"
              isActiveMenu={location.pathname === "/cv_intern" ? true : false}
            />
          )}
          {hasPrivilege(contextData.privileges, "CV Interne") && (
            <StyledTreeItem
              nodeId="6-3"
              labelText="Détails CV Interne"
              labelIcon={ChevronRight}
              link="/detail_cv"
              isActiveMenu={location.pathname === "/detail_cv" ? true : false}
            />
          )}
        </StyledTreeItem>
        {/*  )} */}
        {hasPrivilege(contextData.privileges, "Menu_Mission") && (
          <StyledTreeItem
            nodeId="7"
            labelText="Mission"
            label="Mission"
            labelIcon={CardTravel}
            isBold={true}
            onClick={handleExpandClickMission}
            link="/mission/planning_mission"
          >
            <StyledTreeItem
              nodeId="7-2"
              labelText="Mes demandes d'appui"
              labelIcon={ChevronRight}
              label="Mission"
              link="/mission/demande"
              isActiveMenu={pathname === "/mission/demande" ? true : false}
            />
            <StyledTreeItem
              nodeId="7-4"
              labelText="Acceptation demande d'appui"
              labelIcon={ChevronRight}
              link="/acceptedHelp"
              isActiveMenu={
                splitLocation.includes("acceptedHelp") ? true : false
              }
            />
            <StyledTreeItem
              nodeId="7-1"
              labelText="Planification"
              labelIcon={ChevronRight}
              label="Mission"
              link="/mission/planning_mission"
              isActiveMenu={
                pathname === "/mission/planning_mission" ? true : false
              }
            />
            <StyledTreeItem
              nodeId="7-3"
              labelText="Elaboration TDR"
              labelIcon={ChevronRight}
              link="/mission/elaboration_tdr"
              isActiveMenu={
                pathname === "/mission/elaboration_tdr" ? true : false
              }
            />
            <StyledTreeItem
              nodeId="7-4"
              labelText="Elaboration Agenda"
              labelIcon={ChevronRight}
              link="/mission/agendalist"
              isActiveMenu={pathname === "/mission/agendalist" ? true : false}
            />
            <StyledTreeItem
              nodeId="7-5"
              labelText="Historique demande"
              labelIcon={ChevronRight}
              link="/mission/history"
              isActiveMenu={pathname === "/mission/history" ? true : false}
            />
          </StyledTreeItem>
        )}
        {hasPrivilege(contextData.privileges, "Menu_Logistique") && (
          <StyledTreeItem
            nodeId="8"
            labelText="Logistiques"
            labelIcon={ShopIcon}
            isBold={true}
            link="/logistic/immobilisations"
            onClick={handleExpandClickLogistic}
          >
            {hasPrivilege(contextData.privileges, "Menu_Logistique") && (
              <StyledTreeItem
                nodeId="8-1"
                labelText="Matériel"
                labelIcon={MonitorIcon}
                isBold={true}
                link="/logistic/immobilisations"
              >
                <StyledTreeItem
                  nodeId="8-1-1"
                  labelText="Catégories matériels"
                  link="/logistic/category"
                  labelIcon={ChevronRight}
                  isActiveMenu={
                    pathname === "/logistic/category" ? true : false
                  }
                />
                <StyledTreeItem
                  nodeId="8-1-2"
                  labelText="Classements matériels"
                  link="/logistic/ranking"
                  labelIcon={ChevronRight}
                  isActiveMenu={pathname === "/logistic/ranking" ? true : false}
                />
                <StyledTreeItem
                  nodeId="8-1-3"
                  labelText="Liste des Matériels"
                  link="/logistic/immobilisations"
                  labelIcon={ChevronRight}
                  isActiveMenu={
                    pathname === "/logistic/immobilisations" ? true : false
                  }
                />
                <StyledTreeItem
                  nodeId="8-1-3"
                  labelText="Génération QrCode"
                  link="/logistic/generateQrCode"
                  labelIcon={ChevronRight}
                  isActiveMenu={
                    pathname === "/logistic/generateQrCode" ? true : false
                  }
                />
              </StyledTreeItem>
            )}
            {hasPrivilege(contextData.privileges, "Menu_Logistique") && (
              <StyledTreeItem
                nodeId="8-2"
                labelText="Paramétrage matériel"
                isBold={true}
                labelIcon={EditOffOutlinedIcon}
                link="/logistic/config"
              >
                <StyledTreeItem
                  nodeId="8-2-1"
                  labelText="Configuration d'un matériel"
                  link="/logistic/config"
                  labelIcon={ChevronRight}
                  isActiveMenu={pathname === "/logistic/config" ? true : false}
                />
                <StyledTreeItem
                  nodeId="8-2-2"
                  labelText="Liste des configurations"
                  link="/logistic/rankingList"
                  labelIcon={ChevronRight}
                  isActiveMenu={
                    pathname === "/logistic/rankingList" ? true : false
                  }
                />
              </StyledTreeItem>
            )}
            {hasPrivilege(contextData.privileges, "Menu_Logistique") && (
              <StyledTreeItem
                nodeId="8-3"
                labelText="Historique d'inventaire"
                isBold={true}
                labelIcon={AssignmentOutlinedIcon}
                link="/logistic/inventory"
              >
                <StyledTreeItem
                  nodeId="8-3-1"
                  labelText="Matériel affecté"
                  link="/logistic/inventory"
                  labelIcon={ChevronRight}
                  isActiveMenu={
                    pathname === "/logistic/inventory" ? true : false
                  }
                />
                <StyledTreeItem
                  nodeId="8-3-2"
                  labelText="Matériels en stock"
                  link="/logistic/stock"
                  labelIcon={ChevronRight}
                  isActiveMenu={pathname === "/logistic/stock" ? true : false}
                />
                {/*<StyledTreeItem
                  nodeId="7-3-3"
                  labelText="Matériels transféré"
                  link="/logistic/transfered"
                  labelIcon={ChevronRight}
                  isActiveMenu={pathname === "/logistic/transfered" ? true : false}
                  />*/}
              </StyledTreeItem>
            )}{" "}
            {/*{hasPrivilege(contextData.privileges, "Menu_Logistique") && (
              <StyledTreeItem
              nodeId="7-4"
              labelText="Statistique"
              labelIcon={ShowChart}
              isBold={true}
              link="/logistic/statistics"
              isActiveMenu={
                pathname === "/logistic/statistics" ? true : false
              }
          >
              </StyledTreeItem>
            )}*/}
            {hasPrivilege(contextData.privileges, "Menu_Logistique") && (
              <StyledTreeItem
                nodeId="8-5"
                labelText="Réunion"
                isBold={true}
                labelIcon={MeetingRoomOutlinedIcon}
                link="/Logistic/meeting_management"
              >
                {hasPrivilege(contextData.privileges, "Admin-meeting-Add") && (
                  <StyledTreeItem
                    nodeId="8-5-1"
                    labelText="Créer"
                    link="/Logistic/meeting_management/add"
                    labelIcon={ChevronRight}
                    isActiveMenu={
                      pathname === "/Logistic/meeting_management/add"
                        ? true
                        : false
                    }
                  />
                )}
                {hasPrivilege(
                  contextData.privileges,
                  "Admin-meeting-Request"
                ) && (
                  <StyledTreeItem
                    nodeId="8-5-1"
                    labelText="Réunions"
                    link="/Logistic/meeting_management"
                    labelIcon={ChevronRight}
                    isActiveMenu={
                      pathname === "/Logistic/meeting_management" ? true : false
                    }
                  />
                )}
                <StyledTreeItem
                  nodeId="8-5-1"
                  labelText="Calendrier"
                  link="/logistic/viewing_meeting"
                  labelIcon={ChevronRight}
                  isActiveMenu={
                    pathname === "/logistic/viewing_meeting" ? true : false
                  }
                />

                <StyledTreeItem
                  nodeId="8-5-1"
                  labelText="Terminées"
                  link="/Logistic/meeting_management/finish"
                  labelIcon={ChevronRight}
                  isActiveMenu={
                    pathname === "/Logistic/meeting_management/finish"
                      ? true
                      : false
                  }
                />
                {/* <StyledTreeItem
                  nodeId="7-3-2"
                  labelText="Matériel stocké"
                  link="/logistic/stock"
                  labelIcon={ChevronRight}
                  isActiveMenu={pathname === "/logistic/stock" ? true : false}
                /> */}
              </StyledTreeItem>
            )}
          </StyledTreeItem>
        )}

        {/* /////// */}

        {
          <StyledTreeItem
            nodeId="9"
            labelText="Mail regroupé"
            labelIcon={MailIcon}
            isBold={true}
            onClick={handleExpandClickMail}
            link=""
          >
            <StyledTreeItem
              nodeId="9-3"
              labelText="Envoyer"
              labelIcon={ChevronRight}
              link="/mailsend"
              isActiveMenu={pathname === "/mailsend" ? true : false}
            />
            <StyledTreeItem
              nodeId="9-2"
              labelText="Historique mail envoyé"
              labelIcon={ChevronRight}
              label="Historique mail envoyé"
              link="/mailhistory"
              isActiveMenu={pathname === "/mailhistory" ? true : false}
              // isActiveMenu={splitLocation.includes("acceptedHelp") ? true : false}
            />
            <StyledTreeItem
              nodeId="9-2"
              labelText="Historique mail reçu"
              labelIcon={ChevronRight}
              label="Historique mail reçu"
              link="/mailhistoryreceive"
              isActiveMenu={pathname === "/mailhistoryreceive" ? true : false}
              // isActiveMenu={splitLocation.includes("acceptedHelp") ? true : false}
            />
          </StyledTreeItem>
        }
        {
          <StyledTreeItem
            nodeId="a"
            labelText="Evaluation du personnel"
            labelIcon={PsychologyAltOutlinedIcon}
            isBold={true}
            onClick={handleExpandClickPersonnalEvaluation}
            link="/personnel_evaluation"
          >
            <StyledTreeItem
              nodeId="a-1"
              labelText="Evaluation"
              labelIcon={ChevronRight}
              label="Evaluation"
              link="/personnel_evaluation"
              //isActiveMenu={pathname === "/personnel_evaluation" ? true : false}
              isActiveMenu={
                splitLocation.includes("personnel_evaluation") ? true : false
              }
            />
            <StyledTreeItem
              nodeId="a-1"
              labelText="Mes subordonnés"
              labelIcon={ChevronRight}
              label="Mes subordonnés"
              link="/mes_subordonnees"
              isActiveMenu={
                splitLocation.includes("mes_subordonnees") ? true : false
              }
            />
            {false && (
              <StyledTreeItem
                nodeId="a-3"
                labelText="Performance"
                labelIcon={InsightsIcon}
                label="Evaluation"
                link="/personnel_performance"
                isActiveMenu={
                  splitLocation.includes("personnel_performance") ? true : false
                }
              />
            )}
          </StyledTreeItem>
        }

        {/* /////// */}
        <StyledTreeItem
          nodeId="10"
          labelText="Mon compte"
          labelIcon={AccountCircle}
          isBold={true}
          link="/myAccount"
          isActiveMenu={pathname === "/myAccount" ? true : false}
        ></StyledTreeItem>
        <StyledTreeItem
          nodeId="0"
          labelText="Mes matériels"
          labelIcon={MonitorIcon}
          isBold={true}
          link="/logistic/me/inventory"
          isActiveMenu={pathname === "/logistic/me/inventory" ? true : false}
        ></StyledTreeItem>
        {/* <StyledTreeItem
          nodeId="9"
          labelText="Mails"
          labelIcon={MailIcon}
          isBold={true}
          link="/logistic/me/inventory"
          isActiveMenu={
            pathname === "/logistic/me/inventory" ? true : false
          }
        >
        </StyledTreeItem> */}
      </TreeView>
    </>
  );
}
export default SidebarTreeView;
