import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Title from "../../../components/Title";
import { Done, Clear, LocalFlorist } from "@material-ui/icons";
import { DataGrid } from '@material-ui/data-grid';
import PlanningMissionService from "../../../services/PlanningMissionService";
import ConfirmationDialog from "../../tools/ConfirmationDialog";
import Message from "../../tools/Message";
import { CreateTwoTone ,DeleteOutlineRounded} from "@material-ui/icons";
import { Subject, CalendarToday, PostAdd, CalendarTodayTwoTone } from "@material-ui/icons";
import MissionService from "../../../services/MissionService";
import { userDetailContext } from "../../../App";
import {useContext} from "react";
import Spacer from "../../../components/Spacer";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import { useNavigate } from 'react-router-dom';
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Typography from '@mui/material/Typography';
import TextField from "@material-ui/core/TextField";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TDRService from "../../../services/TDRService";
import { format } from 'date-fns';
import CheckIcon from "@material-ui/icons/Send";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,TablePagination  } from '@mui/material';
import PlanningService from "../../../services/PlanningService";
import { DownloadOutlined } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function PlanningMissionTable() {
  const navigate = useNavigate();
  const [opens, setOpens] = React.useState(false);

  const [ openobservation, setOpenobservation] = useState(false);
  const handleClose = () => {
    setOpens(false);
  };

  const contextData = useContext(userDetailContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedFileRequestId, setSelectedFileRequestId] = useState(null);
  const [rows, setRows] = useState([]);
  const [details,setDetails]=useState([]);
  const [note,setNote]=useState([]);
  const [idplanif,setIdplanif]=useState(0);
  const [id_to_delete, setId_to_delete] = useState(0);
  const [envoie,setEnvoie]=useState(false);
  const [confirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog: 'Voulez vous vraiment supprimer  ?',
  });
  const { openDialog, messageDialog } = confirmationDialog;
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;
  
  //Samuela: paramètrage pour le loader
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
  const [statut_val,setStatut_val]=useState({ label: 'Nouveau', id: 'Nouveau' });
  const [tdr,setTDR]=useState([]);

  useEffect(() => {
    setOpenLoadingSimpleBackdrop(true);
    PlanningMissionService.getPlanning(statut_val.label).then((result) => {
      if(result && result.data){
        setRows(result.data?.map((row)=>({...row, id:row.mission_planning_id})));
        setOpenLoadingSimpleBackdrop(false);
      }else{
        setOpenLoadingSimpleBackdrop(false);
      }
    });


    

    TDRService.getlistTDR().then((result) => {
      if (result && result.data) {
        setTDR(result.data?.map((row) => ({ ...row, id: row.tdr_mission_id })))

      } else {

      }
    });
  }, [statut_val]);

  const handletdr = (filename, event) => {
    // Empêcher la propagation de l'événement (évitant que d'autres événements liés ne se déclenchent)
    event.stopPropagation();
    
    // Empêcher le comportement par défaut (par exemple, éviter un clic dans un formulaire qui soumet)
    event.preventDefault();
  
    // Si filename est dynamique, tu peux l'utiliser directement, sinon tu utilises la variable file ici.
    const file = filename || "mission_20250123154241.pdf";
    const destination = process.env.REACT_APP_API_URL_FILE; 
    const fileUrl = `${destination.replace(/\/$/, '')}/${file}`;
    
    // Créer un élément <a> temporaire pour déclencher le téléchargement
    const link = document.createElement('a');
    link.href = fileUrl; 
    link.download = file; // Nom du fichier téléchargé
    document.body.appendChild(link);
  
    // Déclencher le téléchargement
    link.click();
  
    // Supprimer l'élément <a> après le téléchargement
    document.body.removeChild(link);
  };
  
  

  function closeDialog(){
    setConfirmationDialog({...confirmationDialog, openDialog: false});
  }

  function handleCloseMessage(){
    setMessage({...message, open: false});
  }
  const handleDelete = (id, event) => {
    event.stopPropagation();
    setId_to_delete(id);
    setConfirmationDialog({...confirmationDialog, openDialog: true});
  };

  function closeDialog(){
    setConfirmationDialog({...confirmationDialog, openDialog: false});
  }
  function confirmDialog(){
    setConfirmationDialog({...confirmationDialog, openDialog: false});
    PlanningMissionService.deleteMissionPlanning(id_to_delete).then((result) => {
      if(result.status){
        setMessage({...message, open: true, color: 'green', messageInfo: result.message});
        PlanningMissionService.getPlanning(statut_val.label).then((result) => {
          if(result && result.data){
            setRows(result.data?.map((row)=>({...row, id:row.mission_planning_id})));
            setOpenLoadingSimpleBackdrop(false);
          }else{
            setOpenLoadingSimpleBackdrop(false);
          }
        });
        navigate("/mission/planning_mission");
      }else{
        setMessage({...message, open: true, color: 'red', messageInfo: result.message});
      }
    }).catch((err) => {
      setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
    });
  }

  function cancelDialog(){
    setConfirmationDialog({...confirmationDialog, openDialog: false});
  }
  const handleedit = (id) => (event) => {
    event.preventDefault();
    event.stopPropagation();
    navigate(`/mission/planning_mission/add/${id}`);
  };

  const handleenvoie = (id) => (event) => {
    navigate('/mission/planning_mission/detail/'+id);
  };


  const handlevalidation = () => {
    setOpens(false);
    navigate('/mission/planning_mission/detail/'+details.mission_planning_id);
  };

  // const handleplanning= () => {
  //    PlanningService.sendplanning(details.mission_planning_id).then((result) => {
  //     if(result){
  //       setMessage({...message, open: true, color: 'green', messageInfo:"Succès"});
  //       setOpens(false);
  //       setOpenLoadingSimpleBackdrop(true);
  //       setStatut_val({ label: 'Planning', id: 'Planning' });
  //       PlanningMissionService.getPlanning('Planning').then((result) => {
  //         if(result && result.data){
  //           setRows(result.data?.map((row)=>({...row, id:row.mission_planning_id})));
  //           setOpenLoadingSimpleBackdrop(false);
  //         }else{
  //           setOpenLoadingSimpleBackdrop(false);
  //         }
  //       });
  //     }else{
  //       setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
  //     }
  //   });
  // };

  const handleenvoieplanning= (id) => (event) => {
       event.stopPropagation();
       setEnvoie(true);
  }

  const seeobservation = (id) => (event) => {
        setIdplanif(id);
        event.stopPropagation();
        setOpenobservation(true);
        PlanningMissionService.getObservation(id).then((result) => {
          if(result && result.data){
              setNote(result.data);
          }else{
            setNote([]);
          }
        });
   
  }
 
  const handleCloseobservations = () => {
    setOpenobservation(false); 
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear();  
    return `${day}/${month}/${year}`;
  };

  const handlemofid=()=>{
    MissionService.updateplanification(idplanif).then((result) => {
      if(result){
        setMessage({...message, open: true, color: 'green', messageInfo:"Succès"});
        setOpenobservation(false);
        window.location.reload();
      }else{
        setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
      }
    });
  }

  const handleCloseobservation = () => {
    setOpenobservation(false);
   
  };
  const statut = [
    { label: 'Nouveau', id: 'Nouveau' },
    { label: 'Planning', id: 'Planning' },
    { label: 'Réel', id: 'Réel' }
   ];

  const columns = [
    { field: 'reference', headerName: 'Réference', width: 160, },
    { field: 'categorie', headerName: 'Categorie Mission', width: 200},
    { field: 'mission_title', 
      headerName: 'Objet de la mission', 
      width: 400
     },
    {
       field: 'state',
      headerName: 'Statut',
      width: 400,
      renderCell: (params) => {
        if (params.row.users && Array.isArray(params.row.users) && params.row.users.length > 0) {
          return (
            <div>
              {params.row.users.map((ts, index) => (
                <ul key={index}>
                  <li>{ts.nom}</li>
                </ul>
              ))}
            </div>
          );
        } else {
          return <div>Aucun utilisateur</div>;
        }
    }},
    { field: 'tdr_agenda', headerName: 'TDR/Agenda', 
    renderCell: (params) => {
      return (
        <strong>
          <Button>
            {params.row.tdr=="true" && <Subject style={{ color: "green" }} />}
            {params.row.tdr=="false" && <PostAdd style={{ color: "red" }} />}
          </Button>
          <Button>
            {params.row.agenda=="true" && <CalendarToday style={{ color: "green" }} />}
            {params.row.agenda=="false" && <CalendarTodayTwoTone style={{ color: "red" }} />}
          </Button>
        </strong>
      );
    },
    width: 200, },
    { field: 'action', headerName: 'Actions',   renderCell: (params) => {
      return (
        <strong>
          {statut_val.label==="Nouveau"&&
              <>
                <Button title="Envoyez pour validation" onClick={handleenvoieplanning(params.row.id)}>
                    <CheckIcon style={{ color: "green"}} />
                </Button>
                <Button title="Modifier le planning" onClick={handleedit(params.row.id)}>
                    <CreateTwoTone style={{ color: "black"}} />
                </Button>
                <Button title="Supprimer le planning" onClick={(event) => handleDelete(params.row.id, event)}>
                  <DeleteOutlineRounded style={{ color: 'red' }} />
                </Button>
                
              </>
          }
          {statut_val.label==="Planning"&&
           <>
                {params.row.stateplanning==="Planifié"&&
                  <Button title="Envoyez pour validation" onClick={handleenvoieplanning(params.row.id)}>
                    <CheckIcon style={{ color: "green"}} />
                  </Button>
                }
                <Button title="Modifier le planning" onClick={handleedit(params.row.id)}>
                    <CreateTwoTone style={{ color: "black"}} />
                </Button>
           </>
          }
           {statut_val.label==="Réel"&&
           <>

                {params.row.state==="Nouveau"&&
                  <Button title="Envoyez pour validation" onClick={handleenvoie(params.row.id)}>
                    <CheckIcon style={{ color: "green"}} />
                  </Button>
                }
                {(params.row.state !=="Refusé" || params.row.state !=="En attente de validation" )&&
                  <Button title="Modifier le " onClick={handleedit(params.row.id)}>
                    <CreateTwoTone style={{ color: "black"}} />
                  </Button>
                }   
           </>
          }
          {params.row.state==="Refusé"&&
           <>
              <Button title="Voir les observations" onClick={seeobservation(params.row.file_request_id)} >
                 <RemoveRedEyeIcon />
              </Button>
           </>
          }

        </strong>
      );
    }, width: 200, },
  ];
  
  function handleChangeTypeStatut(event, value) {
    setStatut_val(value);
  }

  const handleRowClick = (params) => {
    const rowId = params.id;
    PlanningMissionService.getdetails(rowId).then((result) => {
      if(result.status){
          setDetails(result.data[0]);
          setOpens(true);
      }else{
        setMessage({...message, open: true, color: 'red', messageInfo: result.message});
      }
    })
    .catch((err) => {
      setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
    });
};

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0); 
    };

    const displayedRows = rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  return (
    <React.Fragment>
      <ConfirmationDialog openDialog={openDialog} messageDialog={messageDialog} closeDialog={closeDialog} confirmDialog={confirmDialog} cancelDialog={cancelDialog} />
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
          <Grid item xs={3}>
                  <Autocomplete
                    id="tags-outlined"
                    options={statut}
                    getOptionLabel={(option) => option.label}
                    onChange={handleChangeTypeStatut}
                    value={statut_val}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        id="outlined-basic"
                        size="small"
                        label="Statut"
                        variant="outlined"
                        placeholder="Statut"
                      />
                    )}
                  />
                </Grid>
                <br/><br/>
      <Title>Planifications</Title>
      <Spacer y={2} />
      <div style={{ height: 400, width: '100%' }}>
        {/* <DataGrid
           rows={rows}
           columns={columns} 
           onRowClick={handleRowClick}
           /> */}
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="mission table">
                <TableHead>
                  <TableRow>
                    <TableCell>Réference</TableCell>
                    <TableCell>Categorie Mission</TableCell>
                    <TableCell>Objet de la mission</TableCell>
                    <TableCell>     
                      {statut_val.label === "Nouveau" && (
                         <>
                           Statut
                         </>
                      )}
                       {statut_val.label !== "Nouveau" && (
                         <>
                           Statut des missionaires
                         </>
                      )}
                    </TableCell>
                    <TableCell>TDR/Agenda</TableCell>
                    <TableCell>Créer par</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayedRows.length > 0 ? (
                    displayedRows.map((row) => (
                      <TableRow key={row.id} onClick={() => handleRowClick(row)}>
                        <TableCell>{row.reference}</TableCell>
                        <TableCell>{row.categorie}</TableCell>
                        <TableCell>{row.mission_title}</TableCell>
                        <TableCell>
                        {statut_val.label === "Nouveau" && (
                           <>
                             Nouveau
                           </>
                         )}
                         {statut_val.label !== "Nouveau" && (
                              row.users && Array.isArray(row.users) && row.users.length > 0 ? (
                                row.users.map((ts, index) => (
                                  <ul key={index}>
                                    <li>{ts.nom} {ts.validate}</li>
                                  </ul>
                                ))
                              ) : (
                                <div>Aucun utilisateur</div>
                              )
                            )}

                        </TableCell>
                        <TableCell>
                          <strong>
                            <Button>
                              {row.tdr === "true" && <Subject style={{ color: "green" }} />}
                              {row.tdr === "false" && <PostAdd style={{ color: "red" }} />}
                            </Button>
                            <Button>
                              {row.agenda === "true" && <CalendarToday style={{ color: "green" }} />}
                              {row.agenda === "false" && <CalendarTodayTwoTone style={{ color: "red" }} />}
                            </Button>
                          </strong>
                        </TableCell>
                        <TableCell>{row.nom_editeur}</TableCell>
                        <TableCell>
                          <strong>
                            {statut_val.label === "Nouveau" && (
                              <>
                                <Button title="Envoyez pour validation" onClick={() => handleenvoieplanning(row.id)}>
                                  <CheckIcon style={{ color: "green" }} />
                                </Button>
                                <Button
                                    title="Modifier le planning"
                                    onClick={() => navigate(`/mission/planning_mission/add/${row.id}`)} // Utilisation correcte de navigate
                                >
                                    <CreateTwoTone style={{ color: "black" }} />
                                </Button>
                                <Button title="Supprimer le planning" onClick={(event) => handleDelete(row.id, event)}>
                                  <DeleteOutlineRounded style={{ color: 'red' }} />
                                </Button>
                              </>
                            )}
                            {statut_val.label === "Planning" && (
                              <>
                                {row.stateplanning === "Planifié" && (
                                  <Button title="Envoyez pour validation" onClick={() => handleenvoieplanning(row.id)}>
                                    <CheckIcon style={{ color: "green" }} />
                                  </Button>
                                )}
                                {row.stateplanning!="En attente de validation" &&
                                   <Button
                                        title="Modifier le planning"
                                        onClick={() => navigate(`/mission/planning_mission/add/${row.id}`)} 
                                    >
                                        <CreateTwoTone style={{ color: "black" }} />
                                    </Button>
                                }
                                
                              </>
                            )}
                            {statut_val.label === "Réel" && (
                              <>
                                {row.state === "Nouveau" && (
                                  <Button title="Envoyez pour validation" onClick={() => handleenvoieplanning(row.id)}>
                                    <CheckIcon style={{ color: "green" }} />
                                  </Button>
                                )}
                                 {row.lienpdf && row.lienpdf !== "" &&  
                                  <Button title="Télécharger TDR" onClick={(event) => handletdr(row.lienpdf,event)}>
                                      <DownloadOutlined style={{ color: "#1a5fa1" }} />
                                  </Button>
                                }

                                {(row.state !== "Refusé" && row.state !== "En attente de validation") && (
                                   <Button
                                   title="Modifier le planning"
                                        onClick={() => navigate(`/mission/planning_mission/add/${row.id}`)} 
                                    >
                                        <CreateTwoTone style={{ color: "black" }} />
                                    </Button>
                                )}
                              </>
                            )}
                            {row.state === "Refusé" && (
                              <>
                                <Button title="Voir les observations" onClick={() => seeobservation(row.file_request_id)}>
                                  <RemoveRedEyeIcon />
                                </Button>
                              </>
                            )}
                          </strong>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">Aucune donnée à afficher</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}  
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
      </div>
      <Dialog
        open={opens}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md" 
      >
        <DialogTitle id="form-dialog-title" style={{backgroundColor:"#133E67"}}>
          {"Details de la mission réf: "}{details.reference}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <Grid container spacing={3}>
              <Grid item xs={3}>
                  <Typography variant="body1" align="right" >Objet de la mission :</Typography>
              </Grid>
              <Grid item xs={9}>
                  <Typography variant="body1">{details.mission_title}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant="body1" align="right" >Categorie :</Typography>
              </Grid>
              <Grid item xs={9}>
                {details.categorie && details.categorie.length > 0 ? 
                    details.categorie.map((ts, index) => (
                        <Typography key={index} variant="body1">{ts.name}{index !== details.categorie.length - 1 && ', '}</Typography>
                    )) 
                    : ''
                }
               </Grid>
                <Grid item xs={3}>
                    <Typography variant="body1" align="right" >Date:</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography variant="body1">{formatDate(details.date_begin)} au {formatDate(details.date_end)} </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="body1" align="right" >Lieu:</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography variant="body1">{details.lieu}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="body1" align="right" >Transport :</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography variant="body1">{details.transport}</Typography>
                </Grid>
                
                <Grid item xs={3}>
                  <Typography variant="body1" align="right" >Missionaires :</Typography>
                </Grid>
                <Grid item xs={9}>
                  {details.missionnary && details.missionnary.length > 0 ? 
                      details.missionnary.map((ts, index) => (
                          <Typography key={index} variant="body1">{ts.nom}{index !== details.missionnary.length - 1 && ', '}</Typography>
                      )) 
                      : ''
                  }
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="body1" align="right" >Autre participant :</Typography>
                </Grid>
                <Grid item xs={9}>
                {details.partnership && typeof details.partnership === 'string' && details.partnership.length > 0 ? (
                    JSON.parse(details.partnership).map((ts, index) => (
                      <Typography key={index} variant="body1">
                        {ts}{index !== JSON.parse(details.partnership).length - 1 && ', '}
                      </Typography>
                    ))
                  ) : (
                    ''
                  )}
                </Grid>

                
                    {details.tdrref && (
                      <>
                      <Grid item xs={3}>
                            <Typography variant="body1" align="right" >TDR :</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="body1" >
                                {details.tdrref.title}-{details.tdrref.reference}
                              </Typography>  
                        </Grid>
                      </>
                    
                    )}                
                    
                  {details.agedref !== null && details.agedref !== undefined && (
                    <>
                      <Grid item xs={3}>
                          <Typography variant="body1" align="right" >Agenda :</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="body1" >
                            {details.agedref.title}-{details.agedref.reference}
                          </Typography>
                      </Grid>
                    </>
                  )}

             
            
          </Grid>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
           {/* {envoie && */}
                  <>
                     {/* <Button style={{ backgroundColor: 'rgb(19, 62, 103)', color: "#FFFFFF" }} variant="contained" color="primary" onClick={handleplanning}>Envoyez pour validation planning</Button>  */}
                     <Button style={{ backgroundColor: '#425b8a', color: "#FFFFFF" }} variant="contained" color="#425b8a" onClick={handlevalidation}>Envoyez pour validation réel</Button> 
                  </>
            {/* } */}
          <Button variant="outlined" onClick={handleClose} autoFocus>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
      <div>
        <Dialog open={openobservation} onClose={handleCloseobservation}>
        <DialogTitle id="form-dialog-title" color="primary"  >Observation refus du mission</DialogTitle>
          <DialogContent>
            <DialogContentText>  
            <Grid container justifyContent="center" spacing={2}> 
              
                <Grid container item spacing={2}  xs={8} >
                  <Grid item xs={5}>
                    <label >Context :</label>
                  </Grid>
                  <Grid item xs={7}>
                    <label >{note.Context}</label>
                  </Grid>
                  <Grid item xs={5}>
                    <label >Objectifs :</label>
                  </Grid>
                  <Grid item xs={7}>
                    <label >{note.Objectifs}</label>
                  </Grid>
                  <Grid item xs={5}>
                    <label >Resulat attendus</label>
                  </Grid>
                  <Grid item xs={7}>
                    <label >{note["Resulat attendus"]}</label>
                  </Grid>
                  <Grid item xs={5}>
                    <label >Tâche :</label>
                  </Grid>
                  <Grid item xs={7}>
                    <label >{note.Tâche}</label>
                  </Grid>
                  <Grid item xs={5}>
                    <label >Agenda :</label>
                  </Grid>
                  <Grid item xs={7}>
                    <label >{note.Agenda}</label>
                  </Grid>
                </Grid>
          </Grid>

            </DialogContentText>
          </DialogContent>
          <DialogActions> 
              <Button style={{ backgroundColor: 'rgb(19, 62, 103)', color: "#FFFFFF" }} variant="contained" color="primary" onClick={handlemofid} >MODIFIER planning</Button> 
              <Button variant="contained" color="secondary" onClick={handleCloseobservations} >FERMER</Button>
          </DialogActions>
      </Dialog>
      </div>
    </React.Fragment>
  );
}
