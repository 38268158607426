import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TablePagination } from '@material-ui/core';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const generateDateRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#425b8a",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const groupDaysByMonth = (dates) => {
    const months = {};
    dates.forEach(date => {
        const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
        if (!months[monthKey]) {
            months[monthKey] = [];
        }
        months[monthKey].push(date.getDate());
    });
    return months;
};

const DateRangeTable = ({ startDate, endDate, datatable }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const dates = generateDateRange(startDate, endDate);
    const monthsGrouped = groupDaysByMonth(dates);

    const monthKeys = Object.keys(monthsGrouped).sort((a, b) => {
        const [yearA, monthA] = a.split('-').map(Number);
        const [yearB, monthB] = b.split('-').map(Number);
        return yearA === yearB ? monthA - monthB : yearA - yearB;
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div id="table-to-pdf">
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '10%', backgroundColor: '#D3D3D3' }}></TableCell>
                            {monthKeys.map((monthKey) => {
                                const [year, month] = monthKey.split('-').map(Number);
                                const monthName = new Date(year, month - 1).toLocaleString('default', { month: 'long' });
                                const daysInMonth = monthsGrouped[monthKey].length;
                                return (
                                    <TableCell key={monthKey} colSpan={daysInMonth} style={{ backgroundColor: '#D3D3D3' }}>
                                        {monthName.toLowerCase()}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ width: '10%', border: '1px solid #ddd', backgroundColor: '#D3D3D3' }}></TableCell>
                            {monthKeys.map((monthKey) =>
                                monthsGrouped[monthKey].map((day) => (
                                    <TableCell style={{ border: '1px solid #ddd', backgroundColor: '#D3D3D3' }} key={`${monthKey}-${day}`}>
                                        {day}
                                    </TableCell>
                                ))
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datatable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((ts, index) => {
                            // Simplified condition to highlight rows
                            const isHighlighted = monthKeys.some((monthKey) =>
                                monthsGrouped[monthKey].some((day) => day >= ts.debut && day <= ts.fin && parseInt(ts.moiss, 10) === parseInt(monthKey.split('-')[1], 10))
                            );

                            return isHighlighted ? (
                                <TableRow key={`row-${index}`}>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        style={{
                                            position: 'sticky',
                                            left: 0,
                                            backgroundColor: 'white',
                                            zIndex: 1,
                                        }}
                                    >
                                        {ts.ligne}
                                    </TableCell>
                                    {monthKeys.map((monthKey) =>
                                        monthsGrouped[monthKey].map((day, dayIndex) => {
                                            const isDayHighlighted = day >= ts.debut && day <= ts.fin && parseInt(ts.moiss, 10) === parseInt(monthKey.split('-')[1], 10);
                                            const colors =
                                                ts.state === 'En attente de validation'
                                                    ? '#cecece'
                                                    : ts.state === 'Planifié'
                                                    ? '#78b785'
                                                    : ts.state === 'Nouveau'
                                                    ? '#87CEEB'
                                                    :ts.state.toLowerCase() === "validé" || ts.state.toLowerCase() === "valide"
                                                    ?'#78b785'
                                                    : 'red';

                                            return (
                                                <TableCell
                                                    key={`day-${monthKey}-${dayIndex}`}
                                                    style={{
                                                        backgroundColor: isDayHighlighted ? colors : 'transparent',
                                                        color: isDayHighlighted ? 'white' : 'black',
                                                        textAlign: 'center',
                                                        border: '1px solid #ddd',
                                                    }}
                                                >
                                                    {isDayHighlighted ? ts.valeur : ''}
                                                </TableCell>
                                            );
                                        })
                                    )}
                                </TableRow>
                            ) : null;
                        })}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={datatable.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </div>
    );
};

export default DateRangeTable;

