import { useContext, useState } from "react";
import MainContainer from "../../../components/MainContainer";
import { headerPersonnalEvalutaion } from "../../../data/modules/personnal-evaluation-header";
import AppPageForTableList from "../../personal_evaluation/shared/AppPageForTableList";
import { useNavigate } from "react-router";
import React from "react";
import { hasPrivilege } from "../../../utils/privilege";
import { userDetailContext } from "../../../App";
import useToast from "../../../hooks/useToast";
import { TYPE_NOTIFICATION } from "../../../components/reusable/NotificationBull";
import EmplacementFiltre from "./EmplacementFiltre";
import { headerGed } from "../../../data/modules/ged-header";

const EmplacementListe = () => {
  const contextData = useContext(userDetailContext);
  const { Toast, showToast } = useToast();
  const navigate = useNavigate();

  const handleClickCreate = () => {
    if (hasPrivilege(contextData.privileges, "Button_create_module")) {
      navigate("/emplacement/add/");
    } else {
      showToast(
        TYPE_NOTIFICATION.ERROR,
        "Vous n'avez pas l'autorisation de créer un rubrique"
      );
    }
  };

  return (
    <MainContainer {...headerGed}>
      <Toast />
      <AppPageForTableList
        title={"Liste des emplacements"}
        button_creer_title={"Creer"}
        handleClickBtnCreer={handleClickCreate}
        filter_component={<EmplacementFiltre />}
      />
    </MainContainer>
  );
};

export default EmplacementListe;
