import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DownloadIcon from "@mui/icons-material/Download";
import SettingsApplicationsSharpIcon from "@mui/icons-material/SettingsApplicationsSharp";
import SettingsIcon from "@mui/icons-material/Settings";
import { DataGrid } from "@material-ui/data-grid";
import Message from "../../personnel/Message";
import Result from "../../personnel/Result";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DocumentService from "../../../services/DocumentService";
import * as FileSaver from "file-saver";
import PrivilegeDialog from "../../tools/PrivilegeDialog";
import PrivilegeButton from "../../tools/PrivilegeButton";
import RubriquesService from "../../../services/RubriquesService";
import { hasPrivilege } from "../../../utils/privilege";
import { userDetailContext } from "../../../App";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddLocationDialog from "../../tools/AddLocationDialog";
import useToast from "../../../hooks/useToast";
import { TYPE_NOTIFICATION } from "../../../components/reusable/NotificationBull";
import { Button, Grid } from "@mui/material";
import { exportToExcel } from "../../../helpers/ExportHelpers";
import LoupeIcon from "@mui/icons-material/Loupe";

export default function DocumentTable(props) {
  const navigate = useNavigate();

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const contextData = useContext(userDetailContext);

  const location = useLocation();

  const { pathname } = location;
  const { Toast, showToast } = useToast();

  const [fileNameToDownload, setFileNameToDownload] = useState("");

  const [documentIdToDownload, setDocumentIdToDownload] = useState(0);

  const [isid, setIsid] = useState(0);

  const [ip, setIp] = useState(0);

  const [documentIdToSetPrivilege, setDocumentIdToSetPrivilege] = useState([]);

  const [selectedId, setSelectedId] = useState(0);

  const [document, setDocument] = useState([]);

  const [etat, setEtat] = useState({
    openDeleteDialog: false,
    message: "message",
    accept: false,
  });

  const [etats, setEtats] = useState({
    open: false,
    message: "message",
    color: "",
  });

  const [download, setDownload] = useState({
    openDownloadDialog: false,
    message: "message",
    accept: false,
  });

  const filtre = props.filtre;
  const excelExport = props.excelExport;

  const handleFetchDocuments = () => {
    setOpenLoadingSimpleBackdrop(true);
    DocumentService.getDocument(filtre)
      .then((result) => {
        if (result && result.data) {
        } else {
          setRows([]);
        }
      })
      .finally(() => {
        setOpenLoadingSimpleBackdrop(false);
      });
  };

  const handleRefreshDataOnTable = () => {
    setOpenLoadingSimpleBackdrop(true);
    const userId = localStorage.getItem("USER");
    const filteredRows = [];
    DocumentService.getDocument(filtre).then((result) => {
      if (result && result.data) {
        setDocument(result.data);
        const documentIdLIst = result.documentIdLIst;
        documentIdLIst.map((id) =>
          DocumentService.getAction(userId, id)
            .then((response) => {
              if (response && response.status) {
                const action = response.data.action;
                //Vérifier si l'utilisateur connecté a le droit de configurer le privilège d'un document
                if (action[8] === "1") {
                  setDocumentIdToSetPrivilege((oldArray) => [...oldArray, id]);
                }
                //filtrer les documents que l'utilisateur connecté peut voir
                const matchingRows = result.data.filter(
                  (row) => row.id === id && action[2] === "1"
                );
                filteredRows.push(...matchingRows);
                setRows(filteredRows.map((row) => ({ ...row, id: row.id })));
              }
            })
            .finally(() => {
              setOpenLoadingSimpleBackdrop(false);
            })
        );

        setOpenLoadingSimpleBackdrop(false);
      } else {
        setDocument([]);
        setRows([]);
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  };

  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =
    useState(false);

  const [addLocationForId, setAddLocationForId] = useState([]);

  const [rows, setRows] = useState([]);

  const columns = [
    {
      field: "codification",
      headerName: "Codification",
      width: 200,
    },
    {
      field: "titre",
      headerName: "Titre",
      width: 300,
    },

    {
      field: "rubrique_mere",
      headerName: "Rubrique mère",
      width: 200,
    },

    {
      field: "code_couleur",
      headerName: "Couleur rubrique mère",
      width: 230,
      renderCell: (params) => {
        return (
          <>
            {document.map((item) => {
              if (item.id == params.id) {
                return (
                  <div
                    style={{
                      background: item.code_couleur,
                      width: "60px",
                      height: "20px",
                      marginLeft: "50px",
                    }}
                  ></div>
                );
              }
            })}
          </>
        );
      },
    },

    {
      field: "auteur",
      headerName: "Uploader",
      width: 300,
    },

    {
      field: "resume",
      headerName: "Resumé",
      width: 300,
    },

    {
      field: "date_creation",
      headerName: "Date de création",
      width: 200,
    },

    {
      field: "date_edition",
      headerName: "Date de modification",
      width: 230,
    },

    {
      field: "mot_cle",
      headerName: "Mots clé",
      width: 250,
    },

    {
      field: "fichier",
      headerName: "Nom du fichier",
      width: 250,
    },

    {
      field: "label",
      headerName: "Etat physique du document",
      width: 300,
    },

    {
      field: "current_holder",
      headerName: "Detenteur(s) actuel",
      width: 350,
    },

    {
      field: "location",
      headerName: "Emplacement",
      width: 700,
    },
    {
      field: "action",
      headerName: "Action",
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <Grid
            container
            spacing={1}
            mt={0.5}
            height={"100%"}
            alignItems="center"
          >
            {hasPrivilege(
              contextData.privileges,
              "Button_setting_privilege"
            ) === true ? (
              <Button
                onClick={(e) => handlePrivilegeDialog(e, params.id)}
                title="Configurer les privilèges"
              >
                <SettingsIcon style={{ color: "grey" }} />
              </Button>
            ) : (
              documentIdToSetPrivilege.map((id) => {
                if (params.id == id) {
                  return (
                    <Button
                      onClick={(e) => handlePrivilegeDialog(e, params.id)}
                      title="Configurer les privilèges"
                    >
                      <SettingsApplicationsSharpIcon
                        style={{ color: "grey" }}
                      />
                    </Button>
                  );
                } else return null;
              })
            )}

            <Button
              onClick={(e) => {
                handleOpenAddLocationDialog(e, params.id);
              }}
              title="Ajouter ou modifier l' emplacement du document"
              color="secondary"
            >
              <LoupeIcon />
            </Button>

            <Button
              onClick={handleDownload(params.id)}
              title="Télécharger le fichier"
              color="primary"
            >
              <DownloadIcon />
            </Button>
            {/* <Button
              onClick={(e) => handleClickDownload(params.id)}
              title="Télécharger le fichier"
              style={{ color: "green" }}
              
            >
              Down
            </Button> */}

            <PrivilegeButton
              documentId={params.id}
              userId={localStorage.getItem("USER")}
              edit={(e) => handleEditDocument(e, params.id)}
              delete={(e) => handleDeleteDocument(e, params.id)}
              moduleType={"document"}
            />
          </Grid>
        );
      },
      width: 300,
    },
  ];

  const [privilegeDialog, setPrivilegeDialog] = useState({
    open: false,
  });

  const [addLocationDialog, setAddLocationDialog] = useState({
    open: false,
  });

  function handleDeleteDocument(event, id) {
    setEtat({
      openDeleteDialog: true,
      message: "Voulez-vous vraiment supprimer ?",
    });
    setIsid(id);
  }

  function handleAccept() {
    setEtat({
      open: false,
      message: "",
      accept: true,
    });

    DocumentService.deleteDocument(isid).then((results) => {
      const userId = localStorage.getItem("USER");
      const deletedDocumenteId = results.deletedDocument["document_id"];
      const deletedDocumentName = results.deletedDocument["titre"];

      RubriquesService.saveHistory(
        userId,
        deletedDocumenteId,
        deletedDocumentName,
        "Suppression de document"
      ).then((historyResponse) => {
        if (historyResponse.status) {
          console.log("Historique de suppression de document enregistrée");
        } else {
          console.log("Historique de suppression de document non enregistrée");
        }
      });

      handleFetchDocuments();

      if (results.status) {
        setEtats({
          ...etats,
          open: true,
          color: "green",
          message: results.message,
        });
        navigate("/document");
      } else {
        setEtats({
          ...etats,
          open: true,
          color: "red",
          message: results.message,
        });
      }
    });
  }

  function handleMessage() {
    setEtat({
      open: false,
      message: "",
    });
    setDownload({
      openDownloadDialog: false,
    });
  }

  const handleDownload = (id) => (ev) => {
    setDownload({
      openDownloadDialog: true,
      message: "Voulez-vous télécharger le fichier ?",
    });

    setDocumentIdToDownload(id);
  };

  const handleClickDownload = (id) => {
    DocumentService.downloadFileById(id)
      .then((res) => {
        if (res && res.response && res.response.status !== 200) {
          showToast(TYPE_NOTIFICATION.ERROR, res.response.statusText);
        }
      })
      .catch((e) => {
        console.log("ee", e);

        showToast(TYPE_NOTIFICATION.ERROR, e.toString());
      });
  };

  function handleAcceptDownload() {
    setDownload({
      open: false,
      message: "",
      accept: true,
    });

    const userId = localStorage.getItem("USER");

    DocumentService.getFichier(documentIdToDownload).then((result) => {
      if (result && result.data) {
        setFileNameToDownload(result.data.fichier);

        const filenameResult = result.data.fichier;
        DocumentService.readFileContent(filenameResult).then((results) => {
          if (results) {
            const data = new Blob([results.data], { type: fileType });
            FileSaver.saveAs(data, filenameResult);
          }
        });

        RubriquesService.saveHistory(
          userId,
          documentIdToDownload,
          result.data.fichier,
          "Télechargement du fichier",
          ip
        ).then((response) => {
          if (response.status) {
            console.log("Historique ajoutée pour le telechargement");
          }
        });
      }
    });
  }

  function handleCloseMessage() {
    setEtats({ ...etats, open: false });
    setDownload({
      openDownloadDialog: false,
    });
  }

  function handleEditDocument(e, id) {
    navigate("/document/add/" + id);
  }

  function handlePrivilegeDialog(e, id) {
    setPrivilegeDialog({
      ...privilegeDialog,
      open: true,
    });
    setSelectedId(id);
  }

  function handleClosePrivilegeDialog() {
    setPrivilegeDialog({ ...privilegeDialog, open: false });
  }

  const getIp = async () => {
    try {
      const response = await fetch("https://ipapi.co/json/");
      const data = await response.json();
      setIp(data.ip);
    } catch (error) {}
  };

  useEffect(() => {
    handleRefreshDataOnTable();
  }, []);

  useEffect(() => {
    getIp();
    if (filtre.refresh) {
      handleRefreshDataOnTable();
    }
    if (excelExport) {
      exportToExcel(rows, columns, "Liste des documents " + new Date());
    }
  }, [filtre.refresh, excelExport]);

  function handleOpenAddLocationDialog(e, documentId) {
    setAddLocationDialog({
      ...addLocationDialog,
      open: true,
    });

    setAddLocationForId(documentId);
  }

  function handleCloseAddLocationDialog(e) {
    setAddLocationDialog({
      ...addLocationDialog,
      open: false,
    });
    handleRefreshDataOnTable();
  }

  return (
    <>
      <Toast />
      <React.Fragment>
        <AddLocationDialog
          openAddLocationDialog={addLocationDialog.open}
          closeAddLocationDialog={(e) => {
            handleCloseAddLocationDialog(e);
          }}
          documentId={addLocationForId}
        />

        <Result
          message={etats.message}
          color={etats.color}
          open={etats.open}
          handleClose={handleCloseMessage}
        />

        <Message
          isActive={download.openDownloadDialog}
          message={download.message}
          accept={download.accept}
          handleClose={handleMessage}
          handleConfirm={handleAcceptDownload}
        />

        <Message
          isActive={etat.openDeleteDialog}
          message={etat.message}
          accept={etat.accept}
          handleClose={handleMessage}
          handleConfirm={handleAccept}
        />

        <LoadingSimpleBackdrop
          openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
        />
        <Grid height={"62vh"}>
          <DataGrid rows={rows} columns={columns} rowsPerPageOptions={[10]} />
        </Grid>
        <PrivilegeDialog
          isActive={privilegeDialog.open}
          handleClose={handleClosePrivilegeDialog}
          selectedId={selectedId}
          typePrivilege={"document"}
        />
      </React.Fragment>
    </>
  );
}
