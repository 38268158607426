import { useState } from "react";
import DocumentTable from "./DocumentTable";
import React from "react";
import { DownloadOutlined } from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';

import { Button, Grid, TextField } from "@mui/material";

export default function DocumentFiltre(props) {
  const [filtre, setFiltre] = useState({
    mot_cle: "",
    refresh: 0,
    level: 5,
  });

  const [excelExport, declenceExportToExcel] = useState(0);

  const downloadExcel = () => {
    declenceExportToExcel(excelExport + 1);
  };
  const handleRefreshData = () => {
    setFiltre({ ...filtre, refresh: filtre.mot_cle + 1 });
  };

  const handlechangeFiltre = (evt) => {
    const { name, value } = evt.target;
    setFiltre({ ...filtre, [name]: value });
  };

  return (
    <Grid display={"flex"} flexDirection={"column"} height={"70vh"}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <TextField
          sx={{ width: 400 }}
          size="small"
          name="mot_cle"
          value={filtre.mot_cle}
          label="Mot clé"
          onChange={handlechangeFiltre}
          className="filtre_list"
        />
        <Button
          variant="outlined"
          className="filtre_list"
          onClick={handleRefreshData}
        >
          <SearchIcon />
        </Button>
        <Button
          variant="outlined"
          className="filtre_list"
          onClick={downloadExcel}
        >
          <DownloadOutlined /> Excel export
        </Button>
      </Grid>
      <Grid flexGrow={1} mt={3}>
        <DocumentTable filtre={filtre} excelExport={excelExport}  />
      </Grid>
    </Grid>
  );
}
