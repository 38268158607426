import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import BanqueDeCvService from "../../services/BanqueCvService";
import Spacer from "../../components/Spacer";
import { Tooltip, Typography } from "@material-ui/core";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { exportToExcel } from "../../helpers/ExportHelpers";
import { LoadingButton } from "@mui/lab";
import { DownloadOutlined } from "@mui/icons-material";
import useToast from "../../hooks/useToast";
import { TYPE_NOTIFICATION } from "../../components/reusable/NotificationBull";

import { format, fromUnixTime } from "date-fns";
import { fr } from "date-fns/locale";
import { Grid } from "@mui/material";

export const INTERNAL_FILE_STORAGE_PATH = process.env.REACT_APP_API_URL.replace(
  "api",
  "uploads"
);
//export const INTERNAL_FILE_STORAGE_PATH = "pgi.fid.mg/pgibackend/uploads";
export const INTERNAL_CV_STORAGE_PATH = INTERNAL_FILE_STORAGE_PATH + "/cv/";
export const INTERNAL_LM_STORAGE_PATH = INTERNAL_FILE_STORAGE_PATH + "/lm/";
export const INTERNAL_NIF_STORAGE_PATH = INTERNAL_FILE_STORAGE_PATH + "/nif/";
export const INTERNAL_PHOTO_STORAGE_PATH =
  INTERNAL_FILE_STORAGE_PATH + "/photo/";
export const INTERNAL_STAT_STORAGE_PATH = INTERNAL_FILE_STORAGE_PATH + "/stat/";
export const INTERNAL_DIPLOME_STORAGE_PATH =
  INTERNAL_FILE_STORAGE_PATH + "/diplome/";
export const INTERNAL_ATTESTATION_STORAGE_PATH =
  INTERNAL_FILE_STORAGE_PATH + "/attestation/";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function CvList(props) {
  const [rows, setRows] = useState([]);
  const [tableColumns, setTableColumn] = useState([]);
  const [load, setLoad] = useState([]);
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =
    React.useState(false);
  const { Toast, showToast } = useToast();

  const classes = useStyles();
  const filter = props.filter;

  useEffect(() => {
    handleSwitchCVinternExtern(filter.isCVIntern);
    refreshTable();
  }, [filter.refresh, filter.isCVIntern]);

  useEffect(() => {
    handleExportToExcel(props.excel_export);
  }, [props.excel_export]);

  useEffect(() => {
    console.log("load ", load);
  }, [load]);

  const refreshTable = () => {
    setOpenLoadingSimpleBackdrop(true);
    BanqueDeCvService.list(filter).then((result) => {
      if (result && result.data) {
        setRows(result.data.map((row) => ({ ...row, id: row.ref })));
        setOpenLoadingSimpleBackdrop(false);
      } else {
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  };

  const handleAddIdToLoad = (id) => {
    let load_copy = load.slice();
    if (!Array.isArray(load)) {
      load_copy = [];
    }
    load_copy.push(id);
    setLoad(load_copy);
  };

  const handleRemoveIdToLoad = (id) => {
    let load_copy = load.slice();
    if (!Array.isArray(load)) {
      load_copy = [];
    }
    load_copy.splice(load_copy.indexOf(id), 1);
    setLoad(load_copy);
  };

  const handleDownloadZip = (row) => {
    handleAddIdToLoad(row.us_id);
    console.log(
      "row ",
      row,
      "load ",
      load,
      "r=test ",
      load.includes(row.us_id)
    );

    showToast(TYPE_NOTIFICATION.INFO, "Chargement des données à compresser...");
    BanqueDeCvService.downloadUploadedFiles(row)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Document de " + row.full_name + ".zip");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        showToast(TYPE_NOTIFICATION.ERROR, "Aucun fichier à télécharger");
      })
      .finally(() => {
        handleRemoveIdToLoad(row.us_id);
      });
  };

  const handleShowSelectedCV = (rw) => (ev) => {
    handleAddIdToLoad(rw.us_id);
    showToast(
      TYPE_NOTIFICATION.INFO,
      "Vous allez être redirigé vers le CV dans un instant ..."
    );
    try {
      if (filter.isCVIntern) {
        BanqueDeCvService.showCvByUserId(rw.us_id)
          .then((result) => {
            if (result.data) {
              window.open(INTERNAL_CV_STORAGE_PATH + result.data, "_blank");
            } else {
              showToast(
                TYPE_NOTIFICATION.ERROR,
                rw.full_name + " ne dispose pas de CV dans le systeme"
              );
            }
          })
          .finally(() => {
            handleRemoveIdToLoad(rw.us_id);
          });
      } else {
        BanqueDeCvService.showCvByUserIdexterne(rw.us_id)
          .then((result) => {
            if (result.data) {
              window.open(INTERNAL_CV_STORAGE_PATH + result.data, "_blank");
            } else {
              showToast(
                TYPE_NOTIFICATION.ERROR,
                rw.full_name + " ne dispose pas de CV dans le systeme"
              );
            }
          })
          .finally(() => {
            handleRemoveIdToLoad(rw.us_id);
          });
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  const handleExportToExcel = (stat) => {
    let titre =
      "Banque de CV " +
      filter.formation +
      " " +
      filter.diplome +
      " " +
      filter.competence;
    if (stat > 0) {
      if (!(filter.direction_id === 0 || filter.direction_id === "")) {
        titre += +" " + filter.direction_id.name;
        if (!(filter.service_id === 0 || filter.service_id === ""))
          titre += +" " + filter.service_id.name;
      }
      let cols = tableColumns.slice();
      if (!filter.isCVIntern) {
        cols.unshift({
          field: "date_creation",
          headerName: "Date de soumission",
          width: 200,
        });
      }
      exportToExcel(rows, cols, titre);
    }
  };

  const handleSwitchCVinternExtern = (candidatExtern) => {
    setTableColumn(columns);
    if (!candidatExtern) {
      const cvIntFieldExcludeFiltre = [
        "direction_name",
        "service_name",
        "matricule",
        "poste",
        "formation_fid",
      ];
      setTableColumn(
        columns.filter(
          (element) => !cvIntFieldExcludeFiltre.includes(element.field)
        )
      );
    } else {
      const cvIntFieldExcludeFiltre = [
        "objet_candidature",
        "date_envoie",
        "state",
        "adresse",
        "contact",
        "email",
      ];
      setTableColumn(
        columns.filter(
          (element) => !cvIntFieldExcludeFiltre.includes(element.field)
        )
      );
    }
  };

  const columns = [
    {
      field: "matricule",
      headerName: "Matricule",
      width: 150,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "full_name",
      headerName: "Nom et prenoms",
      width: 300,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "adresse",
      headerName: "Adresse",
      width: 300,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "contact",
      headerName: "Contact",
      width: 250,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "email",
      headerName: "Adrresse email",
      width: 300,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "objet_candidature",
      headerName: "Objet candidature",
      width: 300,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "date_envoie",
      headerName: "Date d'envoie",
      width: 250,
      renderCell: (params) => (
        <Tooltip
          title={format(fromUnixTime(params.value), "dd MMMM yyyy à HH:mm:ss", {
            locale: fr,
          })}
        >
          <Typography>
            {format(fromUnixTime(params.value), "dd MMM yyyy à HH:mm:ss", {
              locale: fr,
            })}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "poste",
      headerName: "Fonction",
      width: 300,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "direction_name",
      headerName: "Direction",
      width: 150,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "service_name",
      headerName: "Service",
      width: 200,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "diplome_formation",
      headerName: "Diplômes et Formations",
      width: 400,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "competence",
      headerName: "Domaines de compétence",
      width: 300,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "sous_domaine_competence",
      headerName: "Sous domaine de compétence",
      width: 300,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "state",
      headerName: "Status dossier",
      width: 180,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "dispose_un_cv",
      headerName: "Avoir CV",
      width: 150,
    },
    {
      field: "libelle_actions",
      headerName: "Actions",
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip
              title={"Cliquer pour afficher le cv de " + params.row.full_name}
            >
              <span>
                <LoadingButton
                  onClick={handleShowSelectedCV(params.row)}
                  color="primary"
                  variant="outlined"
                  loadingPosition="start"
                  loading={load.includes(params.row.us_id)}
                  disabled={params.row.dispose_un_cv === "Non"}
                  startIcon={
                    <VisibilityOutlinedIcon
                    /*  style={{
                      color:
                        params.row.dispose_un_cv !== "Non"
                          ? "blue"
                          : "GrayText",
                      display: load ? "none" : "",
                    }} */
                    />
                  }
                >
                  <Typography> Voir Cv</Typography>
                </LoadingButton>
              </span>
            </Tooltip>
            <Tooltip
              title={
                "Cliquer pour telecharger tous les fichiers envoyés par " +
                params.row.full_name
              }
            >
              <LoadingButton
                sx={{ ml: 1 }}
                onClick={() => handleDownloadZip(params.row)}
                color="secondary"
                variant="outlined"
                loadingPosition="start"
                loading={load.includes(params.row.us_id)}
              >
                <DownloadOutlined />
              </LoadingButton>
            </Tooltip>
          </div>
        );
      },
      width: 400,
    },
  ];

  return (
    <React.Fragment>
      <Toast />
      <LoadingSimpleBackdrop
        openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
      />
      <Spacer y={2} />
      <div style={{ height: "60vh", width: "100%" }} className={classes.root}>
        <DataGrid
          rows={rows}
          columns={tableColumns}
          pageSize={7}
          rowsPerPageOptions={[7]}
          disableSelectionOnClick
        />
      </div>
      <Grid
        pt={1}
        display={filter.isCVIntern ? "none" : "flex"}
        flexDirection={"row"}
      >
        <label>{` ${rows.reduce(
          (somme, row) => somme + (row.state === "complet" ? 1 : 0),
          0
        )} dossier(s) complet et ${rows.reduce(
          (somme, row) => somme + (row.state !== "complet" ? 1 : 0),
          0
        )} dossier(s) non complet`}</label>
      </Grid>
    </React.Fragment>
  );
}
