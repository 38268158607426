import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MainContainer from "../../components/MainContainer";
import { headerDataRH } from "../../data/modules/hr-header";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, CircularProgress } from '@mui/material';
import BanqueDeCvService from "../../services/BanqueCvService";
import Grid from '@material-ui/core/Grid';
import { Button, MenuItem, Select, Tooltip, Typography, TextField } from '@material-ui/core';
import Spacer from '../../components/Spacer';
import PlanningMissionService from "../../services/PlanningMissionService";
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { extraData } from './ExtraData';
import { exportToExcel } from "../../helpers/ExportHelpers";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
}));

export const INTERNAL_FILE_STORAGE_PATH = process.env.REACT_APP_API_URL.replace("api", "uploads/cv/");

export default function CVDetail(props) {
  const [rows, setRows] = useState([]);
  const titre = (true) ? "Liste des CV internes" : "Liste des CV externes";
  const [candidatIntern,setCandidatecterne]=useState(true);
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = useState(false);
  const [direction, setDirection] = useState([]);
  const [service, setService] = useState([]);
  const [domaine_competence, setDomaineCompetence] = useState([]);
  const [sous_domaine_competence, setSousDomaineCompetence] = useState([]);
  const [active_offre_emplois, setActiveOffreEmplois] = useState([]);
  const [excelExport, declenceExportToExcel] = useState(0);
  const [filtre, setFiltre] = useState({
    direction_id: "",
    service_id: "",
    formation: "",
    diplome: "",
    refresh: 0,
    domaine_comp: "",
    sous_domaine: "",
    mot_cle: "",
    objet_candidature: "",
    isCVIntern: true,
    excelExport:0
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const refreshTable = () => {
    setOpenLoadingSimpleBackdrop(true);
    BanqueDeCvService.list(filtre).then((result) => {
      if (result && result.data) {
        setRows(result.data.map((row) => ({ ...row, id: row.ref })));
        setOpenLoadingSimpleBackdrop(false);
      } else {
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  };

 

    const columns = [
        {
          field: 'matricule',
          headerName: 'Matricule',
          width: 150,
          renderCell: (params) => (
            <Tooltip title={params.value}>
              <Typography>
                {params.value}
              </Typography>
            </Tooltip>
          ),
        }, {
          field: 'full_name',
          headerName: 'Nom et prenoms',
          width: 300,
          renderCell: (params) => (
            <Tooltip title={params.value}>
              <Typography>
                {params.value}
              </Typography>
            </Tooltip>
          ),
        },
       
        {
          field: 'poste',
          headerName: 'Fonction',
          width: 300,
          renderCell: (params) => (
            <Tooltip title={params.value}>
              <Typography>
                {params.value}
              </Typography>
            </Tooltip>
          ),
        },
        {
          field: 'direction_name',
          headerName: 'Direction',
          width: 300,
          renderCell: (params) => (
            <Tooltip title={params.value}>
              <Typography>
                {params.value}
              </Typography>
            </Tooltip>
          ),
        },
        {
          field: 'service_name',
          headerName: 'Service',
          width: 400,
          renderCell: (params) => (
            <Tooltip title={params.value}>
              <Typography>
                {params.value}
              </Typography>
            </Tooltip>
          ),
        },
        {
          field: 'diplome_formation',
          headerName: 'Diplômes ',
          width: 400,
          renderCell: (params) => (
            <Tooltip title={params.value}>
              <Typography>
                {params.value}
              </Typography>
            </Tooltip>
          ),
        },
        {
          field: 'competence',
          headerName: 'Domaines de compétence',
          width: 300,
          renderCell: (params) => (
            <Tooltip title={params.value}>
              <Typography>
                {params.value}
              </Typography>
            </Tooltip>
          ),
        },
        {
          field: 'sous_domaine_competence',
          headerName: 'Sous domaine de compétence',
          width: 300,
          renderCell: (params) => (
            <Tooltip title={params.value}>
              <Typography>
                {params.value}
              </Typography>
            </Tooltip>
          ),
        },
        {
          field: 'dispose_un_cv',
          headerName: 'Avoir CV',
          width: 150,
        },
        {
            field: 'formation_fid',
            headerName: 'Formations',
            width: 300,
            renderCell: (params) => (
              <Tooltip title={params.value}>
                <Typography>
                  {params.value}
                </Typography>
              </Tooltip>
            ),
          },
      ];

      const handleExportToExcel = (stat) => {
        let titre = 'Banque de CV ' + filtre.formation + ' ' + filtre.diplome + ' ' + filtre.competence;
        if (stat > 0) {
          if (!((filtre.direction_id === 0 || filtre.direction_id === ''))) {
            titre += +' ' + filtre.direction_id.name;
            if (!(filtre.service_id === 0 || filtre.service_id === ''))
              titre += +' ' + filtre.service_id.name;
          }
          exportToExcel(rows, columns,titre);
        }
      }
  useEffect(() => {
    handleExportToExcel(excelExport);
  }, [excelExport]);

  useEffect(() => {
    PlanningMissionService.getDirection().then((result) => {
      if (result && result.data) {
        setDirection(result.data);
      }
    });

    BanqueDeCvService.showDomaineCompetence().then(res => {
      if (res) {
        setDomaineCompetence(res.data);
      }
    });
  }, []);

  useEffect(() => {
    refreshTable();
  }, [filtre.refresh]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleChangeDirection(event, value) {
    try {
      PlanningMissionService.getService(value.direction_id).then((result) => {
        if (result && result.data) {
          setService(result.data);
          setFiltre({ ...filtre, direction_id: value });
        }
      });
    } catch (err) {
      setService([]);
      setFiltre({ ...filtre, direction_id: 0 });
    }
  }

  function handleChangeDomaineDeCompetence(event, value) {
    try {
      BanqueDeCvService.ShowSousdomaine(value.id_domaine).then((result) => {
        if (result && result.data) {
          setSousDomaineCompetence(result.data);
          setFiltre({ ...filtre, domaine_comp: value });
        }
      });
    } catch (err) {
      setSousDomaineCompetence([]);
      setFiltre({ ...filtre, domaine_comp: "" });
    }
  }

  function handleChangeService(event, value) {
    let srv_id = value !== null ? value : 0;
    setFiltre({ ...filtre, service_id: srv_id });
  }

  function handleChangeDiplome(event, value) {
    let dpl = value !== null ? value : "";
    setFiltre({ ...filtre, diplome: dpl });
  }

  function handleChangeSousDomaine(event, value) {
    let sd = value !== null ? value : "";
    setFiltre({ ...filtre, sous_domaine: sd });
  }

  function handleObjetCandatureChange(event, value) {
    let sd = value !== null ? value : "";
    setFiltre({ ...filtre, objet_candidature: sd });
  }

  function handleChangeFormation(event, value) {
    let srv_id = value !== null ? value : 0;
    setFiltre({ ...filtre, formation: srv_id });
  }

  const handleActionRefresh = () => {
    setFiltre({ ...filtre, refresh: filtre.refresh + 1 });
  };

  const handlefiltreActionUpdate = (event) => {
    const { name, value } = event.target;
    setFiltre({ ...filtre, [name]: value });
  };

  const handleExportToexcelEvent = () => {
    declenceExportToExcel(excelExport + 1);
  }

  const classes = useStyles();

  return (
    <MainContainer {...headerDataRH}>
        <Spacer y={2} />
      {/* <h1 style={{ textAlign: 'center', color: '#4682B4' }}>Détails CV interne</h1> */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper} variant="outlined">
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20, marginLeft:20 ,marginTop:20}}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Tooltip title={"Actualiser les données"}>
                  <Button
                    size='small'
                    variant='contained'
                    color='primary'
                    onClick={handleActionRefresh}
                  >
                    <RefreshOutlinedIcon />
                    <Typography> Filtrer</Typography>
                  </Button>
                </Tooltip>
                <Tooltip title={"Exporter les données en fichier Excel"}>
                  <Button
                    size='small'
                    variant='contained'
                    style={{ marginLeft: 10 }}
                    color='primary'
                    onClick={handleExportToexcelEvent}
                  >
                    <GetAppOutlinedIcon />
                    <Typography> Exporter</Typography>
                  </Button>
                </Tooltip>
              </div>
            </div>
            <Grid container spacing={3} >
              <Grid item xs={4} style={{ display: (candidatIntern) ? "" : "none" }}>
                <Autocomplete
                  onChange={handleChangeDirection}
                  options={direction} getOptionLabel={(option) => option.name + ' (' + option.code + ')'}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Direction"
                      name='direction'
                      size="small"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: "search" }}
                      onChange={handlefiltreActionUpdate}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} style={{ display: (candidatIntern) ? "" : "none" }}>
                <Autocomplete
                  onChange={handleChangeService}
                  options={service} getOptionLabel={(option) => option.name + ' (' + option.code + ')'}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Service"
                      size="small"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: "search" }}
                      onChange={handlefiltreActionUpdate}
                      name='service'
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  onChange={handleChangeFormation}
                  options={extraData.formation} getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='formation'
                      fullWidth
                      label="Formation"
                      size="small"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: "search" }}
                      onChange={handlefiltreActionUpdate}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  onChange={handleChangeDiplome}
                  options={extraData.diplome} getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='diplome'
                      fullWidth
                      label="Diplôme"
                      size="small"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: "search" }}
                      onChange={handlefiltreActionUpdate}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  onChange={handleChangeDomaineDeCompetence}
                  options={domaine_competence} getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Domaine de competence"
                      size="small"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: "search" }}
                      onChange={handlefiltreActionUpdate}
                      name='domaine_comp'
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Autocomplete
                  onChange={handleChangeSousDomaine}
                  options={sous_domaine_competence || null} getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Sous domaine de competence"
                      size="small"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: "search" }}
                      onChange={handlefiltreActionUpdate}
                      name='sous_domaine'
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} style={{ display: (!candidatIntern) ? "" : "none" }}>
                <Autocomplete
                  onChange={handleObjetCandatureChange}
                  options={active_offre_emplois || null} getOptionLabel={(option) => option.libelle_type}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Objet candidature"
                      size="small"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: "search" }}
                      onChange={handlefiltreActionUpdate}
                      name='objet_candidature'
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} style={{ display: (!candidatIntern) ? "" : "none" }}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  size="small"
                  label="Mot clé de description"
                  variant="outlined"
                  name="mot_cle"
                  onChange={handlefiltreActionUpdate}
                />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Spacer y={2} />
      {openLoadingSimpleBackdrop ? (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      ) : (
        <div style={{ height: '100%', width: '100%' }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="mission table">
              <TableHead>
                <TableRow>
                  <TableCell>Matricule</TableCell>
                  <TableCell>Nom et prénom(s)</TableCell>
                  <TableCell>Fonction</TableCell>
                  <TableCell>Direction</TableCell>
                  <TableCell>Service</TableCell>
                  <TableCell>Diplôme</TableCell>
                  <TableCell>Domaine de compétence</TableCell>
                  <TableCell>Sous-Domaine de compétence</TableCell>
                  <TableCell>Formation</TableCell>
                  <TableCell>Formation dispensée par</TableCell>
                
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((ts, index) => (
                  <TableRow key={ts.id}>
                    <TableCell>{ts.matricule}</TableCell>
                    <TableCell>{ts.full_name}</TableCell>
                    <TableCell>{ts.poste}</TableCell>
                    <TableCell>{ts.direction_name}</TableCell>
                    <TableCell>{ts.service_name}</TableCell>
                    <TableCell>
                        {
                            ts.diplome_formation && ts.diplome_formation !== '' ? (
                            <ul>
                                {ts.diplome_formation.split(',').map((val, index) => (
                                <li key={index}>{val}</li>
                                ))}
                            </ul>
                            ) : (
                            <span></span> 
                            )
                        }
                        </TableCell>
                    <TableCell>
                       {
                            ts.competence && ts.competence !== '' ? (
                            <ul>
                                {ts.competence.split(',').map((val, index) => (
                                <li key={index}>{val}</li>
                                ))}
                            </ul>
                            ) : (
                            <span></span> 
                            )
                        }
                    </TableCell>
                    <TableCell>
                      {
                            ts.sous_domaine_competence && ts.sous_domaine_competence !== '' ? (
                            <ul>
                                {ts.sous_domaine_competence.split(',').map((val, index) => (
                                <li key={index}>{val}</li>
                                ))}
                            </ul>
                            ) : (
                            <span></span> 
                            )
                        }
                    </TableCell>
                    <TableCell>
                      {
                            ts.formation_fid && ts.formation_fid !== '' ? (
                            <ul>
                                {ts.formation_fid.split(',').map((val, index) => (
                                <li key={index}>{val}</li>
                                ))}
                            </ul>
                            ) : (
                            <span></span> 
                            )
                        }
                    </TableCell>
                    <TableCell>
                      {
                            ts.formation_lieu && ts.formation_lieu !== '' ? (
                            <ul>
                                {ts.formation_lieu.split(';').map((val, index) => (
                                <li key={index}>{val}</li>
                                ))}
                            </ul>
                            ) : (
                            <span></span> 
                            )
                        }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      )}
    </MainContainer>
  );
}
