export const headerPersonnalEvalutaion = {
  title: "Evaluation du personnels",
  submodules: [
    createData(0, "Evaluation", "/personnel_evaluation"),
    createData(1, "Mes subordonnées", "/mes_subordonnees"),
  ],
};

function createData(id, moduleName, ref) {
  return { id, moduleName, ref };
}
