export const headerGed = {
  title: "GED",
  submodules: [
    createData(0, "Rubrique", "/rubrique"),
    createData(1, "Document", "/document"),
    createData(2, "Emplacement", "/emplacement"),
  ],
};

function createData(id, moduleName, ref) {
  return { id, moduleName, ref };
}
