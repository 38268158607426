import React, { useState, useEffect, useContext } from "react";
import { DataGrid } from "@material-ui/data-grid";
import RubriquesService from "../../../services/RubriquesService";
import { useNavigate } from "react-router-dom";
import Message from "../../personnel/Message";
import Result from "../../personnel/Result";
import SettingsApplicationsSharpIcon from "@mui/icons-material/SettingsApplicationsSharp";
import SettingsIcon from "@mui/icons-material/Settings";
import PrivilegeDialog from "../../tools/PrivilegeDialog";
import PrivilegeButton from "../../tools/PrivilegeButton";
import { hasPrivilege } from "../../../utils/privilege";
import { userDetailContext } from "../../../App";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import { exportToExcel } from "../../../helpers/ExportHelpers";
import { Button } from "@mui/material";

export default function RubriqueTable({ excelExport, filtre }) {
  const navigate = useNavigate();

  const [rows, setRows] = React.useState([]);

  const contextData = useContext(userDetailContext);

  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =
    useState(false);

  const [isid, setIsid] = useState(0);

  const [etat, setEtat] = useState({
    open: false,
    message: "message",
    accept: false,
  });

  const [etats, setEtats] = useState({
    open: false,
    message: "message",
    color: "",
  });

  const [selectedId, setSelectedId] = useState(0);

  const [privilegeDialog, setPrivilegeDialog] = useState({
    open: false,
  });

  const [rubrique, setRubrique] = useState([]);

  const [rubriqueIdToSetPrivilege, setRubriqueIdToSetPrivilege] = useState([]);

  const handleCloseRubriqurDialog = () => {
    setPrivilegeDialog({ ...privilegeDialog, open: false });
  };

  const columns = [
    {
      field: "code_couleur",
      headerName: "Code couleur",
      width: 180,
      renderCell: (params) => {
        return (
          <>
            {rubrique.map((item) => {
              if (item.id == params.id) {
                return (
                  <div
                    style={{
                      background: item.code_couleur,
                      width: "60px",
                      height: "20px",
                      marginLeft: "50px",
                    }}
                  ></div>
                );
              }
            })}
          </>
        );
      },
    },

    {
      field: "codification",
      headerName: "Codification",
      width: 200,
    },
    {
      field: "nom",
      headerName: "Nom de la rubrique",
      width: 350,
    },

    {
      field: "description",
      headerName: "Description",
      width: 350,
    },

    {
      field: "rubrique_mere",
      headerName: "Rubrique mère",
      width: 350,
    },

    {
      field: "niveau",
      headerName: "Niveau",
      width: 150,
    },

    {
      field: "action",
      headerName: "Action",
      disableClickEventBulbing: true,
      width: 200,

      renderCell: (params) => {
        return (
          <>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "20% 70%",
              }}
            >
              {/* {console.log(
                "alalalalaalalalala: ",
                rubriqueIdToSetPrivilege
              )} */}

              {hasPrivilege(
                contextData.privileges,
                "Button_setting_privilege"
              ) === true ? (
                <Button
                  onClick={(e) => handlePrivilegeDialog(e, params.id)}
                  title="Configurer les privilèges"
                >
                  <SettingsIcon style={{ color: "grey" }} />
                </Button>
              ) : (
                rubriqueIdToSetPrivilege.map((id) => {
                  if (params.id == id) {
                    return (
                      <Button
                        onClick={(e) => handlePrivilegeDialog(e, params.id)}
                        title="Configurer les privilèges"
                      >
                        <SettingsIcon style={{ color: "grey" }} />
                      </Button>
                    );
                  } else return null;
                })
              )}

              <PrivilegeButton
                rubriqueId={params.id}
                userId={localStorage.getItem("USER")}
                edit={(e) => handleEdit(e, params.id)}
                delete={(e) => handleDelete(e, params.id)}
                moduleType={"rubrique"}
              />
            </div>
          </>
        );
      },
    },
  ];

  function handleMessage() {
    setEtat({
      open: false,
      message: "",
    });
  }

  const handleCloseMessage = () => {
    setEtats({ ...etats, open: false });
  };

  function handleEdit(e, id) {
    navigate("/rubrique/add/" + id);
  }

  function handleDelete(e, id) {
    setEtat({
      open: true,
      message: "Voulez vous vraiment supprimer  ?",
    });
    setIsid(id);
  }

  function handleAcceptDeleting() {
    setEtat({
      open: false,
      message: "",
      accept: true,
    });

    RubriquesService.deleteRubrique(isid).then((results) => {
      if (results.status) {
        const userId = localStorage.getItem("USER");
        const deletedRubriqueId = results.deletedRubrique["id_rubrique"];
        const deletedRubriqueName = results.deletedRubrique["titre_rubrique"];

        RubriquesService.saveHistory(
          userId,
          deletedRubriqueId,
          deletedRubriqueName,
          "Suppression de la rubrique"
        ).then((historyResponse) => {
          if (historyResponse.status) {
            console.log("Historique de suppression enregistrée");
          } else {
            console.log("Historique de suppression non enregistrée");
          }
        });

        setEtats({
          ...etats,
          open: true,
          color: "green",
          message: results.message,
        });

        RubriquesService.getRubrique().then((result) => {
          if (result && result.data) {
            setRows(result.data.map((row) => ({ ...row, id: row.id })));
          } else {
            setRows([]);
          }
        });
      } else {
        setEtats({
          ...etats,
          open: true,
          color: "red",
          message: results.message,
        });
      }
    });
  }

  function handleClosePrivilegeDialog() {
    setPrivilegeDialog({ ...privilegeDialog, open: false });
  }

  function handlePrivilegeDialog(e, id) {
    setPrivilegeDialog({
      ...privilegeDialog,
      open: true,
    });
    setSelectedId(id);
  }

  const handleFetchRubriqueList = () => {
    setOpenLoadingSimpleBackdrop(true);
    const userId = localStorage.getItem("USER");
    const filteredRows = [];
    RubriquesService.getRubrique(filtre)
      .then((result) => {
        if (result && result.data && result.status) {
          setRubrique(result.data);
          const rubriqueIdList = result.rubriqueIdList;
          rubriqueIdList.map((id) =>
            RubriquesService.getAction(id, userId).then((response) => {
              if (response.status) {
                const action = response.data.action;
                //Vérifier si l'utilisateur connecté a le droit de configurer le privilège d'un document
                if (action[8] === "1") {
                  setRubriqueIdToSetPrivilege((oldArray) => [...oldArray, id]);
                }
                //filtrer les rubriques que l'utilisateur connecté peut voir
                const matchingRows = result.data.filter(
                  (row) => row.id === id && action[2] === "1"
                );
                filteredRows.push(...matchingRows);
                setRows(filteredRows.map((row) => ({ ...row, id: row.id })));
              }
            })
          );

          setOpenLoadingSimpleBackdrop(false);
        } else {
          setRubrique([]);
          setRows([]);
          setOpenLoadingSimpleBackdrop(false);
        }
      })
      .finally(() => {
        setOpenLoadingSimpleBackdrop(false);
      });
  };

  useEffect(() => {
    handleFetchRubriqueList();
  }, []);

  useEffect(() => {
    if (filtre.refresh ) {
      handleFetchRubriqueList();
    }
    if (excelExport) {
      exportToExcel(rows, columns, "Liste des rubriques " + new Date());
    }
  }, [filtre.refresh, excelExport]);

  return (
    <React.Fragment>
      <Result
        message={etats.message}
        color={etats.color}
        open={etats.open}
        handleClose={handleCloseMessage}
      />
      <Message
        isActive={etat.open}
        message={etat.message}
        accept={etat.accept}
        handleClose={handleMessage}
        handleConfirm={handleAcceptDeleting}
      />
      <LoadingSimpleBackdrop
        openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
      />

      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />

      <PrivilegeDialog
        isActive={privilegeDialog.open}
        handleClose={handleClosePrivilegeDialog}
        selectedId={selectedId}
        typePrivilege={"rubrique"}
      />
    </React.Fragment>
  );
}
