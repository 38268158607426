import React, { useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import ValidateRequest from "../../tools/ValidateImmobilisationStepRequest";
import InventoryService from "../../../services/InventoryService";
import Title from "../../../components/Title";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ArticleIcon from "@mui/icons-material/Article";
import { style } from "@mui/system";
import { id } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import Message from "../../tools/Message";
import Spacer from "../../../components/Spacer";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
  style1: {
    backfaceVisibility: "cyan",
  },
}));
export default function ImmobilisationMineTable() {
  const [rows, setRows] = useState([]);
  const [immoArray, setImmoArray] = useState([]);
  const classes = useStyles();
  const navigate = useNavigate();

  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =React.useState(false);
  const [message, setMessage] = useState({
      open: false,
      color: 'green',
      messageInfo: '',
  });
  const { open, color, messageInfo } = message;
  const [openValidateRequest, setOpenValidateRequest] = useState(false);
  const [immoHistoryId, setImmoHistoryId] = useState([]);
  const [state, setState] = useState(false);
  const userConnectedId = localStorage.getItem("USER");

  console.log("-----User connected----", userConnectedId);
  function initalizeStorage(name){
    if(localStorage.getItem(name)){
      localStorage.setItem(name,localStorage.getItem(name));
      console.log("ambony");
     }
    else{
      localStorage.setItem(name, "");
      console.log("ambany");
    }
  }
  function loadTable(name_materiel,code_immo,state,logistics_manager,status1,status2) {
    setOpenLoadingSimpleBackdrop(true);
    InventoryService.getMinePendingImmoHistory(name_materiel,code_immo,state,logistics_manager,status1,status2).then((result) => {
      if (result && result.status) {
        setRows(
          result.data.map((row) => ({
            ...row,
            id: row.history_id,
            id2: row.code_immo_id,
            code: row.code_immo,
          }))
        );
        setOpenLoadingSimpleBackdrop(false);
      } else {
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }

  useEffect(() => {
    initalizeStorage('name_materiel');
      initalizeStorage('code_immo');
      initalizeStorage('state');
      initalizeStorage('logistics_manager');
      initalizeStorage('status1');
      initalizeStorage('status2');

    const name_materiel = localStorage.getItem('name_materiel');
    const code_immo = localStorage.getItem('code_immo');
    const state = localStorage.getItem('state');
    const logistics_manager = localStorage.getItem('logistics_manager');
    const status1 = localStorage.getItem('status1');
    const status2 = localStorage.getItem('status2');
    loadTable(name_materiel,code_immo,state,logistics_manager,status1,status2);
  }, []);

  const handleValidated = (id) => (ev) => {
      immoHistoryId.push(id);
      setImmoHistoryId(immoHistoryId => [...immoHistoryId]);
      localStorage.setItem("immoImmoHistoryIdStorage", immoHistoryId);
      localStorage.setItem("immoStatusStorage", "CONFIRMED");
      localStorage.setItem("validationByHolderStorage", true);
      console.log(id,'/',localStorage.getItem("immoStatusStorage"));

      setOpenValidateRequest(true);
  };

  const handleConfirmed = (id) => (ev) => {
      immoHistoryId.push(id);
      setImmoHistoryId(immoHistoryId => [...immoHistoryId]);
      localStorage.setItem("immoImmoHistoryIdStorage", immoHistoryId);
      localStorage.setItem("immoStatusStorage", "CONFIRMED");
      localStorage.setItem("confirmationByOldHolderStorage", true);
      console.log(id,'/',localStorage.getItem("immoStatusStorage"));

      setOpenValidateRequest(true);
  };

  const handleRefused = (id) => (ev) => {
    immoHistoryId.push(id);
    setImmoHistoryId(immoHistoryId => [...immoHistoryId]);
    localStorage.setItem("immoImmoHistoryIdStorage", immoHistoryId);
    localStorage.setItem("immoStatusStorage", "DECLINED");
    localStorage.setItem("validationByHolderStorage", true);
    console.log(id,'/',localStorage.getItem("immoStatusStorage"));

    setOpenValidateRequest(true);
  };

  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }

  const handleCloseValidateRequest = (event) => {
    setOpenValidateRequest(false);
  }

  const columns = [
    {
      field: "code_immo",
      headerName: "Code Immo",
      width: 170,
    },
    {
      field: "name",
      headerName: "Matériel",
      width: 250,
    },
    {
      field: "state",
      headerName: "Etat matériel",
      width: 170,
      renderCell: (params) => {
        if (params.row.state == "TRES BON ETAT") {
          return (
            <div
              style={{
                backgroundColor: "rgb(52, 168, 168, 0.747)",
                width: 250,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              {params.row.state}
            </div>
          );
        } else if (params.row.state == "BON ETAT") {
          return (
            <div
              style={{
                backgroundColor: "rgb(58, 139, 58)",
                width: 250,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              {params.row.state}
            </div>
          );
        } else if (params.row.state == "MOYEN ETAT") {
          return (
            <div
              style={{
                background: "rgb(167, 167, 55)",
                width: 250,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              {params.row.state}
            </div>
          );
        } else if (params.row.state == "MAUVAIS ETAT") {
          return (
            <div
              style={{
                background: "rgb(167, 54, 54)",
                width: 250,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              {params.row.state}
            </div>
          );
        }
      },
    },
    {
      field: "date_inventaire",
      headerName: "Date inventaire",
      width: 170,
    },
    {
      field: "observation",
      headerName: "Observation",
      width: 250,
    },
    {
      field: "logistics_manager",
      headerName: "Resp logistique",
      width: 250,
    },
    {
      field: "nom",
      headerName: "Nouveau détenteur",
      width: 250,
    },
    {
      field: "status_by_the_logistics_manager",
      headerName: "Validation Resp Logistique",
      width: 250,
      renderCell: (params) => {
        if (params.row.status_by_the_logistics_manager == "CONFIRMED") {
          return (
            <div
              style={{
                backgroundColor: "rgb(58, 139, 58)",
                width: 250,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              Validé
            </div>
          );
        }
        if (params.row.status_by_the_logistics_manager == "DECLINED") {
          return (
            <div
              style={{
                backgroundColor: "rgb(58, 139, 58)",
                width: 250,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              Non Validé
            </div>
          );
        }
        if (params.row.status_by_the_logistics_manager == "PENDING") {
          return (
            <div
              style={{
                background: "rgb(167, 167, 55)",
                width: 250,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              En attente de validation
            </div>
          );
        }
      },
    },
    {
      field: "status_by_the_holder",
      headerName: "Statut détenteur",
      width: 170,
      renderCell: (params) => {
        if (params.row.status_by_the_holder == "CONFIRMED") {
          return (
            <div
              style={{
                backgroundColor: "rgb(58, 139, 58)",
                width: 250,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              Validé
            </div>
          );
        }
        if (params.row.status_by_the_holder == "PENDING") {
          return (
            <div
              style={{
                background: "rgb(167, 167, 55)",
                width: 250,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              En attente de validation
            </div>
          );
        }
        if (params.row.status_by_the_holder == "DECLINED") {
          return (
            <div
              style={{
                background: "rgb(167, 54, 54)",
                width: 250,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              Non validé
            </div>
          );
        }
      },
    },
    {
      field: "status_by_the_old_holder",
      headerName: "Statut ancien detenteur",
      width: 170,
      renderCell: (params) => {
        if (params.row.status_by_the_old_holder == "CONFIRMED") {
          return (
            <div
              style={{
                backgroundColor: "rgb(58, 139, 58)",
                width: 250,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              Validé
            </div>
          );
        }
        if (params.row.status_by_the_old_holder == "PENDING") {
          return (
            <div
              style={{
                background: "rgb(167, 167, 55)",
                width: 250,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              En attente de validation
            </div>
          );
        }
        if (params.row.status_by_the_old_holder == "DECLINED") {
          return (
            <div
              style={{
                background: "rgb(167, 54, 54)",
                width: 250,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              Non validé
            </div>
          );
        }
      },
    },

    {
      field: "action",
      headerName: "Action",
      disableClickEventBulbing: true,
      width: 300,
      renderCell: (params) => {
        if (params.row.new_affectation_user_id == userConnectedId) {
          return (
            <div>
              <Button variant="contained"  onClick={handleValidated(params.row.history_id)} title="Accepter l'acquisition" style={{ fontWeight: 600, backgroundColor:"green", color:"#FFFFFF" }}>
                Accepter
              </Button>
              <Button variant="contained" color="secondary" onClick={handleRefused(params.row.history_id)} title="Refuser l'acquisition" style={{ fontWeight: 600, marginLeft:20 }}>
                Refuser
              </Button>
            </div>
          );
        }
        if (params.row.new_affectation_user_id != userConnectedId) {
          return (
            <div>
              <Button variant="contained"  onClick={handleConfirmed(params.row.history_id)} title="Confirmer l'affectation" style={{ fontWeight: 600, backgroundColor:"green", color:"#FFFFFF" }}>
                Confirmer
              </Button>
              <Button variant="contained" color="secondary" onClick={handleRefused(params.row.history_id)} title="Refuser l'acquisition" style={{ fontWeight: 600, marginLeft:20 }}>
                Refuser
              </Button>
            </div>
          );
        }
      },
      // width: 1200,
    },
  ];
  return (
    <>
      <LoadingSimpleBackdrop
        openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
      />

      <Title>Liste de(s) matériel(s) à valider ou à confirmer</Title>
      <div style={{ height: "70vh", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
        />
      </div>

      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
    
      <ValidateRequest
          openValidateRequest={openValidateRequest}
          handleCloseValidateRequest={handleCloseValidateRequest} />
    </>
  );
}
