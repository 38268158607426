// import logo from './logo.svg';

import { Routes, Route } from "react-router-dom";
import Portal from "../screens/portal/Portal";
import SignIn from "../screens/authentication/SignIn";
import CardInPortal from "../screens/portal/CardPortal";
import ResetPassword from "../screens/authentication/ResetPassword";
// import Conge from '../screens/electronic-validation/conge';
import ElectronicValidation from "../screens/tools/ElectronicValidation";
import KeybordVirtuel from "../screens/tools/KeybordVirtuel";
import UserList from "../screens/users/UserList";
import UserAdd from "../screens/users/UserAdd";
import GroupList from "../screens/groups/GroupList";
import GroupAdd from "../screens/groups/GroupAdd";
import PrivilegeList from "../screens/privileges/PrivilegeList";
import PrivilegeAdd from "../screens/privileges/PrivilegeAdd";
import Dashboard from "../screens/dashboard/dashboard";
import LeaveList from "../screens/my-account/leave/LeaveList";
import LeaveAdd from "../screens/my-account/leave/LeaveAdd";
import ValidationList from "../screens/my-account/validation/ValidationList";
// import AcceptedList from "../screens/accepted-help/validation/AcceptedList";
import ProtectedRoute from "./ProtectedRoute";
import NewPassword from "../screens/authentication/NewPassword";
import DirectionList from "../screens/direction/DirectionLIst";
import DirectionAdd from "../screens/direction/DirectionAdd";
import Acces_directionList from "../screens/acces_direction/acces_directionList";
import Acces_directionAdd from "../screens/acces_direction/acces_directionAdd";
import AcceptedHelpList from "../screens/accepted-help/validation/AcceptedHelpList";
import Accepted from "../screens/accepted-help/validation/AcceptedHelpList";

import TimeTrackingProvisional from "../screens/hrConfiguration/TimeTrackingprovisional/TimeTrackingProvisional";
import TimeTrackingAdd from "../screens/hrConfiguration/TimeTrackingprovisional/TimeTrackingAdd";
import TimeTrackingValidation from "../screens/hrConfiguration/TimeTrackingprovisional/TimeTrackingValidation";

import DocumentAdd from "../screens/ged/document/DocumentAdd";

import RubriquePrincipaleAdd from "../screens/ged/RubriquePrincipale/RubriquePrincipaleAdd";
import RubriquePrincipaleList from "../screens/ged/RubriquePrincipale/RubriquePrincipaleList";

import RubriqueSecondaireList from "../screens/ged/RubriqueSecondaire/RubriqueSecondaireList";
import RubriqueSecondaireAdd from "../screens/ged/RubriqueSecondaire/RubriqueSecondaireAdd";

//test
import RubriqueAdd from "../screens/ged/Rubrique/RubriqueAdd";

import EmplacementAdd from "../screens/ged/Emplacement/EmplacementAdd";

import SeatList from "../screens/seat/SeatList";
import SeatAdd from "../screens/seat/SeatAdd";

import BuildingList from "../screens/building/BuildingList";
import BuildingAdd from "../screens/building/BuildingAdd";

import AntenneList from "../screens/antenne/AntenneList";
import AntenneAdd from "../screens/antenne/AntenneAdd";
import PersonnelList from "../screens/personnel/PersonnelList";
import PersonnelAdd from "../screens/personnel/PersonnelAdd";
import Update from "../screens/personnel/update";
import Annuaire from "../screens/personnel/annuaire";
import FonctionList from "../screens/fonction/fonctionList";
import FonctionAdd from "../screens/fonction/fonctionAdd";
import AppuiAdd from "../screens/mission/tdr/demmande";
import DemandeList from "../screens/mission/tdr/demandeList";

import ServiceList from "../screens/services/ServiceList";
import ServiceAdd from "../screens/services/ServiceAdd";
import FundingAdd from "../screens/funding/FundingAdd";
import FundingList from "../screens/funding/FundingList";

import PersonalSettingList from "../screens/personal-setting/PersonalSettingList";
import PersonalSettingAdd from "../screens/personal-setting/PersonalSettingAdd";
import PersonalSettingUpdate from "../screens/personal-setting/PersonalSettingUpdate";

import NumberValidationList from "../screens/number-validation/NumberValidationList";
import NumberValidationAdd from "../screens/number-validation/NumberValidationAdd";

import AnnualCalendarList from "../screens/annual-calendar/AnnualCalendarList";
import AnnualCalendarAdd from "../screens/annual-calendar/AnnualCalendarAdd";
import AddDay from "../screens/annual-calendar/Daynotcompted";

import LeaveTypeList from "../screens/leave-type/LeaveTypeList";
import LeaveTypeAdd from "../screens/leave-type/LeaveTypeAdd";
import PlanningTable from "../screens/planning/planningtable";
import ValidationPlanning from "../screens/planning/ValidationPlanning";

import FamilyMemberList from "../screens/family-member/FamilyMemberList";
import FamilyMemberAdd from "../screens/family-member/FamilyMemberAdd";

import LeavePdfRender from "../screens/my-account/leave/leavePdfRender";
import PdfRender from "../screens/mission/tdr/pdfRender";
import axios from "axios";
import ValidationSettingDate from "../screens/validation_setting/validationSettingDate";
import ValidationSettingList from "../screens/validation_setting/validationSettingList";
import TDRElaborationAdd from "../screens/mission/tdr-elaboration/TDRElaborationAdd";
import TDRElaborationList from "../screens/mission/tdr-elaboration/TDRElaborationList";
import TDRHistory from "../screens/mission/tdr-elaboration/TDRHistory";
import RenderFile from "../screens/my-account/validation/RenderFile";
import TDRView from "../screens/mission/tdr-elaboration/TDRView";
import AgendaMission from "../screens/mission/tdr-elaboration/AgendaMission";
import AgendaMissionView from "../screens/mission/tdr-elaboration/AgendaMissionView";
import AgendaTable from "../screens/mission/tdr-elaboration/AgendaTable";
import DisplayFile from "../screens/tools/DisplayFile";
import MyAccount from "../screens/my-account/AccountConfiguration/MyAccount";
import ImmobilisationList from "../screens/logistic/ImmobilisationList";
import ImmobilisationAdd from "../screens/logistic/ImmobilisationAdd";
import BalanceConfigurationList from "../screens/hrConfiguration/BalanceConfiguration/BalanceConfigurationList";
import BalanceConfigurationAdd from "../screens/hrConfiguration/BalanceConfiguration/BalanceConfigurationAdd";
import EquipmentList from "../screens/equipment/EquipmentList";
import EquipmentAdd from "../screens/equipment/EquipmentAdd";

import CustomStates from "../screens/custom-states/CustomStates";
import ActualBalance from "../screens/custom-states/ActualBalance";
import LeavePerWeek from "../screens/custom-states/LeavePerWeek";
import LeaveLastWeek from "../screens/custom-states/LeaveLastWeek";
import LeaveNextWeek from "../screens/custom-states/LeaveNextWeek";
// import LeaveRequestList from '../screens/my-account/leave-request/LeaveRequestList';
import EquipmentCategoryList from "../screens/equipment-category/EquipmentCategoryList";
import EquipmentCategoryAdd from "../screens/equipment-category/EquipmentCategoryAdd";
import EquipmentRankingList from "../screens/equipment-ranking/EquipmentRankingList";
import EquipmentRankingAdd from "../screens/equipment-ranking/EquipmentRankingAdd";
import InventoryList from "../screens/inventory/InventoryList";
import InventoryMineList from "../screens/inventory/InventoryMineList";
import ImmobilisationMineList from "../screens/my-account/immobilisation/ImmobilisationMineList";
import EquipmentConfigAdd from "../screens/equipment-config/EquipmentConfigAdd";
import EquipmentConfigList from "../screens/equipment-config/EquipmentConfigList";
import StockList from "../screens/stock-inventory/StockList";
import ImmoConfigAdd from "../screens/logistic/ImmoConfigAdd";
import ImmobilisationConfiguration from "../screens/logistic/ImmobilisationConfiguration";
import EquipmentConfigRankingList from "../screens/equipment-config/EquipmentConfigRankingList";
import InventoryDetails from "../screens/inventory/InventoryDetails";
import ImmobilisationDetails from "../screens/logistic/ImmobilisationDetails";
import CodeImmoAdd from "../screens/logistic/CodeImmoAdd";
import StatistiqueEtatList from "../screens/logistic/StatistiqueEtatList";
import EquipmentGenerateQrCode from "../screens/logistic/ImmobilisationGenerateQrCode";
import TimelineView from "../screens/planning/Timeline";

import UserMaternityLeaveList from "../screens/users/UserMaternityLeave/UserMaternityLeaveList";
import UserMaternityLeaveAdd from "../screens/users/UserMaternityLeave/UserMaternityLeaveAdd";

import MettingRequestList from "../screens/metting/MettingRequestList";
import CalendarMettingList from "../screens/metting/CalendarMettingList";

import LeaveBalanceSummary from "../screens/my-account/LeaveBalanceSummary/LeaveBalanceSummaryList";
import ImmobilisationFilterList from "../screens/logistic/ImmobilisationFilterList";
import TransferedList from "../screens/inventory/TransferedList";
import TimeTracking from "../screens/hrConfiguration/TimeTracking/TimeTrackingList";
import CandidatureList from "../screens/candidature/CandidatureList";
import CandidatureAdd from "../screens/candidature/CandidatureAdd";
import CandidatureAffichage from "../screens/candidature/CandidatureAffichage";
import Cvtable from "../screens/Mise_a_jour_CV/Cvtable";

import MeetingRequestAdd from "../screens/metting/MeetingRequestAdd";
import MeetingFinishList from "../screens/metting/MeetingFinishList";
import MailConfigAdd from "../screens/mail/MailConfigAdd";
import MailListPerson from "../screens/mail/MailListPerson";
import MailBox from "../screens/mail/MailBox";
import MailHistory from "../screens/mail/MailHistory";
import MailHistoryReceive from "../screens/mail/MailHistoryReceive";

import CategorieList from "../screens/mission/parametrage/CategorieList";
import CategorieAdd from "../screens/mission/parametrage/CategorieAdd";
import ObservationAppuiList from "../screens/mission/parametrage/ObservationAppuiList";
import ObservationAppuiAdd from "../screens/mission/parametrage/ObservationAppuiAdd";
import NombrePerDirAdd from "../screens/mission/parametrage/NombrePerDirAdd";
import NombrePerDirList from "../screens/mission/parametrage/NombrePerDirList";
import StatistiquePlanning from "../screens/mission/graph/StatistiquePlanning";
import PlanningListv from "../screens/mission/validationplanning/PlanningListv";
import MissionEtat from "../screens/mission/Etat/MissionEtat";
import ValidationSettingLists from "../screens/mission/parametrage/ValidationSettingLists";
import ValidationSettingAdd from "../screens/mission/parametrage/ValidationSettingAdd";
import PlanningEtat from "../screens/mission/Etat/PlanningEtat";
import PlanningMissionList from "../screens/mission/planning-mission/PlanningMissionList";
import PlanningMissionAdd from "../screens/mission/planning-mission/PlanningMissionAdd";
import PlanningMissionDetail from "../screens/mission/planning-mission/PlanningMissionDetail";
import ReponseList from "../screens/personal_evaluation/response/ReponseList";
import EvaluationSaisonList from "../screens/personal_evaluation/evaluation_saison/EvaluationSaisonList";
import MesSubordonneesList from "../screens/personal_evaluation/response/MesSubordonneesList";
import EvaluationFunctionList from "../screens/personal_evaluation/evaluation_fonctions/EvaluationFunctionList";
import CritereList from "../screens/personal_evaluation/evaluation_criteria/CritereList";
import CategoryCritereList from "../screens/personal_evaluation/category_criteria/CategoryCritereList";
import QuestionnaireList from "../screens/personal_evaluation/questionnaire/QuestionnaireList";
import PerformanceList from "../screens/personal_evaluation/performance/PerformanceList";
import CopieMailAdd from "../screens/mission/parametrage/CopieMailAdd";
import CopieMailList from "../screens/mission/parametrage/CopieMailList";
import LeaveAnnual from "../screens/my-account/leave/LeaveAnnual";
import FicheEvaluationList from "../screens/personal_evaluation/fiche_evaluation/FicheEvaluationList";
import Resultats from "../screens/personal_evaluation/response/Resultats";
import CVDetail from "../screens/Mise_a_jour_CV/CVDetail";
import DocumentListe from "../screens/ged/document/DocumentListe";
import RubriqueListe from "../screens/ged/Rubrique/RubriqueListe";
import EmplacementListe from "../screens/ged/Emplacement/EmplacementListe";
import ValidatorList from "../screens/circuit_validations/mes_validateurs/ValidatorList";
import DemandeValidationList, {
  DEMANDE_VALIDAION_TYPE,
} from "../screens/circuit_validations/demandes/DemandeList";

function IndexesRoutes() {
  axios.interceptors.response.use(
    function (response) {
      // Do something before request is sent
      return response;
    },
    function (error) {
      // Do something with request error
      if (error.message.includes("410")) {
        window.location.reload();
      }
      return Promise.reject(error);
    }
  );

  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      {/* <Route exact path="/" component={SignIn} /> */}

      <Route path="/new-password/:token" element={<NewPassword />} />
      {/* <Route exact path="/new-password/:token" component={NewPassword} /> */}

      <Route path="/reset-password" element={<ResetPassword />} />
      {/* <Route exact path="/reset-password" component={ResetPassword} /> */}

      <Route path="/home" element={<ProtectedRoute />}>
        <Route path="/home" element={<Portal />} />
      </Route>
      {/* <ProtectedRoute path="/home" component={Portal} /> */}

      <Route path="/PortalModule/CardPortal" element={<ProtectedRoute />}>
        <Route path="/PortalModule/CardPortal" element={<CardInPortal />} />
      </Route>
      {/* <ProtectedRoute path="/PortalModule/CardPortal" component={CardInPortal} /> */}
      {/* <ProtectedRoute path="/rh/conge" component={Conge} /> */}
      {/* <ProtectedRoute path="/validation/:id" component={Conge} /> */}

      <Route path="/validation/:id" element={<ProtectedRoute />}>
        <Route path="/validation/:id" element={<ElectronicValidation />} />
      </Route>
      {/* <ProtectedRoute path="/validation/:id" component={ElectronicValidation} /> */}

      <Route path="/detail_cv" element={<ProtectedRoute />}>
        <Route path="/detail_cv" element={<CVDetail />} />
      </Route>

      <Route path="/rh/KeybordVirtuel" element={<ProtectedRoute />}>
        <Route path="/rh/KeybordVirtuel" element={<KeybordVirtuel />} />
      </Route>
      {/* <ProtectedRoute path="/rh/KeybordVirtuel" component={KeybordVirtuel} /> */}

      <Route path="/users/add/:id" element={<ProtectedRoute />}>
        <Route path="/users/add/:id" element={<UserAdd />} />
      </Route>

      <Route path="/annual_leave" element={<ProtectedRoute />}>
        <Route path="/annual_leave" element={<LeaveAnnual />} />
      </Route>

      {/* <ProtectedRoute path="/users/add/:id">
        <UserAdd />
      </ProtectedRoute> */}

      <Route path="/users/add" element={<ProtectedRoute />}>
        <Route path="/users/add" element={<UserAdd />} />
      </Route>
      {/* <ProtectedRoute path="/users/add">
        <UserAdd />
      </ProtectedRoute> */}

      <Route path="/users" element={<ProtectedRoute />}>
        <Route path="/users" element={<UserList />} />
      </Route>
      {/* <ProtectedRoute path="/users">
        <UserList />
      </ProtectedRoute> */}

      <Route path="/groups/add/:id" element={<ProtectedRoute />}>
        <Route path="/groups/add/:id" element={<GroupAdd />} />
      </Route>
      {/* <ProtectedRoute path="/groups/add/:id">
        <GroupAdd />
      </ProtectedRoute> */}

      <Route path="/groups/add" element={<ProtectedRoute />}>
        <Route path="/groups/add" element={<GroupAdd />} />
      </Route>
      {/* <ProtectedRoute path="/groups/add/">
        <GroupAdd />
      </ProtectedRoute> */}

      <Route path="/direction/add" element={<ProtectedRoute />}>
        <Route path="/direction/add" element={<DirectionAdd />} />
      </Route>
      {/* <ProtectedRoute path="/direction/add/">
        <DirectionAdd />
      </ProtectedRoute> */}

      <Route path="/direction" element={<ProtectedRoute />}>
        <Route path="/direction" element={<DirectionList />} />
      </Route>
      {/* <ProtectedRoute path="/direction">
        <DirectionList />
      </ProtectedRoute> */}

      <Route path="/antenne/add/" element={<ProtectedRoute />}>
        <Route path="/antenne/add/" element={<AntenneAdd />} />
      </Route>
      {/* <ProtectedRoute path="/antenne/add/">
        <AntenneAdd />
      </ProtectedRoute> */}

      <Route path="/antenne" element={<ProtectedRoute />}>
        <Route path="/antenne" element={<AntenneList />} />
      </Route>
      {/* <ProtectedRoute path="/antenne">
        <AntenneList />
      </ProtectedRoute> */}

      <Route path="/funding/add/:id" element={<ProtectedRoute />}>
        <Route path="/funding/add/:id" element={<FundingAdd />} />
      </Route>
      {/* <ProtectedRoute path="/funding/add/:id">
        <FundingAdd />
      </ProtectedRoute> */}

      <Route path="/funding/add" element={<ProtectedRoute />}>
        <Route path="/funding/add" element={<FundingAdd />} />
      </Route>
      {/* <ProtectedRoute path="/funding/add/">
        <FundingAdd />
      </ProtectedRoute> */}

      <Route path="/funding" element={<ProtectedRoute />}>
        <Route path="/funding" element={<FundingList />} />
      </Route>
      <Route path="/timeTrackingprovisional" element={<ProtectedRoute />}>
        <Route
          path="/timeTrackingprovisional"
          element={<TimeTrackingProvisional />}
        />
      </Route>
      <Route path="/timeTrackingadd/:id" element={<ProtectedRoute />}>
        <Route path="/timeTrackingadd/:id" element={<TimeTrackingAdd />} />
      </Route>
      <Route path="/timeTrackingadd" element={<ProtectedRoute />}>
        <Route path="/timeTrackingadd" element={<TimeTrackingAdd />} />
      </Route>

      <Route path="/me/tracking" element={<ProtectedRoute />}>
        <Route path="/me/tracking" element={<TimeTrackingValidation />} />
      </Route>

      <Route path="mission_mail/add/:id" element={<ProtectedRoute />}>
        <Route path="/mission_mail/add/:id" element={<CopieMailAdd />} />
      </Route>
      <Route path="mission_mail/add" element={<ProtectedRoute />}>
        <Route path="/mission_mail/add" element={<CopieMailAdd />} />
      </Route>
      <Route path="mission_mail" element={<ProtectedRoute />}>
        <Route path="/mission_mail" element={<CopieMailList />} />
      </Route>
      {/* <ProtectedRoute path="/funding">
        <FundingList />
      </ProtectedRoute> */}

      <Route path="/services/add/:id" element={<ProtectedRoute />}>
        <Route path="/services/add/:id" element={<ServiceAdd />} />
      </Route>
      {/* <ProtectedRoute path="/services/add/:id">
        <ServiceAdd />
      </ProtectedRoute> */}

      <Route path="/services/add" element={<ProtectedRoute />}>
        <Route path="/services/add" element={<ServiceAdd />} />
      </Route>
      {/* <ProtectedRoute path="/services/add/">
        <ServiceAdd />
      </ProtectedRoute> */}

      <Route path="/services" element={<ProtectedRoute />}>
        <Route path="/services" element={<ServiceList />} />
      </Route>
      {/* <ProtectedRoute path="/services">
        <ServiceList />
      </ProtectedRoute> */}

      <Route path="/champ_dynamique/add/:id" element={<ProtectedRoute />}>
        <Route
          path="/champ_dynamique/add/:id"
          element={<PersonalSettingUpdate />}
        />
      </Route>

      <Route path="/add_candidature" element={<ProtectedRoute />}>
        <Route path="/add_candidature" element={<CandidatureAdd />} />
      </Route>

      <Route path="/list_candidature" element={<ProtectedRoute />}>
        <Route path="/list_candidature" element={<CandidatureAffichage />} />
      </Route>
      <Route path="/cv_intern" element={<ProtectedRoute />}>
        <Route path="/cv_intern" element={<Cvtable candidatIntern={true} />} />
      </Route>
      <Route path="/cv_extern" element={<ProtectedRoute />}>
        <Route path="/cv_extern" element={<Cvtable candidatIntern={false} />} />
      </Route>

      {/* EVALUTION DES PERSONNELS */}

      <Route path="/personnel_evaluation" element={<ProtectedRoute />}>
        <Route path="/personnel_evaluation" element={<ReponseList />} />
      </Route>
      <Route path="/parametrage" element={<ProtectedRoute />}>
        <Route path="/parametrage" element={<ReponseList />} />
      </Route>
      <Route path="/evaluation_saison" element={<ProtectedRoute />}>
        <Route path="/evaluation_saison" element={<EvaluationSaisonList />} />
      </Route>
      <Route path="/mes_subordonnees" element={<ProtectedRoute />}>
        <Route path="/mes_subordonnees" element={<MesSubordonneesList />} />
      </Route>
      <Route path="/critere_groupee" element={<ProtectedRoute />}>
        <Route path="/critere_groupee" element={<FicheEvaluationList />} />
      </Route>
      <Route path="/evaluation_fonctions_list" element={<ProtectedRoute />}>
        <Route
          path="/evaluation_fonctions_list"
          element={<EvaluationFunctionList />}
        />
      </Route>
      <Route path="/critere" element={<ProtectedRoute />}>
        <Route path="/critere" element={<CritereList />} />
      </Route>
      <Route path="/resultats" element={<ProtectedRoute />}>
        <Route path="/resultats" element={<Resultats />} />
      </Route>
      <Route path="/critere_categorie" element={<ProtectedRoute />}>
        <Route path="/critere_categorie" element={<CategoryCritereList />} />
      </Route>
      <Route path="/questionnaire" element={<ProtectedRoute />}>
        <Route path="/questionnaire" element={<QuestionnaireList />} />
      </Route>
      <Route path="/personnel_performance" element={<ProtectedRoute />}>
        <Route path="/personnel_performance" element={<PerformanceList />} />
      </Route>

      {/* EVALUTION DES PERSONNELS */}

      {/* <ProtectedRoute path="/champ_dynamique/add/:id">
        <PersonalSettingUpdate />
      </ProtectedRoute> */}

      <Route path="/champ_dynamique/add" element={<ProtectedRoute />}>
        <Route path="/champ_dynamique/add" element={<PersonalSettingAdd />} />
      </Route>
      {/* <ProtectedRoute path="/champ_dynamique/add/">
        <PersonalSettingAdd />
      </ProtectedRoute> */}

      <Route path="/champ_dynamique" element={<ProtectedRoute />}>
        <Route path="/champ_dynamique" element={<PersonalSettingList />} />
      </Route>
      {/* <ProtectedRoute path="/champ_dynamique">
        <PersonalSettingList />
      </ProtectedRoute> */}

      <Route path="/number_validation/add/:id" element={<ProtectedRoute />}>
        <Route
          path="/number_validation/add/:id"
          element={<NumberValidationAdd />}
        />
      </Route>
      {/* <ProtectedRoute path="/number_validation/add/:id">
        <NumberValidationAdd />
      </ProtectedRoute> */}

      <Route path="/number_validation/add" element={<ProtectedRoute />}>
        <Route
          path="/number_validation/add"
          element={<NumberValidationAdd />}
        />
      </Route>

      <Route path="/acces_direction/add" element={<ProtectedRoute />}>
        <Route path="/acces_direction/add" element={<Acces_directionAdd />} />
      </Route>

      <Route path="/acces_direction" element={<ProtectedRoute />}>
        <Route path="/acces_direction" element={<Acces_directionList />} />
      </Route>
      {/* <ProtectedRoute path="/number_validation/add/">
        <NumberValidationAdd />
      </ProtectedRoute> */}
      {/* kpkpk */}

      <Route path="/number_validation" element={<ProtectedRoute />}>
        <Route path="/number_validation" element={<NumberValidationList />} />
      </Route>
      {/* <ProtectedRoute path="/number_validation">
        <NumberValidationList />
      </ProtectedRoute> */}

      <Route path="/annual_calendar/month/:id/:id" element={<ProtectedRoute />}>
        <Route path="/annual_calendar/month/:id/:id" element={<AddDay />} />
      </Route>
      {/* <ProtectedRoute path="/annual_calendar/month/:id/:id">
        <AddDay />
      </ProtectedRoute> */}

      <Route path="/annual_calendar/add/:id" element={<ProtectedRoute />}>
        <Route
          path="/annual_calendar/add/:id"
          element={<AnnualCalendarAdd />}
        />
      </Route>
      {/* <ProtectedRoute path="/annual_calendar/add/:id">
        <AnnualCalendarAdd />
      </ProtectedRoute> */}

      <Route path="/annual_calendar/add" element={<ProtectedRoute />}>
        <Route path="/annual_calendar/add" element={<AnnualCalendarAdd />} />
      </Route>
      {/* <ProtectedRoute path="/annual_calendar/add/">
        <AnnualCalendarAdd />
      </ProtectedRoute> */}

      <Route path="/annual_calendar" element={<ProtectedRoute />}>
        <Route path="/annual_calendar" element={<AnnualCalendarList />} />
      </Route>
      {/* <ProtectedRoute path="/annual_calendar">
        <AnnualCalendarList />
      </ProtectedRoute> */}

      <Route path="/leave_type/add/:id" element={<ProtectedRoute />}>
        <Route path="/leave_type/add/:id" element={<LeaveTypeAdd />} />
      </Route>
      {/* <ProtectedRoute path="/leave_type/add/:id">
        <LeaveTypeAdd />
      </ProtectedRoute> */}

      <Route path="/me/missions" element={<ProtectedRoute />}>
        <Route path="/me/missions" element={<PlanningListv />} />
      </Route>
      <Route
        path="/mission/planning_mission/detail/:id"
        element={<ProtectedRoute />}
      >
        <Route
          path="/mission/planning_mission/detail/:id"
          element={<PlanningMissionDetail />}
        />
      </Route>

      <Route path="mission_reponse" element={<ProtectedRoute />}>
        <Route path="/mission_reponse" element={<ObservationAppuiList />} />
      </Route>
      <Route path="mission_reponse/add/:id" element={<ProtectedRoute />}>
        <Route
          path="/mission_reponse/add/:id"
          element={<ObservationAppuiAdd />}
        />
      </Route>
      <Route path="mission_reponse/add" element={<ProtectedRoute />}>
        <Route path="/mission_reponse/add" element={<ObservationAppuiAdd />} />
      </Route>
      <Route path="mission_category" element={<ProtectedRoute />}>
        <Route path="/mission_category" element={<CategorieList />} />
      </Route>
      <Route path="mission_category/add/:id" element={<ProtectedRoute />}>
        <Route path="/mission_category/add/:id" element={<CategorieAdd />} />
      </Route>
      <Route path="mission_category/add" element={<ProtectedRoute />}>
        <Route path="/mission_category/add" element={<CategorieAdd />} />
      </Route>
      <Route path="mission_planning" element={<ProtectedRoute />}>
        <Route path="/mission_planning" element={<PlanningEtat />} />
      </Route>
      <Route path="mission_dashboard" element={<ProtectedRoute />}>
        <Route path="/mission_dashboard" element={<MissionEtat />} />
      </Route>

      <Route path="mission_dir/add/:id" element={<ProtectedRoute />}>
        <Route path="/mission_dir/add/:id" element={<NombrePerDirAdd />} />
      </Route>
      <Route path="mission_dir/add" element={<ProtectedRoute />}>
        <Route path="/mission_dir/add" element={<NombrePerDirAdd />} />
      </Route>
      <Route path="mission_dir" element={<ProtectedRoute />}>
        <Route path="/mission_dir" element={<NombrePerDirList />} />
      </Route>
      <Route path="mission_graphe" element={<ProtectedRoute />}>
        <Route path="/mission_graphe" element={<StatistiquePlanning />} />
      </Route>

      <Route path="/mission_periode" element={<ProtectedRoute />}>
        <Route path="/mission_periode" element={<ValidationSettingLists />} />
      </Route>

      <Route path="/mission_periode/add/:id" element={<ProtectedRoute />}>
        <Route
          path="/mission_periode/add/:id"
          element={<ValidationSettingAdd />}
        />
      </Route>

      <Route path="/mission_periode/add" element={<ProtectedRoute />}>
        <Route path="/mission_periode/add" element={<ValidationSettingAdd />} />
      </Route>
      <Route path="/mission/history" element={<ProtectedRoute />}>
        <Route path="/mission/history" element={<TDRHistory />} />
      </Route>
      <Route path="/mission/agendalist" element={<ProtectedRoute />}>
        <Route path="/mission/agendalist" element={<AgendaTable />} />
      </Route>

      <Route path="/leave_type/add" element={<ProtectedRoute />}>
        <Route path="/leave_type/add" element={<LeaveTypeAdd />} />
      </Route>
      {/* <ProtectedRoute path="/leave_type/add/">
        <LeaveTypeAdd />
      </ProtectedRoute> */}

      <Route path="/leave_type" element={<ProtectedRoute />}>
        <Route path="/leave_type" element={<LeaveTypeList />} />
      </Route>
      {/* <ProtectedRoute path="/leave_type">
        <LeaveTypeList />
      </ProtectedRoute> */}

      <Route path="/validation_planning/add/:id" element={<ProtectedRoute />}>
        <Route
          path="/validation_planning/add/:id"
          element={<ValidationSettingDate />}
        />
      </Route>
      {/* <ProtectedRoute path="/validation_planning/add/:id">
        <ValidationSettingDate />
      </ProtectedRoute> */}

      <Route path="/validation_planning/add" element={<ProtectedRoute />}>
        <Route
          path="/validation_planning/add"
          element={<ValidationSettingDate />}
        />
      </Route>
      {/* <ProtectedRoute path="/validation_planning/add/">
        <ValidationSettingDate />
      </ProtectedRoute> */}

      <Route path="/validation_planning" element={<ProtectedRoute />}>
        <Route
          path="/validation_planning"
          element={<ValidationSettingList />}
        />
      </Route>
      {/* <ProtectedRoute path="/validation_planning">
        <ValidationSettingList />
      </ProtectedRoute> */}

      <Route path="/information_famille/add/:id" element={<ProtectedRoute />}>
        <Route
          path="/information_famille/add/:id"
          element={<FamilyMemberAdd />}
        />
      </Route>
      {/* <ProtectedRoute path="/information_famille/add/:id">
        <FamilyMemberAdd />
      </ProtectedRoute> */}

      <Route path="/information_famille/add" element={<ProtectedRoute />}>
        <Route path="/information_famille/add" element={<FamilyMemberAdd />} />
      </Route>
      {/* <ProtectedRoute path="/information_famille/add/">
        <FamilyMemberAdd />
      </ProtectedRoute> */}

      <Route path="/information_famille" element={<ProtectedRoute />}>
        <Route path="/information_famille" element={<FamilyMemberList />} />
      </Route>
      {/* <ProtectedRoute path="/information_famille">
        <FamilyMemberList />
      </ProtectedRoute> */}

      <Route
        path="/mission/planning_mission/add/:id"
        element={<ProtectedRoute />}
      >
        <Route
          path="/mission/planning_mission/add/:id"
          element={<PlanningMissionAdd />}
        />
      </Route>
      {/* <ProtectedRoute path="/mission/planning_mission/add/:id">
        <PlanningMissionAdd />
      </ProtectedRoute> */}

      <Route path="/mission/planning_mission/add" element={<ProtectedRoute />}>
        <Route
          path="/mission/planning_mission/add"
          element={<PlanningMissionAdd />}
        />
      </Route>
      {/* <ProtectedRoute path="/mission/planning_mission/add/">
        <PlanningMissionAdd />
      </ProtectedRoute> */}

      <Route path="/mission/planning_mission" element={<ProtectedRoute />}>
        <Route
          path="/mission/planning_mission"
          element={<PlanningMissionList />}
        />
      </Route>
      {/* <ProtectedRoute path="/mission/planning_mission">
        <PlanningMissionList />
      </ProtectedRoute> */}

      <Route path="mission_reponse" element={<ProtectedRoute />}>
        <Route path="/mission_reponse" element={<ObservationAppuiList />} />
      </Route>
      <Route path="mission_reponse/add/:id" element={<ProtectedRoute />}>
        <Route
          path="/mission_reponse/add/:id"
          element={<ObservationAppuiAdd />}
        />
      </Route>
      <Route path="mission_reponse/add" element={<ProtectedRoute />}>
        <Route path="/mission_reponse/add" element={<ObservationAppuiAdd />} />
      </Route>
      <Route path="mission_category" element={<ProtectedRoute />}>
        <Route path="/mission_category" element={<CategorieList />} />
      </Route>
      <Route path="mission_category/add/:id" element={<ProtectedRoute />}>
        <Route path="/mission_category/add/:id" element={<CategorieAdd />} />
      </Route>
      <Route path="mission_category/add" element={<ProtectedRoute />}>
        <Route path="/mission_category/add" element={<CategorieAdd />} />
      </Route>

      <Route path="/mission/agenda/view/:id" element={<ProtectedRoute />}>
        <Route
          path="/mission/agenda/view/:id"
          element={<AgendaMissionView />}
        />
      </Route>
      {/* <ProtectedRoute path="/mission/agenda/view/:id">
        <AgendaMissionView />
      </ProtectedRoute> */}

      <Route path="/mission/agenda/:id/:modif" element={<ProtectedRoute />}>
        <Route path="/mission/agenda/:id/:modif" element={<AgendaMission />} />
      </Route>
      {/* <ProtectedRoute path="/mission/agenda/:id/:modif">
        <AgendaMission />
      </ProtectedRoute> */}

      <Route path="/mission/agenda/:id" element={<ProtectedRoute />}>
        <Route path="/mission/agenda/:id" element={<AgendaMission />} />
      </Route>
      {/* <ProtectedRoute path="/mission/agenda/:id">
        <AgendaMission />
      </ProtectedRoute> */}

      <Route path="/mission/tdr/view/:id" element={<ProtectedRoute />}>
        <Route path="/mission/tdr/view/:id" element={<TDRView />} />
      </Route>
      {/* <ProtectedRoute path="/mission/tdr/view/:id">
        <TDRView />
      </ProtectedRoute> */}

      <Route path="/mission/tdr/:id/:tdrId" element={<ProtectedRoute />}>
        <Route path="/mission/tdr/:id/:tdrId" element={<TDRElaborationAdd />} />
      </Route>
      {/* <ProtectedRoute path="/mission/tdr/:id/:tdrId">
        <TDRElaborationAdd />
      </ProtectedRoute> */}

      <Route path="/mission/tdr/:id" element={<ProtectedRoute />}>
        <Route path="/mission/tdr/:id" element={<TDRElaborationAdd />} />
      </Route>
      {/* <ProtectedRoute path="/mission/tdr/:id">
        <TDRElaborationAdd />
      </ProtectedRoute> */}

      <Route path="/mission/elaboration_tdr" element={<ProtectedRoute />}>
        <Route
          path="/mission/elaboration_tdr"
          element={<TDRElaborationList />}
        />
      </Route>
      {/* <ProtectedRoute path="/mission/elaboration_tdr">
        <TDRElaborationList />
      </ProtectedRoute> */}

      <Route path="/file/:path/:filename" element={<ProtectedRoute />}>
        <Route path="/file/:path/:filename" element={<DisplayFile />} />
      </Route>
      {/* <ProtectedRoute path="/file/:path/:filename">
        <DisplayFile />
      </ProtectedRoute> */}

      <Route path="/groups" element={<ProtectedRoute />}>
        <Route path="/groups" element={<GroupList />} />
      </Route>
      {/* <ProtectedRoute path="/groups">
        <GroupList />
      </ProtectedRoute> */}

      <Route path="/privileges/add/:id" element={<ProtectedRoute />}>
        <Route path="/privileges/add/:id" element={<PrivilegeAdd />} />
      </Route>
      {/* <ProtectedRoute path="/privileges/add/:id">
        <PrivilegeAdd />
      </ProtectedRoute> */}

      <Route path="/privileges/add" element={<ProtectedRoute />}>
        <Route path="/privileges/add" element={<PrivilegeAdd />} />
      </Route>
      {/* <ProtectedRoute path="/privileges/add/">
        <PrivilegeAdd />
      </ProtectedRoute> */}

      <Route path="/privileges" element={<ProtectedRoute />}>
        <Route path="/privileges" element={<PrivilegeList />} />
      </Route>
      {/* <ProtectedRoute path="/privileges">
        <PrivilegeList />
      </ProtectedRoute> */}

      <Route
        path="/me/leaves/add/:valide/:idLeaveToValidate"
        element={<ProtectedRoute />}
      >
        <Route
          path="/me/leaves/add/:valide/:idLeaveToValidate"
          element={<LeaveAdd />}
        />
      </Route>
      {/* <Route path="/me/leaves/add/valide/:id" element={<ProtectedRoute />}>
        <Route path="/me/leaves/add/valide/:id" element={<LeaveAdd />} />
      </Route> */}
      {/* <ProtectedRoute path="/me/leaves/add/valide/:id">
        <LeaveAdd />
      </ProtectedRoute> */}

      <Route path="/me/leaves/add/:id" element={<ProtectedRoute />}>
        <Route path="/me/leaves/add/:id" element={<LeaveAdd />} />
      </Route>
      {/* <ProtectedRoute path="/me/leaves/add/:id">
        <LeaveAdd />
      </ProtectedRoute> */}

      <Route path="/me/leaves/add" element={<ProtectedRoute />}>
        <Route path="/me/leaves/add" element={<LeaveAdd />} />
      </Route>
      {/* <ProtectedRoute path="/me/leaves/add">
        <LeaveAdd />
      </ProtectedRoute> */}

      <Route path="/me/leaves" element={<ProtectedRoute />}>
        <Route path="/me/leaves" element={<LeaveList />} />
      </Route>
      {/* <ProtectedRoute path="/me/leaves">
        <LeaveList />
      </ProtectedRoute> */}

      <Route path="/me/validations" element={<ProtectedRoute />}>
        <Route path="/me/validations" element={<ValidationList />} />
      </Route>
      {/* <ProtectedRoute path="/me/validations">
        <ValidationList />
      </ProtectedRoute> */}

      <Route path="/personnel/update/:id" element={<ProtectedRoute />}>
        <Route path="/personnel/update/:id" element={<Update />} />
      </Route>
      {/* <ProtectedRoute path="/personnel/update/:id">
        <Update />
      </ProtectedRoute> */}

      <Route path="/personnel/add/:id/:motif" element={<ProtectedRoute />}>
        <Route path="/personnel/add/:id/:motif" element={<PersonnelAdd />} />
      </Route>
      {/* <ProtectedRoute path="/personnel/add/:id">
        <PersonnelAdd />
      </ProtectedRoute> */}

      <Route path="/personnel/add/:id" element={<ProtectedRoute />}>
        <Route path="/personnel/add/:id" element={<PersonnelAdd />} />
      </Route>
      {/* <ProtectedRoute path="/personnel/add/:id">
        <PersonnelAdd />
      </ProtectedRoute> */}

      <Route path="/personnel/add" element={<ProtectedRoute />}>
        <Route path="/personnel/add" element={<PersonnelAdd />} />
      </Route>
      {/* <ProtectedRoute path="/personnel/add">
        <PersonnelAdd />
      </ProtectedRoute> */}

      <Route path="/personnel" element={<ProtectedRoute />}>
        <Route path="/personnel" element={<PersonnelList />} />
      </Route>
      {/* <ProtectedRoute path="/personnel">
        <PersonnelList />
      </ProtectedRoute> */}

      <Route path="/annuaire" element={<ProtectedRoute />}>
        <Route path="/annuaire" element={<Annuaire />} />
      </Route>
      {/* <ProtectedRoute path="/annuaire">
        <Annuaire />
      </ProtectedRoute> */}

      <Route path="/fonction/add" element={<ProtectedRoute />}>
        <Route path="/fonction/add" element={<FonctionAdd />} />
      </Route>
      {/* <ProtectedRoute path="/fonction/add">
        <FonctionAdd />
      </ProtectedRoute> */}

      <Route path="/fonction" element={<ProtectedRoute />}>
        <Route path="/fonction" element={<FonctionList />} />
      </Route>
      {/* <ProtectedRoute path="/fonction">
        <FonctionList />
      </ProtectedRoute> */}

      <Route path="/leavePdfRender/:id" element={<ProtectedRoute />}>
        <Route path="/leavePdfRender/:id" element={<LeavePdfRender />} />
      </Route>
      {/* <ProtectedRoute path="/leavePdfRender/:id">
        <LeavePdfRender />
      </ProtectedRoute> */}

      <Route path="/RenderFile/:id" element={<ProtectedRoute />}>
        <Route path="/RenderFile/:id" element={<RenderFile />} />
      </Route>
      {/* <ProtectedRoute path="/RenderFile/:id">
        <RenderFile />
      </ProtectedRoute> */}

      <Route path="/PdfRender/:id" element={<ProtectedRoute />}>
        <Route path="/PdfRender/:id" element={<PdfRender />} />
      </Route>
      {/* <ProtectedRoute path="/PdfRender/:id">
        <PdfRender />
      </ProtectedRoute> */}

      <Route path="/mission/demande/add/:id" element={<ProtectedRoute />}>
        <Route path="/mission/demande/add/:id" element={<AppuiAdd />} />
      </Route>
      {/* <ProtectedRoute path="/mission/demande/add/:id">
        <AppuiAdd />
      </ProtectedRoute> */}

      <Route path="/mission/demande/add" element={<ProtectedRoute />}>
        <Route path="/mission/demande/add" element={<AppuiAdd />} />
      </Route>
      {/* <ProtectedRoute path="/mission/demande/add">
        <AppuiAdd />
      </ProtectedRoute> */}

      <Route path="/mission/demande" element={<ProtectedRoute />}>
        <Route path="/mission/demande" element={<DemandeList />} />
      </Route>
      {/* <ProtectedRoute path="/mission/demande">
        <DemandeList />
      </ProtectedRoute> */}

      <Route
        path="/planning/table/validation/:id/:type"
        element={<ProtectedRoute />}
      >
        <Route
          path="/planning/table/validation/:id/:type"
          element={<ValidationPlanning />}
        />
      </Route>

      <Route path="/planning/table/validation/:id" element={<ProtectedRoute />}>
        <Route
          path="/planning/table/validation/:id"
          element={<ValidationPlanning />}
        />
      </Route>

      <Route path="/planning/table/validation" element={<ProtectedRoute />}>
        <Route
          path="/planning/table/validation"
          element={<ValidationPlanning />}
        />
      </Route>
      {/* <ProtectedRoute path="/planning/table/validation">
        <ValidationPlanning />
      </ProtectedRoute> */}

      <Route path="/planning/table" element={<ProtectedRoute />}>
        <Route path="/planning/table" element={<PlanningTable />} />
      </Route>

      <Route path="/logistic/me/inventory" element={<ProtectedRoute />}>
        <Route path="/logistic/me/inventory" element={<InventoryMineList />} />
      </Route>

      <Route path="/logistic/statistics" element={<ProtectedRoute />}>
        <Route path="/logistic/statistics" element={<StatistiqueEtatList />} />
      </Route>

      <Route path="/logistic/myValidations" element={<ProtectedRoute />}>
        <Route
          path="/logistic/myValidations"
          element={<ImmobilisationMineList />}
        />
      </Route>

      <Route path="/myValidators" element={<ProtectedRoute />}>
        <Route path="/myValidators" element={<ValidatorList />} />
      </Route>
      <Route path="/demandes_en_cours" element={<ProtectedRoute />}>
        <Route
          path="/demandes_en_cours"
          element={
            <DemandeValidationList
              type_demande={DEMANDE_VALIDAION_TYPE.en_cours}
            />
          }
        />
      </Route>
      <Route path="/demandes_validees" element={<ProtectedRoute />}>
        <Route
          path="/demandes_validees"
          element={
            <DemandeValidationList
              type_demande={DEMANDE_VALIDAION_TYPE.validees}
            />
          }
        />
      </Route>
      <Route path="/demandes_refusees" element={<ProtectedRoute />}>
        <Route
          path="/demandes_refusees"
          element={
            <DemandeValidationList
              type_demande={DEMANDE_VALIDAION_TYPE.refusees}
            />
          }
        />
      </Route>

      <Route path="/candidature" element={<ProtectedRoute />}>
        <Route path="/candidature" element={<CandidatureList />} />
      </Route>

      {/* <ProtectedRoute path="/planning/table">
        <PlanningTable />
      </ProtectedRoute> */}
      <Route path="/fonction/add/:id" element={<ProtectedRoute />}>
        <Route path="/fonction/add/:id" element={<FonctionAdd />} />
      </Route>

      <Route path="/myAccount" element={<ProtectedRoute />}>
        <Route path="/myAccount" element={<MyAccount />} />
      </Route>
      {/* <ProtectedRoute path="/myAccount">
        <MyAccount />
      </ProtectedRoute> */}
      <Route
        path="/Logistic/immobilisations/add/:id"
        element={<ProtectedRoute />}
      >
        <Route
          path="/Logistic/immobilisations/add/:id"
          element={<ImmobilisationAdd />}
        />
      </Route>
      <Route path="/Logistic/immobilisations/add" element={<ProtectedRoute />}>
        <Route
          path="/Logistic/immobilisations/add"
          element={<ImmobilisationAdd />}
        />
      </Route>
      {/* <ProtectedRoute path="/Logistic/immobilisations/add">
        <ImmobilisationAdd />
      </ProtectedRoute> */}

      <Route path="/Logistic/immobilisations" element={<ProtectedRoute />}>
        <Route
          path="/Logistic/immobilisations"
          element={<ImmobilisationList />}
        />
      </Route>
      <Route
        path="/Logistic/immobilisationsFiltre"
        element={<ProtectedRoute />}
      >
        <Route
          path="/Logistic/immobilisationsFiltre"
          element={<ImmobilisationFilterList />}
        />
      </Route>
      {/* <ProtectedRoute path="/Logistic/immobilisations">
        <ImmobilisationList />
      </ProtectedRoute> */}

      <Route path="/Logistic/materiels/add" element={<ProtectedRoute />}>
        <Route path="/Logistic/materiels/add" element={<EquipmentAdd />} />
      </Route>
      {/* <ProtectedRoute path="/Logistic/materiels/add">
        <EquipmentAdd />
      </ProtectedRoute> */}

      <Route path="/Logistic/materiels" element={<ProtectedRoute />}>
        <Route path="/Logistic/materiels" element={<EquipmentList />} />
      </Route>
      {/* <ProtectedRoute path="/Logistic/materiels">
        <EquipmentList />
      </ProtectedRoute> */}

      <Route path="/balance_configuration/add/:id" element={<ProtectedRoute />}>
        <Route
          path="/balance_configuration/add/:id"
          element={<BalanceConfigurationAdd />}
        />
      </Route>
      {/* <ProtectedRoute path="/balance_configuration/add/:id">
        <BalanceConfigurationAdd />
      </ProtectedRoute> */}

      <Route path="/balance_configuration/add" element={<ProtectedRoute />}>
        <Route
          path="/balance_configuration/add"
          element={<BalanceConfigurationAdd />}
        />
      </Route>
      {/* <ProtectedRoute path="/balance_configuration/add">
        <BalanceConfigurationAdd />
      </ProtectedRoute> */}

      <Route path="/balance_configuration" element={<ProtectedRoute />}>
        <Route
          path="/balance_configuration"
          element={<BalanceConfigurationList />}
        />
      </Route>
      {/* <ProtectedRoute path="/balance_configuration">
        <BalanceConfigurationList />
      </ProtectedRoute> */}

      <Route path="/dashboard" element={<ProtectedRoute />}>
        <Route path="/dashboard" element={<Dashboard />} />
      </Route>
      {/* <ProtectedRoute path="/dashboard">
        <Dashboard />
      </ProtectedRoute> */}

      <Route path="/custom_states" element={<ProtectedRoute />}>
        <Route path="/custom_states" element={<CustomStates />} />
      </Route>
      {/* <ProtectedRoute path="/custom_states">
        <CustomStates />
      </ProtectedRoute> */}

      <Route path="/actual_balance" element={<ProtectedRoute />}>
        <Route path="/actual_balance" element={<ActualBalance />} />
      </Route>
      {/* <ProtectedRoute path="/actual_balance">
        <ActualBalance />
      </ProtectedRoute> */}

      <Route path="/leave_week" element={<ProtectedRoute />}>
        <Route path="/leave_week" element={<LeavePerWeek />} />
      </Route>
      {/* <ProtectedRoute path="/leave_week">
        <LeavePerWeek />
      </ProtectedRoute> */}

      <Route path="/leave_last_week" element={<ProtectedRoute />}>
        <Route path="/leave_last_week" element={<LeaveLastWeek />} />
      </Route>
      {/* <ProtectedRoute path="/leave_last_week">
        <LeaveLastWeek />
      </ProtectedRoute> */}

      <Route path="/leave_next_week" element={<ProtectedRoute />}>
        <Route path="/leave_next_week" element={<LeaveNextWeek />} />
      </Route>
      {/* <ProtectedRoute path="/leave_next_week">
        <LeaveNextWeek />
      </ProtectedRoute> */}

      {/* <ProtectedRoute path="/me/leave-request">
      </ProtectedRoute> */}
      <Route path="/Logistic/category/add/:id" element={<ProtectedRoute />}>
        <Route
          path="/Logistic/category/add/:id"
          element={<EquipmentCategoryAdd />}
        />
      </Route>
      <Route path="/Logistic/category/add" element={<ProtectedRoute />}>
        <Route
          path="/Logistic/category/add"
          element={<EquipmentCategoryAdd />}
        />
      </Route>
      {/* <ProtectedRoute path="/Logistic/category/add">
        <EquipmentCategoryAdd />
      </ProtectedRoute> */}

      <Route path="/Logistic/category" element={<ProtectedRoute />}>
        <Route path="/Logistic/category" element={<EquipmentCategoryList />} />
      </Route>
      {/* <ProtectedRoute path="/Logistic/category">
        <EquipmentCategoryList />
      </ProtectedRoute> */}
      <Route path="/Logistic/ranking/add/:id" element={<ProtectedRoute />}>
        <Route
          path="/Logistic/ranking/add/:id"
          element={<EquipmentRankingAdd />}
        />
      </Route>
      <Route path="/Logistic/ranking/add" element={<ProtectedRoute />}>
        <Route path="/Logistic/ranking/add" element={<EquipmentRankingAdd />} />
      </Route>
      {/* <ProtectedRoute path="/Logistic/ranking/add">
        <EquipmentRankingAdd />
      </ProtectedRoute> */}

      <Route path="/Logistic/ranking" element={<ProtectedRoute />}>
        <Route path="/Logistic/ranking" element={<EquipmentRankingList />} />
      </Route>
      {/* <ProtectedRoute path="/Logistic/ranking">
        <EquipmentRankingList />
      </ProtectedRoute> */}

      <Route path="/Logistic/inventory" element={<ProtectedRoute />}>
        <Route path="/Logistic/inventory" element={<InventoryList />} />
      </Route>
      {/* <ProtectedRoute path="/Logistic/inventory">
        <InventoryList />
      </ProtectedRoute> */}
      <Route path="/Logistic/config/add/:id" element={<ProtectedRoute />}>
        <Route
          path="/Logistic/config/add/:id"
          element={<EquipmentConfigAdd />}
        />
      </Route>
      <Route path="/Logistic/config/add" element={<ProtectedRoute />}>
        <Route path="/Logistic/config/add" element={<EquipmentConfigAdd />} />
      </Route>
      {/* <ProtectedRoute path="/Logistic/config/add">
        <EquipmentConfigAdd />
      </ProtectedRoute> */}

      <Route path="/Logistic/config" element={<ProtectedRoute />}>
        <Route path="/Logistic/config" element={<EquipmentConfigList />} />
      </Route>
      {/* <ProtectedRoute path="/Logistic/config">
        <EquipmentConfigList />
      </ProtectedRoute> */}

      <Route path="/Logistic/stock" element={<ProtectedRoute />}>
        <Route path="/Logistic/stock" element={<StockList />} />
      </Route>
      <Route path="/Logistic/transfered" element={<ProtectedRoute />}>
        <Route path="/Logistic/transfered" element={<TransferedList />} />
      </Route>
      <Route path="/Logistic/feature/:id" element={<ProtectedRoute />}>
        <Route
          path="/Logistic/feature/:id"
          element={<ImmobilisationConfiguration />}
        />
      </Route>
      <Route path="/Logistic/feature" element={<ProtectedRoute />}>
        <Route
          path="/Logistic/feature"
          element={<ImmobilisationConfiguration />}
        />
      </Route>
      <Route path="/Logistic/rankinglist" element={<ProtectedRoute />}>
        <Route
          path="/Logistic/rankinglist"
          element={<EquipmentConfigRankingList />}
        />
      </Route>
      <Route path="/Logistic/recap/:id" element={<ProtectedRoute />}>
        <Route path="/Logistic/recap/:id" element={<InventoryDetails />} />
      </Route>
      <Route path="/Logistic/details/:id" element={<ProtectedRoute />}>
        <Route
          path="/Logistic/details/:id"
          element={<ImmobilisationDetails />}
        />
      </Route>
      <Route path="/Logistic/immo/add/:id/:motif" element={<ProtectedRoute />}>
        <Route path="/Logistic/immo/add/:id/:motif" element={<CodeImmoAdd />} />
      </Route>
      <Route path="/Logistic/immo/filter" element={<ProtectedRoute />}>
        <Route path="/Logistic/immo/filter" element={<CodeImmoAdd />} />
      </Route>
      <Route path="/Logistic/immo/add/:id" element={<ProtectedRoute />}>
        <Route path="/Logistic/immo/add/:id" element={<CodeImmoAdd />} />
      </Route>
      <Route path="/Logistic/immo/add" element={<ProtectedRoute />}>
        <Route path="/Logistic/immo/add" element={<CodeImmoAdd />} />
      </Route>

      <Route path="/Logistic/generateQrCode" element={<ProtectedRoute />}>
        <Route
          path="/Logistic/generateQrCode"
          element={<EquipmentGenerateQrCode />}
        />
      </Route>
      {/* <ProtectedRoute path="/Logistic/stock">
        <StockList />
      </ProtectedRoute>
      <ProtectedRoute path="/Logistic/feature">
        < ImmobilisationConfiguration/>
      </ProtectedRoute>
      <ProtectedRoute path="/Logistic/rankinglist">
        < EquipmentConfigRankingList/>
      </ProtectedRoute>
      <ProtectedRoute path="/Logistic/recap">
        < InventoryDetails/>
      </ProtectedRoute>
    </Switch>
      </ProtectedRoute> */}

      <Route path="/Logistic/meeting_management" element={<ProtectedRoute />}>
        <Route
          path="/Logistic/meeting_management"
          element={<MettingRequestList />}
        />
      </Route>

      <Route
        path="/Logistic/meeting_management/add"
        element={<ProtectedRoute />}
      >
        <Route
          path="/Logistic/meeting_management/add"
          element={<MeetingRequestAdd />}
        />
      </Route>

      <Route
        path="/Logistic/meeting_management/add/:id"
        element={<ProtectedRoute />}
      >
        <Route
          path="/Logistic/meeting_management/add/:id"
          element={<MeetingRequestAdd />}
        />
      </Route>

      <Route path="/Logistic/viewing_meeting" element={<ProtectedRoute />}>
        <Route
          path="/Logistic/viewing_meeting"
          element={<CalendarMettingList />}
        />
      </Route>

      <Route
        path="/Logistic/meeting_management/finish"
        element={<ProtectedRoute />}
      >
        <Route
          path="/Logistic/meeting_management/finish"
          element={<MeetingFinishList />}
        />
      </Route>

      <Route path="/seat/add" element={<ProtectedRoute />}>
        <Route path="/seat/add" element={<SeatAdd />} />
      </Route>

      <Route path="/seat/add/:id" element={<ProtectedRoute />}>
        <Route path="/seat/add/:id" element={<SeatAdd />} />
      </Route>

      <Route path="/seat" element={<ProtectedRoute />}>
        <Route path="/seat" element={<SeatList />} />
      </Route>

      <Route path="/building/add/:id" element={<ProtectedRoute />}>
        <Route path="/building/add/:id" element={<BuildingAdd />} />
      </Route>

      <Route path="/building/add" element={<ProtectedRoute />}>
        <Route path="/building/add" element={<BuildingAdd />} />
      </Route>

      <Route path="/building" element={<ProtectedRoute />}>
        <Route path="/building" element={<BuildingList />} />
      </Route>

      <Route path="/principale/add/:id" element={<ProtectedRoute />}>
        <Route path="/principale/add/:id" element={<RubriquePrincipaleAdd />} />
      </Route>

      <Route path="/principale/add" element={<ProtectedRoute />}>
        <Route path="/principale/add" element={<RubriquePrincipaleAdd />} />
      </Route>

      <Route path="/principale" element={<ProtectedRoute />}>
        <Route path="/principale" element={<RubriquePrincipaleList />} />
      </Route>

      <Route path="/secondaire/add/:id" element={<ProtectedRoute />}>
        <Route path="/secondaire/add/:id" element={<RubriqueSecondaireAdd />} />
      </Route>

      <Route path="/secondaire/add" element={<ProtectedRoute />}>
        <Route path="/secondaire/add" element={<RubriqueSecondaireAdd />} />
      </Route>

      <Route path="/secondaire" element={<ProtectedRoute />}>
        <Route path="/secondaire" element={<RubriqueSecondaireList />} />
      </Route>

      <Route path="/document/add/:id" element={<ProtectedRoute />}>
        <Route path="/document/add/:id" element={<DocumentAdd />} />
      </Route>

      <Route path="/document/add" element={<ProtectedRoute />}>
        <Route path="/document/add" element={<DocumentAdd />} />
      </Route>

      <Route path="/document" element={<ProtectedRoute />}>
        <Route path="/document" element={<DocumentListe />} />
      </Route>

      <Route path="/TimelineView/:groups/:items" element={<ProtectedRoute />}>
        <Route path="/TimelineView/:groups/:items" element={<TimelineView />} />
      </Route>

      <Route path="/acceptedHelp" element={<ProtectedRoute />}>
        <Route path="/acceptedHelp" element={<AcceptedHelpList />} />
      </Route>

      <Route path="/accepted" element={<ProtectedRoute />}>
        <Route path="/accepted" element={<Accepted />} />
      </Route>

      <Route path="/maternity_leave/add/:id" element={<ProtectedRoute />}>
        <Route
          path="/maternity_leave/add/:id"
          element={<UserMaternityLeaveAdd />}
        />
      </Route>

      <Route path="/maternity_leave/add" element={<ProtectedRoute />}>
        <Route
          path="/maternity_leave/add"
          element={<UserMaternityLeaveAdd />}
        />
      </Route>

      <Route path="/maternity_leave" element={<ProtectedRoute />}>
        <Route path="/maternity_leave" element={<UserMaternityLeaveList />} />
      </Route>
      <Route path="/rubrique/add/:id" element={<ProtectedRoute />}>
        <Route path="/rubrique/add/:id" element={<RubriqueAdd />} />
      </Route>

      <Route path="/rubrique/add" element={<ProtectedRoute />}>
        <Route path="/rubrique/add" element={<RubriqueAdd />} />
      </Route>

      <Route path="/rubrique" element={<ProtectedRoute />}>
        <Route path="/rubrique" element={<RubriqueListe />} />
      </Route>

      <Route path="/emplacement/add/:id" element={<ProtectedRoute />}>
        <Route path="/emplacement/add/:id" element={<EmplacementAdd />} />
      </Route>

      <Route path="/leaveBalanceSummary" element={<ProtectedRoute />}>
        <Route path="/leaveBalanceSummary" element={<LeaveBalanceSummary />} />
      </Route>

      <Route path="/emplacement/add/" element={<ProtectedRoute />}>
        <Route path="/emplacement/add/" element={<EmplacementAdd />} />
      </Route>

      <Route path="/emplacement" element={<ProtectedRoute />}>
        <Route path="/emplacement" element={<EmplacementListe />} />
      </Route>

      <Route path="/leaveBalanceSummary" element={<ProtectedRoute />}>
        <Route path="/leaveBalanceSummary" element={<LeaveBalanceSummary />} />
      </Route>

      <Route path="/timeTracking" element={<ProtectedRoute />}>
        <Route path="/timeTracking" element={<TimeTracking />} />
      </Route>

      {/* // */}
      <Route path="/mailsend" element={<ProtectedRoute />}>
        <Route path="/mailsend" element={<MailConfigAdd />} />
      </Route>

      <Route path="/maillist" element={<ProtectedRoute />}>
        <Route path="/maillist" element={<MailListPerson />} />
      </Route>

      <Route path="/sendmail" element={<ProtectedRoute />}>
        <Route path="/sendmail" element={<MailBox />} />
      </Route>

      <Route path="/mailhistory" element={<ProtectedRoute />}>
        <Route path="/mailhistory" element={<MailHistory />} />
      </Route>

      <Route path="/mailhistoryreceive" element={<ProtectedRoute />}>
        <Route path="/mailhistoryreceive" element={<MailHistoryReceive />} />
      </Route>
    </Routes>
  );
}

export default IndexesRoutes;
