import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useToast from "../../../hooks/useToast";
import { LoadingButton } from "@mui/lab";
import AutocompleteCombobox, {
  adaptDataValueToAutocompleteData,
} from "../../personal_evaluation/shared/AutocompleteCombobox";
import RubriquesService from "../../../services/RubriquesService";
import { TYPE_NOTIFICATION } from "../../../components/reusable/NotificationBull";
import DocumentService from "../../../services/DocumentService";
import SeatsService from "../../../services/SeatsService";
import "./Emplacement_form_style.css";

const EmplacementForm = ({ id, open, handleClose, handleSave }) => {
  const { Toast, showToast } = useToast();
  const [directions, setDirections] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [bureaux, setBureaux] = useState([]);
  const [filteredBureaux, setFilteredBureaux] = useState([]);
  const [currentEmplacement, setCurrentEmplacement] = useState({
    location_id: 0,
    direction_id: 0,
    building_id: 0,
    seat_id: 0,
    storage_place: "",
    storage_1: "",
    storage_2: "",
    storage_3: "",
  });

  const handleClickCloseDialog = () => {
    handleClose();
  };

  const handleClickSaveButton = () => {
    handleSave();
  };

  const fetchCurrentEmplacement = (id) => {
    console.log("id emplacement ", id);

    DocumentService.getLocationById(id).then((res) => {
      if (res && res.status) {
        setCurrentEmplacement(res.data);
      } else {
        showToast(
          TYPE_NOTIFICATION.ERROR,
          "Erreur lors de la récupération de l'emplacement"
        );
      }
    });
  };

  const handleFetchDirections = () => {
    RubriquesService.getDirection().then((response) => {
      if (response.status) {
        setDirections(response.data);
      }
    });
  };
  const handleFetchBatiments = (dir_id) => {
    DocumentService.getBuilding(dir_id).then((res) => {
      if (res && res.status) {
        setBuildings(res.data);
      }
    });
  };
  const handleFetchBureaux = (dir_id) => {
    SeatsService.getAllSeats().then((res) => {
      if (res && res.status) {
        setBureaux(res.data);
      }
    });
  };

  const handleFilterBureauByDirection = (direction_id) => {
    setFilteredBureaux(
      bureaux.filter((bureau) => bureau.direction_id === direction_id)
    );
    console.log(
      "filteredBureaux",
      bureaux.filter((bureau) => bureau.direction_id === direction_id)
    );
  };

  const handleChangeDirection = (new_val) => {
    if (new_val) {
      setCurrentEmplacement({
        ...currentEmplacement,
        direction_id: new_val.direction_id,
        building_id: 0,
        seat_id: 0,
      });
    } else {
      setCurrentEmplacement({
        ...currentEmplacement,
        direction_id: 0,
        building_id: 0,
        seat_id: 0,
      });
    }
  };

  const handleChangeBuildingSelection = (new_val) => {
    if (new_val) {
      setCurrentEmplacement({
        ...currentEmplacement,
        direction_id: new_val.DIRECTION_ID,
      });
    } else {
      setCurrentEmplacement({ ...currentEmplacement, direction_id: 0 });
    }
  };
  const handleChangeBureauSelection = (new_val) => {
    if (new_val) {
      setCurrentEmplacement({
        ...currentEmplacement,
        direction_id: new_val.DIRECTION_ID,
        building_id: 0,
      });
    } else {
      setCurrentEmplacement({
        ...currentEmplacement,
        direction_id: 0,
        building_id: 0,
      });
    }
  };

  useEffect(() => {
    handleFetchDirections();
    handleFetchBureaux();
    if (id) {
      fetchCurrentEmplacement(id);
    }
    if (currentEmplacement.direction_id) {
      handleFetchBatiments(currentEmplacement.direction_id);
      handleFilterBureauByDirection(currentEmplacement.direction_id);
      handleFetchBatiments(currentEmplacement.direction_id);
    }
  }, [id, open, currentEmplacement.direction_id]);

  return (
    <>
      <Toast />
      <Dialog
        open={open}
        /* onClose={ handleDialogClose} */
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <Paper>
          <Grid
            container
            height={"100%"}
            display={"flex"}
            flexDirection={"column"}
            overflow={"auto"}
          >
            <DialogTitle id="dialog-title" className="entete-dialog">
              {id ? "Modifier Emplcement" : "Nouvelle Emplacement"}
            </DialogTitle>
            <DialogContent
              id="dialog-description"
              className="app-scroll-bar-view"
              sx={{ maxHeight: "73vh", height: "73vh", overflow: "auto" }}
            >
              <Grid flexDirection={"row"} mt={1}>
                <Grid className="form_label">
                  <label>Directions *</label>
                </Grid>
                <AutocompleteCombobox
                  limitTags={2}
                  fullWidth
                  label="Liste des directions *"
                  data={adaptDataValueToAutocompleteData(
                    directions,
                    "name",
                    "direction_id"
                  )}
                  valeur={adaptDataValueToAutocompleteData(
                    directions,
                    "name",
                    "direction_id"
                  ).find(
                    (direction) =>
                      direction.DIRECTION_ID === currentEmplacement.direction_id
                  )}
                  onChange={handleChangeDirection}
                />
              </Grid>
              <Grid flexDirection={"row"} mt={1}>
                <Grid className="form_label">
                  <label>Bureau *</label>
                </Grid>
                <AutocompleteCombobox
                  limitTags={2}
                  fullWidth
                  label="Bureau *"
                  data={adaptDataValueToAutocompleteData(
                    filteredBureaux,
                    "seat_name",
                    "seat_id"
                  )}
                  valeur={adaptDataValueToAutocompleteData(
                    filteredBureaux,
                    "seat_name",
                    "seat_id"
                  ).find(
                    (bureau) => bureau.seat_id === currentEmplacement.seat_id
                  )}
                  onChange={handleChangeBureauSelection}
                />
              </Grid>
              <Grid flexDirection={"row"} mt={1}>
                <Grid className="form_label">
                  <label>Batiment *</label>
                </Grid>
                <AutocompleteCombobox
                  limitTags={2}
                  fullWidth
                  label="Batiment *"
                  data={adaptDataValueToAutocompleteData(
                    buildings,
                    "building_name",
                    "building_id"
                  )}
                  valeur={adaptDataValueToAutocompleteData(
                    buildings,
                    "building_name",
                    "building_id"
                  ).find(
                    (building) =>
                      building.building_id === currentEmplacement.building_id
                  )}
                  onChange={handleChangeBuildingSelection}
                />
              </Grid>
              <Grid flexDirection={"column"} mt={1}>
                <Grid className="form_label">
                  <label>Lieu de stockage *</label>
                </Grid>
                <TextField size="small" fullWidth />
              </Grid>
              <Grid flexDirection={"column"} mt={1}>
                <Grid className="form_label">
                  <label>Rangement 1 *</label>
                </Grid>
                <TextField size="small" fullWidth />
              </Grid>
              <Grid flexDirection={"column"} mt={1}>
                <Grid className="form_label">
                  <label>Rangement 2</label>
                </Grid>
                <TextField size="small" fullWidth />
              </Grid>
              <Grid flexDirection={"column"} mt={1}>
                <Grid className="form_label">
                  <label>Rangement 3</label>
                </Grid>
                <TextField size="small" fullWidth />
              </Grid>
            </DialogContent>
            <Divider variant="middle" />
            <DialogActions>
              <LoadingButton
                // loading={submitLoad}
                size="small"
                className="form_control"
                type="submit"
                variant="contained"
                onClick={handleClickSaveButton}
              >
                Valider
              </LoadingButton>
              <Button
                className="form_control"
                size="small"
                onClick={handleClickCloseDialog}
                variant="outlined"
              >
                Fermer
              </Button>
            </DialogActions>
          </Grid>
        </Paper>
      </Dialog>
    </>
  );
};

export default EmplacementForm;
