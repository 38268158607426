import React, { useState } from "react";
import MainContainer from "../../../components/MainContainer";
import { headerData } from "../../../data/modules/portal-header";
import AppPageForTableList from "../../personal_evaluation/shared/AppPageForTableList";
import ValidatorsFiltre from "../mes_validateurs/ValidatorsFiltre";

export const DEMANDE_VALIDAION_TYPE = {
  en_cours: 1,
  validees: 2,
  refusees: 3,
};

const DemandeValidationList = ({ type_demande }) => {
  const [openForm, setOpenForm] = useState(false);
  const handleOpenDialog = () => {
    setOpenForm(!openForm);
  };
  const handleCloseDialog = () => {
    setOpenForm(false);
  };

  const handleSwitchTitle = () => {
    switch (type_demande) {
      case DEMANDE_VALIDAION_TYPE.en_cours:
        return "Mes demandes en cours";
      case DEMANDE_VALIDAION_TYPE.refusees:
        return "Mes demandes refusées";
      case DEMANDE_VALIDAION_TYPE.validees:
        return "Mes demandes validées";
      default:
        return "default";
    }
  };
  return (
    <MainContainer {...headerData}>
      <AppPageForTableList
        title={handleSwitchTitle()}
        button_creer_title={"Gerer"}
        handleClickBtnCreer={handleOpenDialog}
        filter_component={
          <ValidatorsFiltre
            handleCloseFormDialog={handleCloseDialog}
            handleOpenFormDialog={handleOpenDialog}
            openForm={openForm}
          />
        }
      />
    </MainContainer>
  );
};

export default DemandeValidationList;
