import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect, useState, useContext } from "react";
import DocumentService from "../../../services/DocumentService";
import { CreateTwoTone, DeleteOutlineRounded } from "@material-ui/icons";
import Result from "../../personnel/Result";
import Message from "../../personnel/Message";
import { useNavigate } from "react-router";
import { hasPrivilege } from "../../../utils/privilege";
import { userDetailContext } from "../../../App";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import EmplacementForm from "./EmplacementForm";
import { Button } from "@mui/material";
import { exportToExcel } from "../../../helpers/ExportHelpers";

export default function EmplacementTable({
  excelExport,
  filtre,
  form_open,
  refreshData,
}) {
  const contextData = useContext(userDetailContext);
  const [formOpen, setFormOpen] = useState(form_open);
  const [selectedEmplacementId, setSelectedEmplacementId] = useState(0);

  const columns = [
    {
      field: "direction_name",
      headerName: "Direction",
      width: 300,
    },
    {
      field: "seat_name",
      headerName: "Bureau",
      width: 250,
    },
    {
      field: "building_name",
      headerName: "Bâtiment",
      width: 250,
    },

    {
      field: "storage_place",
      headerName: "Lieu de stockage",
      width: 250,
    },

    {
      field: "storage_1",
      headerName: "Rangement 1",
      width: 230,
    },

    {
      field: "storage_2",
      headerName: "Rangement 2",
      width: 230,
    },

    {
      field: "storage_3",
      headerName: "Rangement 3",
      width: 230,
    },

    {
      field: "action",
      headerName: "Action",
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <div>
            {hasPrivilege(
              contextData.privileges,
              "Button_edit_document_location"
            ) && (
              <Button
                onClick={(e) => handleEdit(e, params.id)}
                title="Modifier"
              >
                <CreateTwoTone />
              </Button>
            )}

            {hasPrivilege(
              contextData.privileges,
              "Button_delete_document_location"
            ) && (
              <Button onClick={handleDelete(params.id)} title="Supprimer">
                <DeleteOutlineRounded style={{ color: "red" }} />
              </Button>
            )}
          </div>
        );
      },
      width: 200,
    },
  ];

  const [rows, setRows] = useState([]);

  const [etats, setEtats] = useState({
    open: false,
    message: "",
    color: "",
  });

  const [deleteResult, setDeleteResult] = useState({
    open: false,
    message: "",
    accept: false,
  });

  const handleOpenEmplacementForm = (emplacement_id) => {
    setFormOpen(true);
  };

  const [idToDelete, setIdToDelete] = useState(0);

  const navigate = useNavigate();

  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =
    useState(false);

  function handleEdit(event, id) {
    navigate("/emplacement/add/" + id);
  }

  const handleDelete = (id) => (ev) => {
    setDeleteResult({
      open: true,
      message: "Voulez vous vraiment supprimer  ?",
    });
    setIdToDelete(id);
  };

  function handleAcceptDeleting() {
    setDeleteResult({
      open: false,
      message: "",
      accept: true,
    });

    DocumentService.deleteLocation(idToDelete).then((response) => {
      if (response.status) {
        setEtats({
          ...etats,
          open: true,
          color: "green",
          message: response.message,
        });

        DocumentService.getLocation(filtre).then((results) => {
          if (results.status) {
            setRows(results.data);
          } else {
            setRows([]);
          }
        });
      }
    });
  }

  function handleCloseMessage() {
    setDeleteResult({
      open: false,
      message: "",
    });
  }

  const handleCloseForm = () => {
    setFormOpen(false);
  };
  const handleSaveForm = () => {
    setFormOpen(false);
  };

  function handleResultCloseMessage() {
    setEtats({ ...etats, open: false });
  }

  const handleFetchData = () => {
    setOpenLoadingSimpleBackdrop(true);
    DocumentService.getLocation(filtre).then((response) => {
      if (response.status) {
        setRows(response.data);
        setOpenLoadingSimpleBackdrop(false);
      } else {
        setRows([]);
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  useEffect(() => {
    if (filtre.refresh) {
      handleFetchData();
    }
    if (form_open) {
      setFormOpen(form_open);
    }
    if (excelExport) {
      exportToExcel(rows, columns, "Liste des emplacements " + new Date());
    }
  }, [filtre.refresh, form_open, excelExport]);

  return (
    <React.Fragment>
      <EmplacementForm
        open={formOpen}
        handleClose={handleCloseForm}
        handleSave={handleSaveForm}
        id={selectedEmplacementId}
      />
      <DataGrid
        rows={rows}
        columns={columns}
        rowsPerPageOptions={[12]}
        pageSize={12}
      />
      <Result
        message={etats.message}
        color={etats.color}
        open={etats.open}
        handleClose={handleResultCloseMessage}
      />

      <Message
        isActive={deleteResult.open}
        message={deleteResult.message}
        accept={deleteResult.accept}
        handleClose={handleCloseMessage}
        handleConfirm={handleAcceptDeleting}
      />

      <LoadingSimpleBackdrop
        openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
      />
    </React.Fragment>
  );
}
