import React, { useEffect, useState } from "react";
import Color from "color";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { useFourThreeCardMediaStyles } from "@mui-treasury/styles/cardMedia/fourThree";
import { Link as RouterLink } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { userDetailContext } from "../../App";
import { useContext } from "react";
import { hasPrivilege } from "../../utils/privilege";
import { headerDataAdmin } from "../../data/modules/admin-header";
import { headerDataRH } from "../../data/modules/hr-header";
import { SvgIcon } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import { headerDataMission } from "../../data/modules/mission-header";
import { headerDataImmo } from "../../data/modules/logistic-header";

// import { useTheme } from "@material-ui/styles";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
const useGridStyles = makeStyles(({ breakpoints }) => ({
  root: {
    [breakpoints.up("md")]: {
      justifyContent: "center",
    },
    margin: "10px",
  },
}));

const useStyles = makeStyles((theme) => ({
  actionArea: {
    borderRadius: 16,
    transition: "0.6s",
    "&:hover": {
      transform: "scale(1.1)",
    },
    width: '256px',
    height: '307px',
  },
  card: ({ color }) => ({
    [theme.breakpoints.down("sm")]: {
      maxWidth: 200,
    },
    minWidth: 256,
    borderRadius: 16,
    boxShadow: "none",
    "&:hover": {
      boxShadow: `0 6px 12px 0 ${Color(color)
        .rotate(-12)
        .darken(0.2)}`,
    },
  }),
  content: ({ color, textColor }) => {
    return {
      justifyContent: "center",
      color: textColor,
      backgroundColor: color,
      padding: "1rem 1.5rem 1.5rem",
      height: '115px',
    };
  },
  title: {
    fontFamily: "Open sans",
    fontSize: "1.5rem",
    //color: "#fff",
    //textTransform: 'uppercase',
  },
  disabledActionArea: {
    background: "black",
    cursor: "default",
    pointerEvents: "none !important",
  },
  disabledCard: {
    opacity: "0.3",
  },
  paper: {
    // height: "50px",
    padding: "10px",
  },
  subModuleActionGrid: {
    color: "black !important",
    opacity: "0.6",
    textDecoration: "none",
    '&:hover': {
      textDecoration: "underline",
    },
  },
}));

const CustomCard = ({ idCustomCard, classes, image, title, link, disabledActionArea, disabledCard, onclickCard }) => {
  const mediaStyles = useFourThreeCardMediaStyles();

  useEffect(() => {
    Aos.init({
      offset: 100,
      duration: 1000,
    });
  }, []);
  // const theme = useTheme();

  // const matches = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <CardActionArea
      className={clsx(classes.actionArea, disabledActionArea && classes.disabledActionArea)}
      //component={RouterLink}
      //to={link}
      onClick={onclickCard}

    >
      <Card className={clsx(classes.card, disabledCard && classes.disabledCard)}
        id={idCustomCard}>
        <CardMedia classes={mediaStyles} image={image} />
        <CardContent className={classes.content} id={idCustomCard}>
          <Typography className={classes.title} variant={"h2"}>
            {title}
          </Typography>
        </CardContent>
      </Card>
    </CardActionArea>
  );
};

export const CardPortal = React.memo(function SolidGameCard() {
  const gridStyles = useGridStyles();
  // const styles = useStyles({ color: 'rgb(106, 207, 246)' });
  const styles2 = useStyles({ color: "rgb(19, 62, 103)", textColor: "#ffffff" });
  const styles3 = useStyles({ color: "rgb(255, 242, 0)", textColor: "rgb(19, 62, 103)" });
  const contextData = useContext(userDetailContext);
  const [showPortal, setShowPortal] = useState(true);
  const [showPortalChild, setShowPortalChild] = useState(false);
  const [headerDataValue, setHeaderDataValue] = useState([]);

  const classes = useStyles();

  function handleClickCard(e) {
    console.log("TARGET ", e.target);
    console.log("ID ", e.target.parentNode.id);
    const idOfTarget = e.target.parentNode.id;
    setShowPortal(false);
    setShowPortalChild(true);

    console.log("ID =", idOfTarget);
    switch (idOfTarget) {
      case 'admin':
        setHeaderDataValue(headerDataAdmin);
        break;
      case 'rh':
        setHeaderDataValue(headerDataRH);
        break;
      case 'gestion-courier':
        // set header value of logistiqye
        break;
      case 'ged':
        // set header value of ged
        break;
      case 'logistique':
        // set header value of logistique
        setHeaderDataValue(headerDataImmo);
        break;
      case 'mis':
        // set header value of mis
        break;
      case 'mission':
        // set header value of mission
        setHeaderDataValue(headerDataMission);
        break;
      case 'parc-info':
        // set header value of parc informatique
        break;

      default:
        break;
    }
  }

  // console.log("headerValue =", headerDataValue);
  // const styles4 = useStyles({ color: 'rgb(255, 242, 0)' });
  return (
    <>
      {showPortal &&
        <Grid classes={gridStyles} container spacing={4}>

          <Grid item>
            <CustomCard
              idCustomCard={"admin"}
              classes={styles2}
              title={"Administration"}
              image={`${process.env.PUBLIC_URL + '/assets/images/administration-icon.jpg'}`}
              link={"#"}
              disabledActionArea={hasPrivilege(contextData.privileges, 'Admin') ? false : true}
              disabledCard={hasPrivilege(contextData.privileges, 'Admin') ? false : true}
              onclickCard={handleClickCard}
            />
          </Grid>
          <Grid item>
            <CustomCard
              idCustomCard={"gestion_courier"}
              classes={styles3}
              title={"Gestion Courrier"}
              image={`${process.env.PUBLIC_URL + '/assets/images/courrier.jpg'}`}
              link={"#"}
              disabledActionArea={hasPrivilege(contextData.privileges, 'courrier') ? false : true}
              disabledCard={hasPrivilege(contextData.privileges, 'courrier') ? false : true}
            />
          </Grid>
          <Grid item>
            <CustomCard
              idCustomCard={"ged"}
              classes={styles2}
              title={"Gestion Electronique de Document"}
              image={`${process.env.PUBLIC_URL + '/assets/images/ged.png'}`}
              link={"/rubrique"}
              disabledActionArea={hasPrivilege(contextData.privileges, 'electronique') ? false : true}
              disabledCard={hasPrivilege(contextData.privileges, 'electronique') ? false : true}
              onclickCard={()=>{window.location.href = "/pgi/rubrique"}}
            />
          </Grid>
          <Grid item>
            <CustomCard
              idCustomCard={"logistique"}
              classes={styles3}
              title={"Logistique"}
              image={`${process.env.PUBLIC_URL + '/assets/images/logistic.jpg'}`}
              link={"#"}
              disabledActionArea={hasPrivilege(contextData.privileges, 'Menu_Logistique') ? false : true}
              disabledCard={hasPrivilege(contextData.privileges, 'Menu_Logistique') ? false : true}
              onclickCard={handleClickCard}
            />
          </Grid>

          {/* </Grid> */}
          {/* <Grid classes={gridStyles} container spacing={4}> */}

          <Grid item>
            <CustomCard
              idCustomCard={"mis"}
              classes={styles3}
              title={"Management Information Système"}
              image={`${process.env.PUBLIC_URL + '/assets/images/mis.png'}`}
              link={"#"}
              disabledActionArea={hasPrivilege(contextData.privileges, 'mis') ? false : true}
              disabledCard={hasPrivilege(contextData.privileges, 'mis') ? false : true}
            />
          </Grid>
          <Grid item>
            <CustomCard
              idCustomCard={"mission"}
              classes={styles2}
              title={"Mission"}
              image={`${process.env.PUBLIC_URL + '/assets/images/mission.png'}`}
              link={"#"}
              disabledActionArea={hasPrivilege(contextData.privileges, 'Menu_Mission') ? false : true}
              disabledCard={hasPrivilege(contextData.privileges, 'Menu_Mission') ? false : true}
              disabledActionArea={false}
              disabledCard={false}
              onclickCard={handleClickCard}
            />
          </Grid>
          <Grid item>
            <CustomCard
              idCustomCard={"parc-info"}
              classes={styles3}
              title={"Parc Informatique"}
              image={`${process.env.PUBLIC_URL + '/assets/images/parc-informatique.png'}`}
              link={"#"}
              disabledActionArea={hasPrivilege(contextData.privileges, 'informatique') ? false : true}
              disabledCard={hasPrivilege(contextData.privileges, 'informatique') ? false : true}
            />
          </Grid>
          <Grid item>
            <CustomCard
              idCustomCard={"rh"}
              classes={styles2}
              title={"Ressources Humaines"}
              image={`${process.env.PUBLIC_URL + '/assets/images/RH.jpg'}`}
              link={hasPrivilege(contextData.privileges, 'menu_modules_rh') ? "../rh/conge" : "#"}
              disabledActionArea={hasPrivilege(contextData.privileges, 'HR') ? false : true}
              disabledCard={hasPrivilege(contextData.privileges, 'HR') ? false : true}
              onclickCard={handleClickCard}
            />
          </Grid>
        </Grid>
      }
      {showPortalChild &&
        <Grid container spacing={3}>
          {headerDataValue.submodules.map((data) => (
            <Grid item xs={12} sm={3}>

              <Paper className={classes.paper}>
                <Grid container component={RouterLink} to={data.ref} className={classes.subModuleActionGrid}>
                  <Grid item xs={2}>
                    <SvgIcon>
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M20 6h-8l-2-2H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-5 3c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm4 8h-8v-1c0-1.33 2.67-2 4-2s4 .67 4 2v1z" />
                    </SvgIcon>
                  </Grid>
                  <Grid item xs={10} style={{ marginTop: "3px" }}>{data.moduleName}</Grid>
                </Grid>
              </Paper>

            </Grid>

          ))}
        </Grid>
      }
    </>
  );
});
export default CardPortal;
