export const headerBanqueCV = {
  title: "Banque de CV",
  submodules: [
    
  ],
};

function createData(id, moduleName, ref) {
  return { id, moduleName, ref };
}
