import React, { useState } from "react";
import MainContainer from "../../../components/MainContainer";
import { headerData } from "../../../data/modules/portal-header";
import AppPageForTableList from "../../personal_evaluation/shared/AppPageForTableList";
import ValidatorsFiltre from "./ValidatorsFiltre";

const ValidatorList = () => {
  const [openForm, setOpenForm] = useState(false);
  const handleOpenDialog = () => {
    setOpenForm(!openForm);
  };
  const handleCloseDialog = () => {
    setOpenForm(false);
  };
  return (
    <MainContainer {...headerData}>
      <AppPageForTableList
        title={"Mes validateurs"}
        button_creer_title={"Gerer"}
        handleClickBtnCreer={handleOpenDialog}
        filter_component={
          <ValidatorsFiltre
            handleCloseFormDialog={handleCloseDialog}
            handleOpenFormDialog={handleOpenDialog}
            openForm={openForm}
          />
        }
      />
    </MainContainer>
  );
};

export default ValidatorList;
