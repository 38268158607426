import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import { CreateTwoTone, DeleteOutlineRounded } from "@material-ui/icons";
import ConfirmationDialog from "../../tools/ConfirmationDialog";
import Message from "../../tools/Message";
import { DataGrid } from "@material-ui/data-grid";
import { escapeRegExp } from "../../../utils";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import {
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Grid
} from "@material-ui/core";
import PlanningService from "../../../services/PlanningService";
import DirectionService from "../../../services/DirectionService";
import Spacer from "../../../components/Spacer";
import BalanceConfigurationService from "../../../services/BalanceConfigurationService";
import { userDetailContext } from "../../../App";
import { useContext } from "react";
import { hasPrivilege } from "../../../utils/privilege";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
}));

export default function TimeTrackingTable(props) {

    const rows = props.rows;
    const column = [

        {
            field: "direction",
            headerName: "Direction",
            width: 350,
        },
        {
            field: "Nom_prenom",
            headerName: "Nom et prénom(s)",
            width: 350,
        },
        {
            field: "service",
            headerName: "Service",
            width: 350,
        },
        {
            field: "Date_d_arrive",
            headerName: "Date du pointage",
            width: 250,
        },
        {
            field: "observation",
            headerName: "Observation",
            width: 350,
        },
        {
            field: "Statut",
            headerName: "Statut",
            valueGetter: (params) => {
                const date = params.row.Date_d_arrive;
                const value = params.row.Statut;
                const [day, month, year] = date.split('/');

                const parsedMonth = parseInt(month) - 1;

                const parsedDate = new Date(year, parsedMonth, day);

                const today = new Date();
                if(
                    parsedDate.getDate() === today.getDate() &&
                    parsedDate.getMonth() === today.getMonth() &&
                    parsedDate.getFullYear() === today.getFullYear() &&
                    value === "Absent"
                ){
                    return "En attente de synchronisation"
                }
                else {
                    return value;
                }
            },
            width: 240,
        },
    ];

    return (
        <React.Fragment>
            <Spacer y={1} />
            <div style={{ height: "70vh", width: "100%" }}>
                <DataGrid
                    rows={rows}
                    columns={column}
                    pageSize={9}
                    rowsPerPageOptions={[9]}
                    disableSelectionOnClick
                />
            </div>
        </React.Fragment>
    );
}
