import { Button, Grid, Paper } from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { CardHeader } from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
import { circuit_validation_service } from "../../../services/CircuitValidationService";
import useToast from "../../../hooks/useToast";
import useConfirm from "../../../hooks/useConfirm";
import { TYPE_NOTIFICATION } from "../../../components/reusable/NotificationBull";

const ReorderableValidatorsList = forwardRef(
  ({ refresh, handleDeleteOneItem }, ref) => {
    const [items, setItems] = useState([]);
    const { showToast, Toast } = useToast();
    const { DialogComponent, openConfirmDialog } = useConfirm();
    useImperativeHandle(ref, () => ({
      items,
      getMaxNiveau: () => {
        if (Array.from(items).length > 0) {
          return Math.max(...Array.from(items).map((item) => item.niveau)) || 0;
        } else {
          return 0;
        }
      },
    }));

    const onDragEnd = (result) => {
      const { source, destination } = result;
      if (!destination) return;
      const reorderedTasks = Array.from(items);
      const [removed] = reorderedTasks.splice(source.index, 1);
      reorderedTasks.splice(destination.index, 0, removed);
      const reorderedTasksCopy = reorderedTasks.map((item, index) => {
        return { ...item, niveau: index + 1 };
      });
      setItems(reorderedTasksCopy);
    };

    const handleMove = (index, move) => {
      const newIndex = index - move;
      if (newIndex >= 0) {
        const reorderedItems = Array.from(items);
        const [removed] = reorderedItems.splice(index, 1);
        reorderedItems.splice(newIndex, 0, removed);
        const reorderedItemsCopy = reorderedItems.map((item, idx) => {
          return { ...item, niveau: idx + 1 };
        });
        setItems(reorderedItemsCopy);
      }
    };

    const handleRefreshOrder = () => {
      const reorderedItemsCopy = Array.from(items).map((item, idx) => {
        return { ...item, niveau: idx + 1 };
      });
      setItems(reorderedItemsCopy);
    }

    const handleFetchMyEvaluators = () => {
      circuit_validation_service
        .get_personnalized_validators_list()
        .then((res) => {
          if (res) {
            setItems(res.data);
          }
        })
        .finally(() => {});
    };

    const confirmDeletevalidator = (validator) => {
      circuit_validation_service.delete_validator(validator).then((res) => {
        if (res && res.success) {
          showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
          handleFetchMyEvaluators();
          handleRefreshOrder();
        } else {
          showToast(TYPE_NOTIFICATION.ERROR, res.message);
        }
      });
    };

    const handleDeleteValidator = (validator) => {
      openConfirmDialog(
        "Confirmation suppression",
        "Voulez vous supprimer ce validateur?",
        () => confirmDeletevalidator(validator)
      );
    };

    useEffect(() => {
        handleFetchMyEvaluators();
    }, [refresh]);

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <DialogComponent />
        <Toast />
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((item, index) => (
                <Draggable
                  key={item.function_id}
                  draggableId={item.function_id}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        userSelect: "none",
                        backgroundColor: "#fff",
                        color: "#333",
                        ...provided.draggableProps.style,
                      }}
                    >
                      <Paper elevation={2}>
                        <Grid
                          m={0.5}
                          p={0.5}
                          display={"flex"}
                          flexDirection={"row"}
                          alignItems={"center"}
                        >
                          <Grid alignContent={"center"} className="hoverable">
                            <DragIndicatorIcon />
                          </Grid>
                          <Grid ml={1} flexGrow={1}>
                            <CardHeader
                              title={`${item.function_name}`}
                              titleTypographyProps={{ variant: "body2" }}
                              subheader={` Niveau ${item.niveau}. ${item.full_name}`}
                              subheaderTypographyProps={{ variant: "caption" }}
                            />
                          </Grid>
                          <Grid>
                            {/*  <Button
                              sx={{ maxWidth: "20px" }}
                              variant="text"
                              size="small"
                              onClick={() => handleMove(index, 1)}
                            >
                              <ArrowUpwardOutlined />
                            </Button>
                            <Button
                              sx={{ maxWidth: "10px" }}
                              variant="text"
                              size="small"
                              onClick={() => handleMove(index, -1)}
                            >
                              <ArrowDownwardOutlined />
                            </Button> */}
                            <Button
                              sx={{ maxWidth: "10px" }}
                              variant="text"
                              size="small"
                              color="warning"
                              onClick={() => handleDeleteValidator(item)}
                            >
                              <DeleteOutline />
                            </Button>
                          </Grid>
                        </Grid>
                      </Paper>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

export default ReorderableValidatorsList;
