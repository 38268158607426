import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export const exportToExcel = (rows, columns, filename) => {

      const formattedRows = rows.map(row => {
        let formattedRow = {};
        let i = 0;
        columns.forEach(column => {
            i++;
            if (i<columns.length){
                formattedRow[column.headerName] = row[column.field];
            } 
        });
        return formattedRow;
      });
    
      const worksheet = XLSX.utils.json_to_sheet(formattedRows);
    
      // Définir la largeur des colonnes
      worksheet['!cols'] = columns.map(column => ({ wpx: column.width }));
    
      // Ajouter un style aux en-têtes
      const range = XLSX.utils.decode_range(worksheet['!ref']);
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = XLSX.utils.encode_cell({ c: C, r: 0 });
        if (!worksheet[cell_address]) continue;
        worksheet[cell_address].s = {
          font: {
            bold: true,
            color: { rgb: '#FFFFFF' },
          },
          fill: {
            fgColor: { rgb: '#4F81BD' },
          },
        };
      }
    
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });

      saveAs(data,((filename!=='' ||  filename !== undefined || filename !== undefined)? filename : 'Exported_data') + '.xlsx');
};